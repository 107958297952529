import React from "react";
import _ from "lodash";

import { investmentTypes, investmentTypeColors } from "./EntityTypes";

export const Transaction = ({ business, investment_type }) => {
  let transactionType, transactionDetails;
  let collateral, term, interest_rate;
  let color;

  const formatMoney = (number) => {
    return '$'+ number.toLocaleString('en-US');
  };

  switch (investment_type) {
    case investmentTypes.INVESTMENT_TYPE_EQUITY:
      transactionType = "Equity";
      transactionDetails = 
        formatMoney(business.investment_size).toString();
      color = investmentTypeColors.INVESTMENT_TYPE_EQUITY_COLOR
      break;
    case investmentTypes.INVESTMENT_TYPE_TERM_LOAN:
      collateral = _.get(business, ["calculations", "collateral"], 0);
      term = _.get(business, ["calculations", "term"], 0);
      interest_rate = _.get(business, ["calculations", "interest_rate"], 0);
      transactionType = "Term Loan";
      transactionDetails = 
        formatMoney(business.investment_size).toString() +
        " at " +
        interest_rate +
        "% for " + term + " years" +
        (collateral === 1 ? " with collateral" : "");
      color = investmentTypeColors.INVESTMENT_TYPE_TERM_LOAN_COLOR
      break;
    case investmentTypes.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE:
      term = _.get(business, ["calculations", "term"], 0);
      interest_rate = _.get(business, ["calculations", "interest_rate"], 0);
      transactionType = "Purchase Order Finance";
      transactionDetails =
        formatMoney(business.investment_size).toString() +
        " at " +
        interest_rate +
        "% for " + term + " years";
      color = investmentTypeColors.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE_COLOR
      break;
    case investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT:
      term = _.get(business, ["calculations", "term"], 0);
      interest_rate = _.get(business, ["calculations", "interest_rate"], 0);
      const discount_rate = _.get(business, ["calculations", "discount_rate"], 0);
      transactionType = "Convertible Debt";
      transactionDetails =
        formatMoney(business.investment_size).toString() +
        " at " +
        interest_rate +
        "% for " + term + " years " +
        " with discount rate at " + discount_rate + "%";
      color = investmentTypeColors.INVESTMENT_TYPE_CONVERTIBLE_DEBT_COLOR
      break;
    case investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE:
      const royalty_rate = _.get(business, ["calculations", "royalty_rate"], 0);
      const repayment_cap = _.get(business, ["calculations", "repayment_cap"], 0);
      transactionType = "Royalty Finance";
      transactionDetails =
        formatMoney(business.investment_size).toString() +
        " at " +
        royalty_rate +
        "% with repayment cap at " + repayment_cap.toString() + "%";
      color = investmentTypeColors.INVESTMENT_TYPE_ROYALTY_FINANCE_COLOR
      break;
    case investmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT:
      collateral = _.get(business, ["calculations", "collateral"], 0);
      term = _.get(business, ["calculations", "term"], 0);
      interest_rate = _.get(business, ["calculations", "interest_rate"], 0);
      transactionType = "Line of Credit";
      transactionDetails =
        formatMoney(business.investment_size).toString() +
        " at " +
        interest_rate +
        "% for " + term + " years" +
        (collateral === 1 ? " with collateral" : "");
      color = investmentTypeColors.INVESTMENT_TYPE_LINE_OF_CREDIT_COLOR
      break;

    default:
      transactionType = "Unknown";
      transactionDetails =
        "investment type not defined: " +
        (investment_type || business.investment_type);
      break;
  }

  return (
    <React.Fragment>
      <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center"}}>
        {transactionType}
      </div>
      <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center", color: "blue"}}>
        {transactionDetails}
      </div>
    </React.Fragment>
  );
};
