
import React from 'react';
import { Chip, Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";

import BIITheme from "../../theme";

export const ConfirmDialog = ({title, message, open, setOpen, onConfirm, label="Ok", yesNo=true}) => {
  const showYesNoButtons = () => {
    return (
      <div style={{display: "flex", justifyContent: "space-evenly", width: 360}}>
        <div>
          <Chip
            label="YES"
            onClick={() => {
                setOpen(false);
                onConfirm();
              }
            }
          variant="contained"
            style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
          />
        </div>
        <div>
          <Chip
            label ="NO"
            onClick={() => {
              setOpen(false);
            }}
            variant="outlined"
            style={{ width: 150, backgroundColor: BIITheme.palette.dark.secondary.button, color: "black" }}
          />
        </div>
      </div>
    );
  }

  const showOkButton = () => {
    return (
      <div style={{display: "flex", justifyContent: "space-evenly", width: 360}}>
        <div>
          <Chip
            label={label}
            onClick={() => {
                setOpen(false);
                onConfirm && onConfirm();
              }
            }
          variant="contained"
            style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
          />
        </div>
      </div>
    );
  }

  const showConfirmDialog = () => {
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={false}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
    >
      { title  && <DialogTitle id="confirm-dialog">{title}</DialogTitle> }
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 240, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            { message }
          </Typography>
          { yesNo ? showYesNoButtons() : showOkButton() }
        </div>
      </DialogContent>
    </Dialog>
  }

  return (
    <React.Fragment>
      { showConfirmDialog() }
    </React.Fragment>
  );

}

export default ConfirmDialog;