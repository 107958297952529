import React, { useEffect, useState } from "react";
import MarketCard from "./MarketCard";
import { useSelector } from "react-redux";
import { Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { gameSelector, businessesSelector, sessionSelector } from "../../javascript/slices/game";

import _ from "lodash";

const Market = ({classes}) => {
  const allBusinesses = useSelector(businessesSelector);
  const session = useSelector(sessionSelector);
  const sessionBusinesses = session ? _.filter(session.market_business_ids, (id) => !_.includes(session.selected_market_business_ids, id)) : []
  const businesses =  _.sortBy(_.filter(allBusinesses, (business) => _.includes(sessionBusinesses, business.id)), ["name"])

  if (!businesses) {
    return <div>Loading businesses...</div>;
  }

  return (
    <React.Fragment>
      <div style={{width: "100%"}}>
        {/* TODO: Implement filters <div style={{marginLeft: 20}}>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel style={{top: -14, color: "#0093B2", marginTop: 0, fontSize: 14}}>Sort by</InputLabel>
            <Select className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root
                }
              }}
              style={{marginTop: 0, marginTop: 0, marginRight: 20, width: 100}}
            >
            </Select>
          </FormControl>
          <FormControl variant="standard" className={classes.formControl}>
            <InputLabel style={{top: -14, color: "#0093B2", marginTop: 0, fontSize: 14}}>View only</InputLabel>
            <Select className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root
                }
              }}
              style={{marginTop: 0, marginTop: 0, marginRight: 20, width: 100}}
            >
            </Select>
          </FormControl>
        </div> */}
      </div>
      <div className={`${classes.itemContainer} custom-scrollbar`}>
        {businesses.map((business) => (
            <MarketCard key={business.id} business={business} classes={classes}/>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Market;
