import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';

import { MultiSelect } from "react-multi-select-component";
import { Formatters, Editors } from "react-data-grid-addons";
import _ from "lodash";

export class CheckboxEditor extends React.Component {
    constructor(props) {
      super(props);
      this.field = props.field;
      this.options = props.options;
      const getSelectedValues = (values) => {
        return !values ? [] : _.map(_.split(values, ","), getValues);
      };
      const getValues = (v) => {
        return props.options[parseInt(v)];
      };
      this.state = {
        selected: getSelectedValues(props.value)
      };      
    }
  
    getValue() {
      return { [this.field]: _.map(this.state.selected, "value").join() };
    }
  
    getInputNode() {
      return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }
  
    onChange = (selected) => {
      this.setState({selected: selected});
    }
  
    render() {
      return (
          <MultiSelect
            options={this.options}
            value={this.state.selected || ""}
            onChange={this.onChange}
          />
        );
    }
}

export class CheckboxFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.options = props.options;
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const values = _.split(this.props.value, ',');
    const options = this.props.options;
    const textValues = [];      
    _.map(values, function(v) {
      if (v !== "") {
        // pull label out of options and add textValues
        const option = options[parseInt(v)];
        textValues.push(option.label);
      }
    });
    return <div>{textValues.join()}</div>;
  }
}

export class FilteredDropDownEditor extends Editors.DropDownEditor {
  constructor(props) {
    super(props);
    this.field = props.field;
    this.value = props.value;
    const filteredBy = props.rowData[props.filteredBy];
    this.filteredOptions = _.get(props.options, filteredBy);
  }

  renderOptions() {
    let options = [];
    this.filteredOptions.forEach(function(name) {
      if (typeof(name) === 'string') {
        options.push(<option key={name} value={name}>{name}</option>);
      } else {
        options.push(<option key={name.id} value={name.value} title={"name.title"}  >{name.text || name.value}</option>);
      }
    }, this);
    return options;
  }
  
  getValue() {
    return { [this.field]: this.value };
  }
 
  onChange(e, self) {
    const v = e.target.value;
    self.value = v;
  }

  render() {
    return (
      <select style={this.getStyle()} defaultValue="{this.props.value}" onBlur={this.props.onBlur} onChange={(e) => this.onChange(e, this)} >
        {this.renderOptions()}
      </select>);
  }
}

export class FilteredDropDownFormatter extends Formatters.DropDownFormatter {
  constructor(props) {
    super(props);
    const filteredBy = props.row[props.filteredBy];
    this.filteredOptions = _.get(props.options, filteredBy, []);
  }

  render() {
    let value = this.props.value;
    let option = this.filteredOptions.filter(function(v) {
      return v === value || v.value === value;
    })[0];
    if (!option) {
      option = this.filteredOptions[0];
    }
    let title = option.title || option.value || option;
    let text = option.text || option.value || option;
    return <div title={title}>{text}</div>;
  }
}

export class ValueFormatter extends React.Component {
  static propTypes = {
    values: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string
        })
      ])).isRequired,
    value: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.props.value;
  }

  render() {
    let key = this.props.value;
    let value = this.props.values.filter(function(v) {
      return v.id === key;
    })[0];
    if (!value) {
      return key;
    }
    return value.name;
  }
}
