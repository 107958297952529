// business
export const businessStageOptions = [
    { id: 0, value: 0, text: "Start-Up", riskRating: 5.0 },
    { id: 1, value: 1, text: "Early Stage", riskRating: 3.0 },
    { id: 2, value: 2, text: "Steady Stage", riskRating: 1.0 },
    { id: 3, value: 3, text: "Growth Stage", riskRating: 2.0 },
  ];
  
export const businessTypeOptions = [
    { id: 0, value: 0, text: "For Profit", impactRating: 0.0 },
    { id: 1, value: 1, text: "Benefit Corporation", impactRating: 5.0 },
    { id: 2, value: 2, text: "Cooperative", impactRating: 5.0 },
    { id: 3, value: 3, text: "Non-Profit", impactRating: 5 },
];

// key is business type option value
export const businessRevenueOptions = {
    0: [
        { id: 0, value: 0, text: "$0" },
        { id: 1, value: 100000, text: "$100K" }
    ],
    1: [
        { id: 0, value: 100000, text: "$100K" },
        { id: 1, value: 250000, text: "$250K" }
    ],
    2: [
        { id: 0, value: 500000, text: "$500K" },
        { id: 1, value: 1000000, text: "$1M" },
        { id: 2, value: 2000000, text: "$2M" },
        { id: 3, value: 5000000, text: "$5M" }
    ],
    3: [
        { id: 0, value: 250000, text: "$250K" },
        { id: 1, value: 500000, text: "$500K" },
        { id: 2, value: 1000000, text: "$1M" },
        { id: 3, value: 2000000, text: "$2M" },
        { id: 4, value: 5000000, text: "$3M" }
    ]
};

// key is business type option value
export const businessGrowthRateOptions = {
    0: [
        { id: 0, value: 25, text: "25%" },
        { id: 1, value: 50, text: "50%" },
        { id: 2, value: 100, text: "100%" },
        { id: 3, value: 200, text: "200%" }
    ],
    1: [
        { id: 0, value: 15, text: "15%" },
        { id: 1, value: 25, text: "25%" },
        { id: 2, value: 50, text: "50%" },
        { id: 3, value: 100, text: "100%" }
    ],
    2: [
        { id: 0, value: 25, text: "25%" },
        { id: 1, value: 50, text: "50%" },
        { id: 2, value: 75, text: "75%" },
        { id: 3, value: 100, text: "100%" }
    ],
    3: [
        { id: 0, value: 2, text: "2%" },
        { id: 1, value: 5, text: "5%" },
        { id: 2, value: 10, text: "10%" },
    ]
};

// investment types
export const investmentTypes = {
    INVESTMENT_TYPE_EQUITY: 0,
    INVESTMENT_TYPE_CONVERTIBLE_DEBT: 1,
    INVESTMENT_TYPE_ROYALTY_FINANCE : 2,
    INVESTMENT_TYPE_TERM_LOAN: 3,
    INVESTMENT_TYPE_LINE_OF_CREDIT: 4,
    INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE: 5,
    INVESTMENT_TYPE_CASH: 6
}

export const investmentTypeOptions = [
    { id: 0, value: investmentTypes.INVESTMENT_TYPE_EQUITY, text: "Equity", label: "Equity",
        options: {
            riskRating: 5.0,
        },
        defaults: {
            riskRating: 5.0,
        }
    },
    { id: 1, value: investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT, text: "Convertible Debt", label: "Convertible Debt",
        options: {
            // NOTE: Players must draw an event card to convert their convertible notes to equity, then draw another event card to exit their equity investments.
            // term: [ // in years
            //     { id: 0, value: 2, text: "2 years", riskRating: 4.0 },
            //     { id: 1, value: 3, text: "3 years", riskRating: 4.2 },
            //     { id: 2, value: 4, text: "4 years", riskRating: 4.4 },
            //     { id: 3, value: 5, text: "5 years", riskRating: 4.6 },
            //     { id: 4, value: 6, text: "6 years", riskRating: 4.8 },
            //     { id: 5, value: 7, text: "7 years", riskRating: 5.0 },
            // ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 7, value: 7, text: "7%" },
            ],
            discount_rate: [
                { id: 0, value: 10, text: "10%" },
                { id: 1, value: 20, text: "20%" },
                { id: 2, value: 30, text: "30%" },
            ]
        },
        defaults: {
            // term: 2,
            interest_rate: 0,
            discount_rate: 10
        }
    },
    { id: 2, value: investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE, text:  "Royalty Finance", label: "Royalty Finance",
        options: {
            royalty_rate: [
                { id: 0, value: 1, text: "1%", riskRating: 3.0 },
                { id: 1, value: 2, text: "2%", riskRating: 3.0 },
                { id: 2, value: 3, text: "3%", riskRating: 3.0 },
                { id: 3, value: 4, text: "4%", riskRating: 3.0 },
                { id: 4, value: 5, text: "5%", riskRating: 3.0 },
                { id: 5, value: 6, text: "6%", riskRating: 3.0 },
                { id: 6, value: 7, text: "7%", riskRating: 3.0 },
                { id: 7, value: 8, text: "8%", riskRating: 3.0 },
                { id: 8, value: 9, text: "9%", riskRating: 3.0 },
            ],
            repayment_cap: [
                { id: 0, value: 1.5, text: "1.5x", impactRating: 5.0 },
                { id: 1, value: 2.0, text: "2x", impactRating: 4.0 },
                { id: 2, value: 3.0, text: "3x", impactRating: 3.0 },
                { id: 3, value: -1, text: "No Cap", impactRating: 1.0 }, // -1 is no cap
            ],
            revenue: {
                // keys (0, 1, 2, 3) are from ids in business stage options
                0: [
                    { id: 0, value: 0, text: "$0"},
                    { id: 1, value: 100000, text: "$100K"}
                ],
                1: [
                    { id: 0, value: 100000, text: "$100K"},
                    { id: 1, value: 250000, text: "$250K"}
                ],
                2: [
                    { id: 0, value: 500000, text: "$500K"},
                    { id: 1, value: 1000000, text: "$1M"},
                    { id: 2, value: 2000000, text: "$2M"},
                    { id: 3, value: 5000000, text: "$5M"}
                ],
                3: [
                    { id: 0, value: 250000, text: "$250K"},
                    { id: 1, value: 500000, text: "$500K"},
                    { id: 2, value: 1000000, text: "$1M"},
                    { id: 3, value: 2000000, text: "$2M"},
                    { id: 4, value: 3000000, text: "$3M"}
                ]
            },
            growth_rate: {
                // keys (0, 1, 2, 3) are from ids in business stage options
                0: [
                    { id: 0, value: 25, text: "25%"},
                    { id: 1, value: 50, text: "50%"},
                    { id: 2, value: 100, text: "100%"},
                    { id: 3, value: 200, text: "200%"}
                ],
                1: [
                    { id: 0, value: 15, text: "15%"},
                    { id: 1, value: 25, text: "25%"},
                    { id: 2, value: 50, text: "50%"},
                    { id: 3, value: 100, text: "100%"}
                ],
                2: [
                    { id: 0, value: 25, text: "25%"},
                    { id: 1, value: 50, text: "50%"},
                    { id: 2, value: 75, text: "75%"},
                    { id: 3, value: 100, text: "100%"}
                ],
                3: [
                    { id: 0, value: 2, text: "2%"},
                    { id: 1, value: 5, text: "5%"},
                    { id: 3, value: 10, text: "10%"}
                ]
            }
        },
        defaults: { royalty_rate: 1, repayment_cap: 1.5 }
    },
    { id: 3, value: investmentTypes.INVESTMENT_TYPE_TERM_LOAN, text: "Term Loan", label: "Term Loan",
        options: {
            collateral: [
                { id: 0, value: 0, text: "Yes" },
                { id: 1, value: 1, text: "No" },
            ],
            term: [ // in years
                { id: 0, value: 1, text: "1 year", riskRating: [1.00, 2.00] },
                { id: 1, value: 2, text: "2 year", riskRating: [1.25, 2.25] },
                { id: 2, value: 3, text: "3 year", riskRating: [1.50, 2.50] },
                { id: 3, value: 4, text: "4 year", riskRating: [1.75, 2.75] },
                { id: 4, value: 5, text: "5 year", riskRating: [2.00, 3.00] },
                { id: 5, value: 6, text: "6 year", riskRating: [2.25, 3.25] },
                { id: 6, value: 7, text: "7 year", riskRating: [2.50, 3.50] },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { collateral: 1, term: 1, interest_rate: 0 }
    },
    { id: 4, value: investmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT, text:  "Line Of Credit", label: "Line Of Credit",
        options: {
            collateral: [
                { id: 0, value: 0, text: "Yes" },
                { id: 0, value: 1, text: "No" },
            ],
            term: [ // in years
                { id: 0, value: 1, text: "1 year", riskRating: [1.25, 2.25] },
                { id: 1, value: 2, text: "2 year", riskRating: [1.50, 2.50] },
                { id: 2, value: 3, text: "3 year", riskRating: [1.75, 2.75] },
                { id: 3, value: 4, text: "4 year", riskRating: [2.00, 3.00] },
                { id: 4, value: 5, text: "5 year", riskRating: [2.25, 3.25] },
                { id: 5, value: 6, text: "6 year", riskRating: [2.50, 3.50] },
                { id: 6, value: 7, text: "7 year", riskRating: [2.75, 3.75] },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { collateral: 1, term: 1, interest_rate: 0 }
    },
    { id: 5, value: investmentTypes.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE, text:  "Purchase Order Finance", label: "Purchase Order Finance",
        options: {
            term: [ // in years
                { id: 0, value: 0.5, text: "6 months", riskRating: 1.5 },
                { id: 1, value: 1, text: "1 year", riskRating: 2.0 },
                { id: 2, value: 2, text: "2 years", riskRating: 2.5 },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { term: 6, interest_rate: 0 }
    },
];

export const investmentTypeColors = {
    INVESTMENT_TYPE_EQUITY_COLOR: 'rgba(255, 102, 53, 1)',
    INVESTMENT_TYPE_CONVERTIBLE_DEBT_COLOR: 'rgba(102, 61, 0, 1)',
    INVESTMENT_TYPE_ROYALTY_FINANCE_COLOR: 'rgba(45, 76, 157, 1)',
    INVESTMENT_TYPE_TERM_LOAN_COLOR: 'rgba(196, 77, 88, 1)',
    INVESTMENT_TYPE_LINE_OF_CREDIT_COLOR: 'rgba(15, 116, 100, 1)',
    INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE_COLOR: 'rgba(222, 142, 67, 1)',
}

// industries
export const industryTypes = {
    INDUSTRY_TYPE_MEDIA_ART_CULTURE: 0,
    INDUSTRY_TYPE_EDUCATION_HEALTHCARE: 1,
    INDUSTRY_TYPE_ENERGY_TECHNOLOGY : 2,
    INDUSTRY_TYPE_FINANCIAL_PROFESSIONAL_SERVICES: 3,
    INDUSTRY_TYPE_FOOD_AGRICULTURE_ENVIRONMENT: 4,
    INDUSTRY_TYPE_MANUFACTURING_CONSUMER_GOODS: 5,
    INDUSTRY_TYPE_REAL_ESTATE_CONSTRUCTION: 6
}

export const industryTypeOptions = [
    { id: 0, value: industryTypes.INDUSTRY_TYPE_MEDIA_ART_CULTURE, text: "Media, Arts and Culture", name: "Media, Arts & Culture", label: "Media, Arts & Culture" },
    { id: 1, value: industryTypes.INDUSTRY_TYPE_EDUCATION_HEALTHCARE, text: "Education and Healthcare", name: "Education & Healthcare", label: "Education & Healthcare" },
    { id: 2, value: industryTypes.INDUSTRY_TYPE_ENERGY_TECHNOLOGY, text: "Energy and Technology", name: "Energy & Technology", label: "Energy & Technology" },
    { id: 3, value: industryTypes.INDUSTRY_TYPE_FINANCIAL_PROFESSIONAL_SERVICES, text: "Financial and Professional Services", name: "Financial & Professional", label: "Financial & Professional" },
    { id: 4, value: industryTypes.INDUSTRY_TYPE_FOOD_AGRICULTURE_ENVIRONMENT, text: "Food, Agriculture and Environment", name: "Food & Agriculture", label: "Food & Agriculture" },
    { id: 5, value: industryTypes.INDUSTRY_TYPE_MANUFACTURING_CONSUMER_GOODS, text: "Manufacturing and Consumer Goods", name: "Manufacturing & Consumer", label: "Manufacturing & Consumer" },
    { id: 6, value: industryTypes.INDUSTRY_TYPE_REAL_ESTATE_CONSTRUCTION, text: "Real Estate and Construction", name: "Real Estate & Construction", label: "Real Estate & Construction" },
];

// worker ownership
export const workerOwnershipOptions = [
    { id: 0, value: 0, text: "90-100%", impactRating: 5.0 },
    { id: 1, value: 1, text: "50-89%", impactRating: 4.0 },
    { id: 2, value: 2, text: "10-49%", impactRating: 3.0 },
    { id: 3, value: 3, text: "1-9%", impactRating: 2.0 },
    { id: 4, value: 4, text: "0%", impactRating: 1.0 },
];

// job created
export const jobsCreatedOptions = [
    { id: 0, value: 0, text: "0", impactRating: 1.0 },
    { id: 1, value: 1, text: "1-3", impactRating: 2.0 },
    { id: 2, value: 2, text: "4-8", impactRating: 3.0 },
    { id: 3, value: 3, text: "9-14", impactRating: 4.0 },
    { id: 4, value: 4, text: "15+", impactRating: 5.0 },
];

// owner makeup
// Impact Rating - if White AND Men = 1, If BIPOC or Women = 3, If BIPOC AND Women = 5
export const ownerMakeupOptions = [
    { id: 0, value: 0, label: "White" },
    { id: 1, value: 1, label: "BIPOC" },
    { id: 2, value: 2, label: "Men" },
    { id: 3, value: 3, label: "Women" },
];
  
// environmental impact
export const environmentImpactOptions = [
    { id: 0, value: 0, text: "Regenerative", impactRating: 5.0 },
    { id: 1, value: 1, text: "Zero Waste", impactRating: 5.0 },
    { id: 2, value: 2, text: "Low Footprint", impactRating: 4.0 },
    { id: 3, value: 3, text: "Moderate Footprint", impactRating: 2.0 },
    { id: 4, value: 4, text: "High Footprint", impactRating: 1.0 },
];
  
// local supply
export const localSupplyOptions = [
    { id: 0, value: 0, text: "100%", impactRating: 5.0 },
    { id: 1, value: 1, text: "50-99%", impactRating: 4.0 },
    { id: 2, value: 2, text: "10-49%", impactRating: 3.0 },
    { id: 3, value: 3, text: "1-9%", impactRating: 2.0 },
    { id: 4, value: 4, text: "0%", impactRating: 1.0 },
];
  
// event investment types
export const eventInvestmentTypes = {
    INVESTMENT_TYPE_NONE: -1,
    INVESTMENT_TYPE_EQUITY: 0,
    INVESTMENT_TYPE_CONVERTIBLE_DEBT: 1,
    INVESTMENT_TYPE_ROYALTY_FINANCE : 2,
    INVESTMENT_TYPE_TERM_LOAN: 3,
    INVESTMENT_TYPE_LINE_OF_CREDIT: 4,
    INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE: 5,
    INVESTMENT_TYPE_CASH: 6
}

export const eventInvestmentTypeOptions = [
    { id: -1, value: eventInvestmentTypes.INVESTMENT_TYPE_NONE, text: "None", label: "None",
    },
    { id: 0, value: investmentTypes.INVESTMENT_TYPE_EQUITY, text: "Equity", label: "Equity",
        options: {
            riskRating: 5.0,
        },
        defaults: {
            riskRating: 5.0,
        }
    },
    { id: 1, value: eventInvestmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT, text: "Convertible Debt", label: "Convertible Debt",
        options: {
            // NOTE: Players must draw an event card to convert their convertible notes to equity, then draw another event card to exit their equity investments.
            // term: [ // in years
            //     { id: 0, value: 2, text: "2 years", riskRating: 4.0 },
            //     { id: 1, value: 3, text: "3 years", riskRating: 4.2 },
            //     { id: 2, value: 4, text: "4 years", riskRating: 4.4 },
            //     { id: 3, value: 5, text: "5 years", riskRating: 4.6 },
            //     { id: 4, value: 6, text: "6 years", riskRating: 4.8 },
            //     { id: 5, value: 7, text: "7 years", riskRating: 5.0 },
            // ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 7, value: 7, text: "7%" },
            ],
            discount_rate: [
                { id: 0, value: 10, text: "10%" },
                { id: 1, value: 20, text: "20%" },
                { id: 2, value: 30, text: "30%" },
            ]
        },
        defaults: {
            // term: 2,
            interest_rate: 0,
            discount_rate: 10
        }
    },
    { id: 2, value: eventInvestmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE, text:  "Royalty Finance", label: "Royalty Finance",
        options: {
            royalty_rate: [
                { id: 0, value: 1, text: "1%", riskRating: 3.0 },
                { id: 1, value: 2, text: "2%", riskRating: 3.0 },
                { id: 2, value: 3, text: "3%", riskRating: 3.0 },
                { id: 3, value: 4, text: "4%", riskRating: 3.0 },
                { id: 4, value: 5, text: "5%", riskRating: 3.0 },
                { id: 5, value: 6, text: "6%", riskRating: 3.0 },
                { id: 6, value: 7, text: "7%", riskRating: 3.0 },
                { id: 7, value: 8, text: "8%", riskRating: 3.0 },
                { id: 8, value: 9, text: "9%", riskRating: 3.0 },
            ],
            repayment_cap: [
                { id: 0, value: 1.5, text: "1.5x", impactRating: 5.0 },
                { id: 1, value: 2.0, text: "2x", impactRating: 4.0 },
                { id: 2, value: 3.0, text: "3x", impactRating: 3.0 },
                { id: 3, value: -1, text: "No Cap", impactRating: 1.0 }, // -1 is no cap
            ],
            revenue: {
                // keys (0, 1, 2, 3) are from ids in business stage options
                0: [
                    { id: 0, value: 0, text: "$0"},
                    { id: 1, value: 100000, text: "$100K"}
                ],
                1: [
                    { id: 0, value: 100000, text: "$100K"},
                    { id: 1, value: 250000, text: "$250K"}
                ],
                2: [
                    { id: 0, value: 500000, text: "$500K"},
                    { id: 1, value: 1000000, text: "$1M"},
                    { id: 2, value: 2000000, text: "$2M"},
                    { id: 3, value: 5000000, text: "$5M"}
                ],
                3: [
                    { id: 0, value: 250000, text: "$250K"},
                    { id: 1, value: 500000, text: "$500K"},
                    { id: 2, value: 1000000, text: "$1M"},
                    { id: 3, value: 2000000, text: "$2M"},
                    { id: 4, value: 3000000, text: "$3M"}
                ]
            },
            growth_rate: {
                // keys (0, 1, 2, 3) are from ids in business stage options
                0: [
                    { id: 0, value: 25, text: "25%"},
                    { id: 1, value: 50, text: "50%"},
                    { id: 2, value: 100, text: "100%"},
                    { id: 3, value: 200, text: "200%"}
                ],
                1: [
                    { id: 0, value: 15, text: "15%"},
                    { id: 1, value: 25, text: "25%"},
                    { id: 2, value: 50, text: "50%"},
                    { id: 3, value: 100, text: "100%"}
                ],
                2: [
                    { id: 0, value: 25, text: "25%"},
                    { id: 1, value: 50, text: "50%"},
                    { id: 2, value: 75, text: "75%"},
                    { id: 3, value: 100, text: "100%"}
                ],
                3: [
                    { id: 0, value: 2, text: "2%"},
                    { id: 1, value: 5, text: "5%"},
                    { id: 3, value: 10, text: "10%"}
                ]
            }
        },
        defaults: { royalty_rate: 1, repayment_cap: 1.5 }
    },
    { id: 3, value: eventInvestmentTypes.INVESTMENT_TYPE_TERM_LOAN, text: "Term Loan", label: "Term Loan",
        options: {
            collateral: [
                { id: 0, value: 0, text: "Yes" },
                { id: 0, value: 1, text: "No" },
            ],
            term: [ // in years
                { id: 0, value: 1, text: "1 year", riskRating: [1.00, 2.00] },
                { id: 1, value: 2, text: "2 year", riskRating: [1.25, 2.25] },
                { id: 2, value: 3, text: "3 year", riskRating: [1.50, 2.50] },
                { id: 3, value: 4, text: "4 year", riskRating: [1.75, 2.75] },
                { id: 4, value: 5, text: "5 year", riskRating: [2.00, 3.00] },
                { id: 5, value: 6, text: "6 year", riskRating: [2.25, 3.25] },
                { id: 6, value: 7, text: "7 year", riskRating: [2.50, 3.50] },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { collateral: 1, term: 1, interest_rate: 0 }
    },
    { id: 4, value: eventInvestmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT, text:  "Line Of Credit", label: "Line Of Credit",
        options: {
            collateral: [
                { id: 0, value: 0, text: "Yes" },
                { id: 0, value: 1, text: "No" },
            ],
            term: [ // in years
                { id: 0, value: 1, text: "1 year", riskRating: [1.25, 2.25] },
                { id: 1, value: 2, text: "2 year", riskRating: [1.50, 2.50] },
                { id: 2, value: 3, text: "3 year", riskRating: [1.75, 2.75] },
                { id: 3, value: 4, text: "4 year", riskRating: [2.00, 3.00] },
                { id: 4, value: 5, text: "5 year", riskRating: [2.25, 3.25] },
                { id: 5, value: 6, text: "6 year", riskRating: [2.50, 3.50] },
                { id: 6, value: 7, text: "7 year", riskRating: [2.75, 3.75] },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { collateral: 1, term: 1, interest_rate: 0 }
    },
    { id: 5, value: eventInvestmentTypes.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE, text:  "Purchase Order Finance", label: "Purchase Order Finance",
        options: {
            term: [ // in years
                { id: 0, value: 0.5, text: "6 months", riskRating: 1.5 },
                { id: 1, value: 1, text: "1 year", riskRating: 2.0 },
                { id: 2, value: 2, text: "2 years", riskRating: 2.5 },
            ],
            interest_rate: [
                { id: 0, value: 0, text: "0%" },
                { id: 1, value: 1, text: "1%" },
                { id: 2, value: 2, text: "2%" },
                { id: 3, value: 3, text: "3%" },
                { id: 4, value: 4, text: "4%" },
                { id: 5, value: 5, text: "5%" },
                { id: 6, value: 6, text: "6%" },
                { id: 6, value: 7, text: "7%" },
            ]
        },
        defaults: { term: 6, interest_rate: 0 }
    },
    { id: 6, value: eventInvestmentTypes.INVESTMENT_TYPE_CASH, text:  "Cash", label: "Cash",
    },
];

// event industries
export const eventIndustryTypes = {
    INDUSTRY_TYPE_NONE: -1,
    INDUSTRY_TYPE_MEDIA_ART_CULTURE: 0,
    INDUSTRY_TYPE_EDUCATION_HEALTHCARE: 1,
    INDUSTRY_TYPE_ENERGY_TECHNOLOGY : 2,
    INDUSTRY_TYPE_FINANCIAL_PROFESSIONAL_SERVICES: 3,
    INDUSTRY_TYPE_FOOD_AGRICULTURE_ENVIRONMENT: 4,
    INDUSTRY_TYPE_MANUFACTURING_CONSUMER_GOODS: 5,
    INDUSTRY_TYPE_REAL_ESTATE_CONSTRUCTION: 6
}

export const eventIndustryTypeOptions = [
    { id: -1, value: eventIndustryTypes.INDUSTRY_TYPE_NONE, text: "None", name: "None" },
    { id: 0, value: eventIndustryTypes.INDUSTRY_TYPE_MEDIA_ART_CULTURE, text: "Media, Arts and Culture", name: "Media, Arts & Culture", label: "Media, Arts & Culture" },
    { id: 1, value: eventIndustryTypes.INDUSTRY_TYPE_EDUCATION_HEALTHCARE, text: "Education and Healthcare", name: "Education & Healthcare", label: "Education & Healthcare" },
    { id: 2, value: eventIndustryTypes.INDUSTRY_TYPE_ENERGY_TECHNOLOGY, text: "Energy and Technology", name: "Energy & Technology", label: "Energy & Technology" },
    { id: 3, value: eventIndustryTypes.INDUSTRY_TYPE_FINANCIAL_PROFESSIONAL_SERVICES, text: "Financial and Professional Services", name: "Financial & Professional", label: "Financial & Professional" },
    { id: 4, value: eventIndustryTypes.INDUSTRY_TYPE_FOOD_AGRICULTURE_ENVIRONMENT, text: "Food, Agriculture and Environment", name: "Food & Agriculture", label: "Food & Agriculture" },
    { id: 5, value: eventIndustryTypes.INDUSTRY_TYPE_MANUFACTURING_CONSUMER_GOODS, text: "Manufacturing and Consumer Goods", name: "Manufacturing & Consumer", label: "Manufacturing & Consumer" },
    { id: 6, value: eventIndustryTypes.INDUSTRY_TYPE_REAL_ESTATE_CONSTRUCTION, text: "Real Estate and Construction", name: "Real Estate & Construction", label: "Real Estate & Construction" },
];

// find functions
const UNKNOWN_VALUE = "unknown";

export const getBusinessName = (value) => {
    const business = _.find(businessTypeOptions, {value: value});
    return _.get(business, "text", UNKNOWN_VALUE);
};

export const getBusinessStage = (value) => {
    const stage = _.find(businessStageOptions, {value: value});
    return _.get(stage, "text", UNKNOWN_VALUE);
};

export const getInvestmentType = (value, defaultValue) => {
    const investmentType = _.find(investmentTypeOptions, {value: value})
    return _.get(investmentType, "text", defaultValue || UNKNOWN_VALUE);
};

export const getIndustry = (value, defaultValue) => {
    const industry = _.find(industryTypeOptions, {value: value})
    return _.get(industry, "name", defaultValue || UNKNOWN_VALUE);
};

export const getWorkerOwnership = (value) => {
    const ownership = _.find(workerOwnershipOptions, {value: value});
    return _.get(ownership, "text", UNKNOWN_VALUE);
};

export const getJobCreated = (value) => {
    const job = _.find(jobsCreatedOptions, {value: value});
    return _.get(job, "text", UNKNOWN_VALUE);
};

export const geEnvironmentalImpact = (value) => {
    const impact = _.find(environmentImpactOptions, {value: value});
    return _.get(impact, "text", UNKNOWN_VALUE);
};

export const getLocalSupply = (value) => {
    const supply = _.find(localSupplyOptions, {value: value});
    return _.get(supply, "text", UNKNOWN_VALUE);
};

export const getOwnerMakeup = (value) => {
    const values = _.map(_.split(value, ","), v => { return _.get(_.find(ownerMakeupOptions, {value: parseInt(v)}), "label", UNKNOWN_VALUE)});
    return values.join(',');
}

export const getInvestmentTypeColor = (investmentType) => {
    switch (investmentType) {
        case investmentTypes.INVESTMENT_TYPE_EQUITY:
            return investmentTypeColors.INVESTMENT_TYPE_EQUITY_COLOR;
        case investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT:
            return investmentTypeColors.INVESTMENT_TYPE_CONVERTIBLE_DEBT_COLOR;
        case investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE:
            return investmentTypeColors.INVESTMENT_TYPE_ROYALTY_FINANCE_COLOR;
        case investmentTypes.INVESTMENT_TYPE_TERM_LOAN:
            return investmentTypeColors.INVESTMENT_TYPE_TERM_LOAN_COLOR;
        case investmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT:
            return investmentTypeColors.INVESTMENT_TYPE_LINE_OF_CREDIT_COLOR;
        case investmentTypes.INVESTMENT_TYPE_LINE_OF_CREDIT:
            return investmentTypeColors.INVESTMENT_TYPE_LINE_OF_CREDIT_COLOR;
        case investmentTypes.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE:
            return investmentTypeColors.INVESTMENT_TYPE_PURCHASE_ORDER_FINANCE_COLOR;
    }
}
