import React, { useState } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Divider, Tooltip } from "@material-ui/core";
import { GAME_PHASE } from "./Constants";
import { getAggregateScore } from "./helpers/Utils";
import { getEarnedBadge } from "./helpers/Badges";
import { playerSelector } from "../slices/game";
import * as utils from "./helpers/Utils";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flexGrow: 0,
    flexShrink: 0,
    width: "12rem",
    height: "18rem",
    margin: "8px 8px",
    padding: "6px 6px",
    color: "white",
    borderRadius: 12
  }
}));

const formatMoney = (number) => {
  return '$'+ number.toLocaleString('en-US');
};

const truncateString = (str, n) => {
  if (str.length > n) {
    return str.substring(0, n) + "...";
  } else {
    return str;
  }
}

const ScoreboardCard = ({game, session, player, placement, classes}) => {
  const [ isFlipped, setIsFlipped ] = useState(false);

  const _classes = useStyles();

  if (player) {
    const aggregateScore = getAggregateScore(session)
    const badge = getEarnedBadge(game, session, player)
    const cuurentPlayer = useSelector(playerSelector);
    const playerName = truncateString(`${player.first_name} ${player.last_name}`, 18)
    const backgroundColor = cuurentPlayer.id === player.id ? "#0093B2" : "#556270";
    const portfolios = utils.getPlayerPortfolios(game, player);
    const risk = utils.calculateRisk(portfolios);
    const impact = utils.calculateImpact(portfolios);

    const toggleFlipped = (e) => {
      e.stopPropagation();
      setIsFlipped(!isFlipped);
    }
  
    return (
      <div class="flip-card">
        <div className={classnames(_classes.cardContainer, "flip-card-inner", {"is-flipped": isFlipped})} variant="outlined" elevation={10} style={{backgroundColor: backgroundColor}} onClick={(e) => toggleFlipped(e)}>
          <div className={classnames("flip-card-face-no-style flip-card-front")}>
            <Grid container>
              <Grid item xs={12} style={{paddingBottom: 10}}>
                <div>
                  {
                    game.phase !== GAME_PHASE.COMPLETE ? 
                      <img src={require('../../assets/images/bush.png')} alt="" class="badge-center" width="50" height="50" />
                      :
                      <img src={require(`../../assets/images/${badge.image}`)} alt={badge.name} class="badge-center" width="50" height="50" />
                  }
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{fontSize: 14, fontWeight: 600, width: "100%", textAlign: "center"}}>
                  {playerName}
                </div>
                <div style={{fontSize: 14, fontWeight: 600, width: "100%", textAlign: "center",  marginTop: 5}}>
                  Rank: {placement}
                </div>
                <div style={{fontSize: 14, fontWeight: 600, width: "100%", textAlign: "center", marginTop: 5, marginBottom: 10}}>
                  Score: {aggregateScore.toFixed(2)}
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={{margin: "10px 0 10px 0"}}>
                  <Divider className={classes.divider} style={{borderTop: "1px solid rgba(255, 255, 255, 0.5)"}} />
                  <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center", marginTop: 12, marginBottom: 12}}>
                    Portfolio Value: {formatMoney(session.portfolios_value)}
                  </div>
                  <Divider className={classes.divider} style={{borderTop: "1px solid rgba(255, 255, 255, 0.5)"}} />
                </div>
              </Grid>
              <Grid item xs={12} style={{padding: 4}}>
                <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center"}}>
                  Risk Rating: {risk.toFixed(2)}
                </div>
                <div style={{fontSize: 10, fontWeight: 600, width: "100%", textAlign: "center"}}>
                  Impact Rating: {impact.toFixed(2)}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classnames("flip-card-face-no-style flip-card-back")} style={{width: "100%"}}>
              <Grid container>
                <Grid item xs={12} style={{overflow: "auto", fontSize: 12, height: "15rem", paddingLeft: 10, paddingTop: 10, paddingRight: 10, fontWeight: 600, textAlign: "center"}}>
                  { game.phase === GAME_PHASE.COMPLETE ? badge.description : "Happy Investing" }
                </Grid>
              </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default ScoreboardCard;
