import React from "react";
import PropTypes from "prop-types";

import Portfolio from "./Portfolio";
import PlayerCard from "./PlayerCard";
import {
  Button,
  Grid,
  Paper,
  Card,
  GridList,
  GridListTile,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { playerSelector, completeTurn } from "../slices/game";


export const Player = (props) => {

  const player = useSelector(playerSelector);
  const dispatch = useDispatch();

  if (!player) return null;

  return (
    <React.Fragment>
      <Paper elevation={5}>
      <PlayerCard player={player} ActivePlayer={false} />
      
      {<Button disabled={ !(player.status === ACTIVE)}
        variant="contained"
        color={player.status === ACTIVE ? "primary" : "default"}
        onClick={() => {
          dispatch(completeTurn());
        }}
        style={{width: '100%', height: '100%', display:'flex',alignItems: 'center'}}
      >
        Next Turn
      </Button>}
      </Paper>
      <br/>
      <Portfolio
        portfolios={player.portfolios}
        businesses={player.businesses}
      />
    </React.Fragment>
  );
};

export default Player;
