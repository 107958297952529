import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, RadioGroup, Radio, Slider } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import { Formatters, Editors } from "react-data-grid-addons";
import axios from "axios";
import _ from "lodash";

import * as utils from "./helpers/Utils";
import { verifyDevelopment } from "../slices/game";
import { getResourceString } from "./helpers/Strings";
import { getInvestmentType, getIndustry, investmentTypes } from "./EntityTypes";

import {
    eventInvestmentTypes, eventInvestmentTypeOptions,
    eventIndustryTypes, eventIndustryTypeOptions,
} from "./EntityTypes";
import DataGrid from "./DataGrid";

const { DropDownFormatter } = Formatters;
const investmentTypeFormatter = <DropDownFormatter options={eventInvestmentTypeOptions} />;
const industryFormatter = <DropDownFormatter options={eventIndustryTypeOptions} />;

import BIITheme from "../theme";
import { PORTFOLIO_STATUS } from "./Constants";

const VerifyDevelopmentFormatter = (props) => {
    const [round, setRound] = useState(0)
    const [development, setDevelopment] = useState();
    const [selectedPortfolios, setSelectedPortfolios] = useState([])
    const [optionValue, setOptionValue] = useState()
    const [openDialog, setOpenDialog] = useState(false);
    const [session, setSession] = useState();
    const [portfolios, setPortfolios] = useState([])
    const [monthsHeld, setMonthsHeld] = useState(1)
    const [log, setLog] = useState("")

    const startingCash = 10000000;
    const handleClose = () => {
        setOpenDialog(false);
        setPortfolios([])
        setSelectedPortfolios([])
        setLog("")
    }; 
    
    const getInvestmentValues = () => {
        let investmentValue = 0
        selectedPortfolios.forEach(portfolio => { investmentValue += portfolio.investment_value});
        return investmentValue;
    }

    const handleOpen = async () => {
        const development = props.row
        if (development.effect_scope === "updateSession") {
            setSession({
                status: 'active',
                portfolio_value: 0,
                cash: 0,
                finance_score: 0,
                impact_score: 0,
                velocity_score: 0,
            })
        } else {
            if (development.factor) {
                // show only businesses matching development's investment_type and industry
                const allBusinesses = _.get(await axios.get("/api/businesses/fetch/all"), "data", []);
                const businesses = _.filter(allBusinesses, (business) => {
                    return (development.investment_type === eventInvestmentTypes.INVESTMENT_TYPE_NONE || development.investment_type === business.investment_type) && (development.industry === eventIndustryTypes.INDUSTRY_TYPE_NONE || development.industry === business.industry);
                });

                setPortfolios(_.map(businesses, business => {
                    return {
                        id: business.id,
                        business: business,
                        investment_type: business.investment_type,
                        investment_value: business.investment_size,
                        investment_values: [{date: new Date(), investment_value: -business.investment_size}],
                        risk: business.risk,
                        social_impact: business.social_impact,
                        industry: business.industry,
                        status: PORTFOLIO_STATUS.ACTIVE }
                }));
            }
        }
        setSession({
            cash: startingCash,
            portfolios_value: 0,
            finance_score: 0,
            impact_score: 0,
            velocity_score: 0
        })
        setRound(0);
        setDevelopment(development);
        setOpenDialog(true);
    };

    const handleRowSelect = (rows) => {
        setSelectedPortfolios(rows);
        let portfolios_value = 0
        rows.forEach(portfolio => portfolios_value += portfolio.investment_value)
        setSession({
            cash: startingCash - portfolios_value,
            portfolios_value: portfolios_value,
            finance_score: 0,
            impact_score: 0,
            velocity_score: 0
        })
    };

    const handleUpdatePortfolioResults = (data) => {
        let log = "";

        const updatedPortfolios = _.cloneDeep(portfolios);
        data.results.forEach(result => {
            result.forEach(r => {
                const index = _.findIndex(portfolios, { id: r.portfolio.id} )
                const portfolio = portfolios[index]
                r.portfolio.business = portfolio.business
                updatedPortfolios[index] = r.portfolio
                log = log + r.log + "\n";
            });
        });

        setPortfolios(updatedPortfolios);
        setSession(data.session);
        return log;
    }

    const handleUpdateSessionResults = (data) => {
        let log = "";
        data.results.forEach(result => {
            result.forEach(r => {
                log = log + r.log + "\n";
            });
        });
        setSession(data.session);
        return log;
    }

    const handleApply = async () => {
        if(!utils.canApplyEventToPortfolios(development, selectedPortfolios, portfolios)) {
            setLog("The selected businesses do not meet the event conditions\n")
            return;
        }

        const option = optionValue;
        const businessIds = _.map(selectedPortfolios, p => { return p.business.id })
        const data = await verifyDevelopment(session, development, businessIds, option, round, monthsHeld, startingCash);
        setRound(round + 1);
        let log;
        if (development.effect_scope === "updatePortfolio") {
            // updatePortfolio
            log = handleUpdatePortfolioResults(data)
        } else {
            // updateSession
            log = handleUpdateSessionResults(data)
        }
        setLog(log + "\n");
    };

    const handleOptionChange = (e) => {
        const option = parseInt(e.target.value);
        setOptionValue(option)
    };

    const showDialog = () => {
        const renderValue = (name, value) => {
            return (
                <span style={{marginRight: 10}}><b>{getResourceString(props.lang, name.toUpperCase()) || name}</b>: {value}</span>
            );
        };

        const renderWithOptions = (development) => {
            const options = _.split(development.name, "|")
            const name = "Name";
            const value = options.shift();
            return (
                <div style={{display: "flex", flexDirection: "column", paddingBottom: 10}}>
                    <span style={{marginRight: 10}}><b>{getResourceString(props.lang, name.toUpperCase()) || name}</b>: {value}</span>
                    <FormControl style={{paddingTop: 10}}>
                        <RadioGroup
                            aria-labelledby="development-options-group"
                            name="development-options"
                            value={optionValue}
                            onChange={handleOptionChange}
                        >
                            {
                                _.map(options, (option, index) => {
                                    return <FormControlLabel value={index.toString()} control={<Radio size="small" style={{height: 12}}  />} label={option} />
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </div>
            );
        };
        
        const renderOutput = (id, name, value, readOnly=false, required=false, rows=10) => {
            return (
                <TextField
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    variant="outlined"
                    margin="normal"
                    required={required}
                    fullWidth
                    id={id}
                    label={name}
                    name={name}
                    value={value}
                    multiline
                    rows={rows}
                    maxRows={rows}
                />
            );
        };

        const renderMonthsHeld = (event) => {
            if (event.investment_type === investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT) {
                const term = 10
                const total_months = term * 12
                return (
                    <div style={{display: "flex", width: 500, marginRight: 10}}>
                        <span style={{fontWeight: 600, width: 150}}>Months Held: </span><span style={{width: 50, marginRight: 20}}>{monthsHeld}</span>
                        <Slider width={200} defaultValue={1} max={total_months} onChange={(e, v) => setMonthsHeld(v)} aria-label="Default" valueLabelDisplay="auto" />
                    </div>
                )
            }
        };

        const portfolioColumns = [
            { key: "name", name: "Name", width: 300, required: true, sortable: true, editable: false },
            { key: "industry", name: "Industry", width: 280, required: true, sortable: true, editable: false, formatter: industryFormatter },
            { key: "investment_type", name: "Investment Type", width: 150, sortable: true, required: true, editable: false, formatter: investmentTypeFormatter },
            { key: "investment_value", name: "Investment Value", width: 150, sortable: true, required: true, editable: false },
            { key: "risk", name: "Risk", width: 60, sortable: true, required: false, editable: false },
            { key: "social_impact", name: "Impact", sortable: true, width: 65, required: false, editable: false },
            { key: "status", name: "Status", sortable: true, width: 65, required: false, editable: false },
            { key: "calculations", name: "Calculations", sortable: true, width: 120, required: false, editable: false }
        ];

        const renderPortfolios = () => {
            const portfoliosData = _.map(portfolios, portfolio => {
                return _.extend(portfolio, { name: portfolio.business.name, industry: portfolio.industry, calculations: JSON.stringify(portfolio.business.calculations) })
            })
            return <DataGrid
                minHeight="245px"
                dataset={portfoliosData}
                columns={portfolioColumns}
                type="company"
                readOnly={true}
                maxSelectRows={Math.abs(parseInt(_.get(development, "required", 0)))}
                handleRowSelect={handleRowSelect}
            />;
        };
    
        const renderActionButtons = () => {
            const hasRequiredSelected = utils.hasRequiredSelectedForEvent(development, selectedPortfolios);
            const actionButton = (
                <Button
                    variant={hasRequiredSelected ? "contained" : "outlined"}
                    style={{backgroundColor: hasRequiredSelected ? "#007bff" : "lightgrey", color: "white", margin: 5}}
                    disabled={!hasRequiredSelected}
                    onClick={() => handleApply()}
                >
                    Apply
                </Button>
            )
            const closeButton = (
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}}  onClick={handleClose}> 
                    Close
                </Button>
            )

            return (
                <div style={{display: "flex", justifyContent: "right"}}>
                    {actionButton}
                    {closeButton}
                </div>
            )
        };
    
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
            <DialogTitle id="customized-dialog-title">
            Verify Event
            </DialogTitle>  
            <DialogContent dividers>
                <form noValidate>
                    <div style={{display: "flex", flexDirection: "column", paddingBottom: 10}}>
                        { development && (_.includes(development.factor, "?") ? renderWithOptions(development) : renderValue("Name", development.name)) }
                    </div>
                    <div style={{display: "flex", flexDirection: "column", paddingBottom: 10}}>
                        {  development && renderValue("Outcome", development.effect_outcome) }
                    </div>
                    <div style={{display: "flex"}}>
                        { development && renderValue("Investment Type", getInvestmentType(_.get(development, "investment_type"), "All")) }
                        { development && renderValue("Industry", getIndustry(_.get(development, "industry"), "All")) }
                        { development && renderValue("Factor", development.factor) }
                        { development && renderValue("Required", development.required) }
                    </div>
                    <div style={{display: "flex"}}>
                        { development && renderMonthsHeld(development) }
                    </div>
                    <div style={{display: "flex"}}>
                        { session && renderValue("Cash", "$" + session.cash) }
                        { session && renderValue("Portfolio Value", "$" + session.portfolios_value) }
                        { renderValue("Total Investment Value", "$" + getInvestmentValues()) }
                    </div>
                    <div style={{display: "flex"}}>
                        { session && renderValue("Finance Score", session.finance_score) }
                        { session && renderValue("Impact Score", session.impact_score) }
                        { session && renderValue("Velocity Score", session.velocity_score) }
                        { session && renderValue("Aggregate Score", ((session.finance_score + session.impact_score + session.velocity_score) / 2.1).toFixed(2)) }
                    </div>
                    <div style={{display: "flex", paddingTop: 16}}>
                        { renderPortfolios() }
                    </div>
                    { renderOutput("log", "", log, true) }
                </form>
            </DialogContent>
            {
                renderActionButtons()
            }
            </Dialog>
        );
    }

    return <div>
            <span className = "btn-group  btn-group-sm">
                <button type="button" className="btn btn-primary" onClick={handleOpen}>Verify</button>
            </span>
            {
                showDialog()
            }
        </div>
}

export default VerifyDevelopmentFormatter;