import React, { useState } from "react";
import { TextField } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { send } from "../slices/game";

const handleKeyUp = (event, value, setValue, sendMessage) => {
  if (event.key === "Enter" || event.keyCode === 13) {
    // Send the data to other players in the game
    sendMessage(value);

    // Clear message box
    setValue("");
  }
};

const handleChange = (event, setValue) => {
  setValue(event.target.value);
};

const ChatBox = () => {
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const sendMessage = (message) => {
    dispatch(send(message, false));
  };

  return (
    <div>
      <TextField
        onChange={(event) => handleChange(event, setValue)}
        onKeyUp={(event) => handleKeyUp(event, value, setValue, sendMessage)}
        placeholder="Press enter to send message"
        value={value}
        type="text"
        autoFocus={true}
      />
    </div>
  );
};

export default ChatBox;
