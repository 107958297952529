import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";

import { subscribeGame, unsubscribeGame, send } from "../global/actionCableRedux";

// Initial state of this slice of the Redux store
export const initialState = {
  organization: undefined,
  game: {
    businesses: [],
    round: 0,
    number_of_investments: 0
  },
  player: undefined,
  session: undefined,
  animatedValues: {},
  adminData: {},
  playSound: false,
  messages: [],
};

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setOrganization: (state, { payload }) => {
      // Update the Redux store based on the response
      state.organization = payload;
    },
    setGame: (state, { payload }) => {
      // Update the Redux store based on the response
      state.game = payload;
    },
    setPlayer: (state, { payload }) => {
      // Update the Redux store based on the response
      state.player = payload;
    },
    setSession: (state, { payload }) => {
      // Update the Redux store based on the response
      state.session = payload;
    },
    appendMessage: (state, { payload }) => {
      state.messages.push(payload);
    },
    setAnimatedValue: (state, {payload}) => {
      state.animatedValues[payload.key] = payload.value;
    },
    // adminstration
    setAdminData: (state, {payload}) =>{
      state.adminData = payload;
    },
    setPlaySound: (state, {payload}) => {
      state.playSound = payload;
    }
  },
});

export const {
  setOrganization,
  setGame,
  setPlayer,
  setSession,
  appendMessage,
  setAnimatedValue,
  setAdminData,
  setPlaySound,
} = gameSlice.actions;

// Selectors get a part of the game object from the store
// Then in the component that needs it, we can do e.g. const player = useSelector(playerSelector)
export const organizationSelector = (state) => state.game.organization;
export const gameSelector = (state) => state.game.game;
export const playerSelector = (state) => state.game.player;
export const sessionSelector = (state) => state.game.session;
export const businessesSelector = (state) => state.game.game.businesses;
export const animatedValuesSelector = (state) => state.game.animatedValues;
export const adminDataSelector = (state) => state.game.adminData;
export const playSoundSelector = (state) => state.game.playSound;

export default gameSlice.reducer;

const actionFailure = (message) => console.log(message);

// Thunks (functions which do async stuff then update state)
// TO BE USED FOR NON TURNBASED
// export const openMarket = () => {
//   return async (dispatch, getState) => {
//     const store = getState();
//     const gameId = store.game.game.id;
//     const playerId = store.game.player.id;

//     try {
//       const response = await axios.post("api/games/market", {
//         game_id: gameId
//       });

//       dispatch(send(playerId, "Market is open for investment"));
//     } catch (error) {
//       dispatch(actionFailure(`Error setting market to open: ${error.toString()}`));
//     }
//   };
// }
export const updatePlayerStatus = (status) => {
  return async (dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post("api/sessions/update", {
        game_id: gameId,
        player_id: playerId,
        status: status
      });

      dispatch(send(playerId, `I updated my status to ${status}`));
    } catch (error) {
      dispatch(actionFailure(`Error updating status: ${error.toString()}`));
    }
  };
}

export const completeTurn = () => {
  return async (dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post("api/games/next", {
        game_id: gameId,
        player_id: playerId
      });

      dispatch(send(playerId, "I ended my turn"));
    } catch (error) {
      dispatch(actionFailure(`Error advancing turn: ${error.toString()}`));
    }
  };
}

export const buyBusiness = (businessId) => {
  return async (dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post("/api/businesses/buy", {
        game_id: gameId,
        player_id: playerId,
        business_id: businessId,
      });

      dispatch(send(playerId, `bought business ${businessId}`));
    } catch (error) {
      dispatch(actionFailure(`Error buying business: ${error.toString()}`));
    }
  };
}

export const sellBusiness = (portolioId) => {
  return async (dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post("/api/businesses/sell", {
        game_id: gameId,
        player_id: playerId,
        portfolio_id: portolioId,
      });

      dispatch(send(playerId, `sold business ${portolioId}`));
    } catch (error) {
      dispatch(actionFailure(`Error selling business: ${error.toString()}`));
    }
  };
}

export const updateGame = (gameId, playerId) => {
  return async(dispatch, getState) => {
    const store = getState();
    const gid = gameId || _.get(store, ["game", "game", "id"]);
    const pid = playerId || _.get(store, ["game", "player", "id"]);

    try {
      // action cable will fire an event to update game before store is setup
      if (gid) {
        const gameResponse = await axios(`/api/games/${gid}`);
        // convert risk and social_impact in game.portfolios
        const portfolios = _.get(gameResponse.data, "portfolios");
        _.map(portfolios, p => {
          p.risk = parseFloat(p.risk);
          p.social_impact = parseFloat(p.social_impact);
        });
        dispatch(setGame(gameResponse.data));

        if (pid) {
          const playerResponse = await axios(`/api/players/${pid}`);
          const sessionResponse = await axios(`/api/sessions/fetch?player_id=${pid}&game_id=${gid}`);
          dispatch(setPlayer(playerResponse.data));
          dispatch(setSession(sessionResponse.data));
        }
      }
    } catch (error) {
      console.log("Error in updating game with id: " + gid)
      // dispatch(actionFailure(`Error updating game: ${error.toString()}`));
      throw error;
    }
  };
}

export const joinGame = (gameId, playerId, isAdminUser) => {
  return async(dispatch) => {
    try {
      dispatch(updateGame(gameId, playerId));

      // Subscribe to game events using ActionCable
      if (!isAdminUser) {
        dispatch(subscribeGame(gameId));
      }
    }
    catch(error){
      dispatch(actionFailure(`Error joining game: ${error.toString()}`));
      throw error;
    }
  }
}

export const startGame = (gameId, playerId) => {
  return async(dispatch) => {
    try {
      dispatch(send(playerId, `I started the game: ${gameId}`));
    }
    catch(error){
      dispatch(actionFailure(`Error starting game: ${error.toString()}`));
      throw error;
    }
  }
}

export const leaveGame = (playerId) => {
  return async(dispatch) => {
    try {
      dispatch(setOrganization(initialState.organization));
      dispatch(setGame(initialState.game));
      dispatch(setPlayer(initialState.player));
      dispatch(setSession(initialState.session));
      if (playerId) {
        dispatch(unsubscribeGame(playerId));
      }
    }
    catch(error){
      dispatch(actionFailure(`Error leaving game: ${error.toString()}`));
      throw error;
    }
  }
}

export const selectEvent = (override=false) => {
  return async(dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post(`/api/developments/select`, {
        game_id: gameId,
        player_id: playerId,
        override: override
      });

      dispatch(send(playerId, `Individual event card selected`));
    }
    catch(error){
      dispatch(actionFailure(`Error selecting individual event card: ${error.toString()}`))
    }
  };
}

export const developmentAction = (action, portfolioId, developmentId) => {
  return async(dispatch, getState) => {
    const store = getState();
    const playerId = store.game.player.id;

    try {
      const response = await axios.post(`/api/developments/${action}`, {
        portfolio_id: portfolioId,
        player_id: playerId,
        development_id: developmentId
      });

      dispatch(send(playerId, `${action} performed`));
    }
    catch(error){
      dispatch(actionFailure(`Error performing development action: ${error.toString()}`))
    }
  };
}

export const developmentUnaffected = () => {
  return async(dispatch,getstate) =>{
    const store = getstate();
    const playerId = store.game.player.id;

    try {
      const response = await axios.post(`/api/developments/unaffected`, {
        player_id: playerId
      });

      dispatch(updateGame());
    }
    catch(error){
      dispatch(actionFailure(`Error performing development unaffected: ${error.toString()}`))
    }
  }
}

export const applyDevelopment = (portfolioIds, option) => {
  return async (dispatch, getState) => {
    const store = getState();
    const gameId = store.game.game.id;
    const playerId = store.game.player.id;

    try {
      const response = await axios.post("/api/developments/apply", {
        game_id: gameId,
        player_id: playerId,
        portfolio_ids: portfolioIds,
        option: option
      });

      dispatch(send(playerId, "I applyed individual development"));
    } catch (error) {
      dispatch(actionFailure(`Error applying individual development: ${error.toString()}`));
    }
  };
}

export const verifyPortfolio = async (session, portfolio, round, monthsPerRound, startingCash) => {
  try {
    const response = await axios.post("api/games/verify", {
      verify_type: "calculate",
  
      // game
      round: round,
      starting_cash: startingCash,
      months_per_round: monthsPerRound,
      // session
      cash: session.cash,
      portfolios_value: session.portfolios_value,
      // portfolio
      business_id: portfolio.business_id,
      investment_type: portfolio.investment_type,
      month_funded: portfolio.month_funded,
      months_held: portfolio.months_held,
      investment_value: portfolio.investment_value,
      investment_values: portfolio.investment_values,
      total_collected: portfolio.total_collected,
      earnings: portfolio.earnings,
      status: portfolio.status
    });
    return response.data;
  } catch (error) {
    console.log(`Error verifying portfolio: ${error.toString()}`);
  }
}

export const convertPortfolio = async (session, portfolio, round, monthsPerRound, startingCash) => {
  try {
    const response = await axios.post("api/games/verify", {
      verify_type: "convert",

      // game
      round: round,
      starting_cash: startingCash,
      months_per_round: monthsPerRound,
      // session
      cash: session.cash,
      portfolios_value: session.portfolios_value,
      // portfolio
      business_id: portfolio.business_id,
      investment_type: portfolio.investment_type,
      month_funded: portfolio.month_funded,
      months_held: portfolio.months_held,
      investment_value: portfolio.investment_value,
      total_collected: portfolio.total_collected,
      earnings: portfolio.earnings,
      status: portfolio.status,
    });
    return response.data;
  } catch (error) {
    console.log(`Error converting portfolio: ${error.toString()}`);
  }
}

export const exitPortfolio = async (session, portfolio, round, monthsPerRound, startingCash, multiplier) => {
  try {
    const response = await axios.post("api/games/verify", {
      verify_type: "exit",
      multiplier: multiplier,
  
      // game
      round: round,
      starting_cash: startingCash,
      months_per_round: monthsPerRound,
      // session
      cash: session.cash,
      portfolios_value: session.portfolios_value,
      // portfolio
      business_id: portfolio.business_id,
      investment_type: portfolio.investment_type,
      month_funded: portfolio.month_funded,
      months_held: portfolio.months_held,
      investment_value: portfolio.investment_value,
      total_collected: portfolio.total_collected,
      earnings: portfolio.earnings,
      status: portfolio.status,
    });
    return response.data;
  } catch (error) {
    console.log(`Error converting portfolio: ${error.toString()}`);
  }
}

export const verifyDevelopment = async (session, development, businessIds, option, round, monthsHeld, startingCash) => {
  try {
    const response = await axios.post("api/developments/verify", {
      // game
      round: round,
      starting_cash: startingCash,
      // session
      cash: session.cash,
      portfolios_value: session.portfolios_value,
      // development
      development_id: development.id,
      business_ids: businessIds,
      option: option,
      months: monthsHeld
    });
    return response.data;
  } catch (error) {
    console.log(`Error verifying development: ${error.toString()}`);
  }
}

// Actioncable
export { subscribeGame, send };
