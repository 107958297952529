import React from "react";

import { getBusinessStage, getIndustry, getWorkerOwnership, getJobCreated, getOwnerMakeup, geEnvironmentalImpact, getLocalSupply } from "./EntityTypes";

export const Business = ({ business, industry }) => {
  return (
    <React.Fragment>
      <div style={{fontSize: "0.55rem"}}>
        <span style={{fontWeight: 600}}>Sector:</span> {getIndustry(industry)}
        <br />
        <span style={{fontWeight: 600}}>Stage:</span> {getBusinessStage(business.stage)}
        <br />
        <span style={{fontWeight: 600}}>Worker Ownership:</span> {getWorkerOwnership(business.worker_ownership)}
        <br />
        <span style={{fontWeight: 600}}>Owners:</span> {getOwnerMakeup(business.owner_makeup)}
        <br />
        <span style={{fontWeight: 600}}>Jobs Created</span>: {getJobCreated(business.jobs_created)}
        <br />
        <span style={{fontWeight: 600}}>Ecological:</span> {geEnvironmentalImpact(business.environment_impact)}
        <br />
        <span style={{fontWeight: 600}}>Local Supply:</span> {getLocalSupply(business.local_supply)}
        <br />
      </div>
    </React.Fragment>
  );
};
