import React from "react";
import { useSelector } from "react-redux";
import { Divider } from "@material-ui/core";
import { gameSelector } from "../slices/game";
import _ from "lodash";

const Scoreboard = ({classes}) => {
  const game = useSelector(gameSelector);

  return (
    <React.Fragment>
      <div className={`${classes.itemContainer} custom-scrollbar`}>
        {
          _.map(game.history_logs, (log) => {
            const player = _.find(game.players, {id: log.playerId});
            const portfolio = _.find(game.portfolios, {id: log.portfolioId});
            const name = _.get(portfolio, ["business", "name"])
            const businessName = name ? ` [${name}] ` : " "; 
            return (
              <div style={{width: "100%", margin: "10px 20px 10px 20px"}}>
                <span style={{fontWeight: 600, marginRight: 5}}>Round {log.round + 1}:</span><span style={{fontWeight: 400}}>[{player.first_name} {player.last_name}]{businessName}{log.log}</span>
                <Divider className={classes.dashedDivider} />
              </div>
            );
          })
        }
      </div>
    </React.Fragment>
  );
}

export default Scoreboard;
