import React, { useState, useEffect } from "react";
import {
  Chip,
  Grid,
  CardContent,
  Button,
  Divider
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  gameSelector,
  playerSelector,
  sessionSelector,
  playSoundSelector,
} from "../slices/game";
import classnames from "classnames";

import { buyBusiness } from "../slices/game";
import { EventBusy, ExpandMore } from "@material-ui/icons";
import { RiskIcon, ImpactIcon, HoldingsValueIcon } from "./GameIcons";
import { Transaction } from "./Transaction";
import { Business } from "./Business";
import { getResourceString } from "./helpers/Strings";
import { GAME_PHASE, PLAYER_STATUS } from "./Constants";

import * as utils from "./helpers/Utils"
import useSound from 'use-sound';
import invest from '../../assets/sounds/invest.mp3';     

import BIITheme from "../theme";

//This class should send the business ID through BUYBUSINESS and needs no more information
const MarketCard = ({business, classes, lang="EN"}) => {
  const dispatch = useDispatch();

  const [ isFlipped, setIsFlipped ] = useState(false);
  const [ play ] = useSound(invest, { interrupt: true });

  const game = useSelector(gameSelector);
  const player = useSelector(playerSelector);
  const session = useSelector(sessionSelector);
  const playSound = useSelector(playSoundSelector);

  const hasMadeRequiredInvestments = utils.hasMadeRequiredInvestments(game, player)

  const canInvest = () => {
    return _.get(session, "status") === PLAYER_STATUS.EXECUTING && (game.phase === GAME_PHASE.DRAFT || game.phase === GAME_PHASE.MARKET) && !hasMadeRequiredInvestments;
  }

  const handleInvest = (business) => {
    if (playSound) {
      play();
    }
    dispatch(buyBusiness(business.id));
  }

  if (business) {
    return (
      <div class="flip-card">
        <div className={classnames(classes.frameContainer, "flip-card-inner", {"is-flipped": isFlipped})} variant="outlined" elevation={10}>
        <div id={business.id} className={classnames("flip-card-face flip-card-front")}>
          <Grid container style={{height: "18rem"}}>
            <Grid item xs={9} style={{height: "1rem", lineHeight: "0.8rem", fontSize: "0.8rem", fontWeight: 600}}>
                {business.name}
            </Grid>
            <Grid item xs={3} style={{paddingLeft: 8, paddingTop: 2}}>
              <div style={{display: "flex"}}>
                <RiskIcon />
                <div className={classes.iconLabel} style={{marginTop: 1, marginBottom: 3}}>{business.risk}</div>
              </div>
              <div style={{display: "flex"}}>
                <ImpactIcon />
                <div className={classes.iconLabel}>{business.social_impact}</div>
              </div>
            </Grid>
            <Grid item xs={12} style={{height: "3.5rem"}}>
              <div style={{display: "flex", fontSize: 10, lineHeight: "120%", marginTop: "0.4rem", marginBottom: "0.4rem", overflow: "auto", maxWidth: "12rem", maxHeight: "2.35rem"}}>{business.description}</div>
            </Grid>
            <Grid item xs={12} style={{height: "2.8rem"}}>
              <Transaction business={business} investment_type={business.investment_type} />
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
              <div style={{display: "flex", justifyContent: "center", margin: "6px 0px 8px 0px"}}>
                <Chip className={classes.investButton} title="Add company to Portfolio" label={getResourceString(lang, "INVEST")} color="primary" disabled={!canInvest()} variant={canInvest() ? "contained" : "outlined"} onClick={() => handleInvest(business)} />
              </div>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Business business={business} industry={business.industry}/>
            </Grid>
          </Grid>
        </div>
      </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MarketCard;
