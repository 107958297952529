const Stats = require('fast-stats').Stats;

import { investmentTypes } from "../EntityTypes";
import {
    getInitialInvestments,
    getPlayerWithHighestAggregateScore,
    getPlayerWithHighestRiskScore,
    getPlayerWithHighestImpactScore,
    getPlayerWithLowestVelocityScore
} from "./Utils"

export const BADGES = {
    WINNER: 0,
    VULTURE: 1,
    SKUNK: 2,
    EAGLE: 3,
    COCKATOO: 4,
    KOALA: 5,
    TORTOISE: 6,
    MONKEY: 7,
}

const Badges = {
    WINNER: {
        id: BADGES.WINNER,
        name: "Capital Superstar",
        description: "Congratulations! You are an Integrated Capital superstar. Your fund successfully created meaningful social and ecological impact while managing risk and sustaining a sufficient amount of financial returns to pay back your investors. Enough with the simulations… Get out there and launch a fund!",
        image: "trophy.png",
        metrics: "Highest aggregate score",
    },
    VULTURE: {
        id: BADGES.VULTURE,
        name: "Vulture Capitalist",
        description: "So you’re in it for the money, hey? You came out on top for financial returns and on the bottom for impact returns. You might be playing the wrong game. Our advice? Go look for a job at Goldman Sachs or Kleiner Perkins–you’re not really an impact investor.",
        image: "vulture.png",
        metrics: "Highest financial returns (top quartile) AND lowest impact returns (below 2.0)",
    },
    SKUNK: {
        id: BADGES.SKUNK,
        name: "Bank Badger",
        description: "You really like burrowing into those debt instruments, don’t you? More than 85 percent of your investments started out that way. You seem to value predictability over risk-taking, which leaves a lot of opportunity–both impact and financial–on the table. Are you sure you want to be a banker?",
        image: "skunk.png",
        metrics: "Overweighted in term loans and other forms of debt. More than 85% of all investments started out as debt",
    },
    EAGLE: {
        id: BADGES.EAGLE,
        name: "Equity Eagle",
        description: "Do you think you’ve got greater vision than the rest of the crowd? That’s what your investing behavior would suggest. As the player with the highest frequency of equity investments, you’re willing to ride the currents alongside your entrepreneurs. We applaud your patience and allyship. Just be sure you can tolerate the uncertainty.",
        image: "eagle.png",
        metrics: "Overweighted in equity and near equity. More than 85% of all investments started out as equity",
    },
    COCKATOO: {
        id: BADGES.COCKATOO,
        name: "Cocky Cockatoo",
        description: "There is no Plan B! Let’s shoot for the moon! As the player with the highest risk score, you have revealed that you’re willing to risk everything for the right deal. As long as you can tolerate losses, there’s nothing wrong with that strategy. Just try to avoid getting wiped out completely–your participation as an impact investor is too important to lose.",
        image: "cockatoo.png",
        metrics: "Highest risk score",
    },
    KOALA: {
        id: BADGES.KOALA,
        name: "Compassionate Koala",
        description: "It’s all about love for you, isn’t it? You topped the charts on impact this time around. That’s good and kind and generous of you. Just be sure your warm-heartedness doesn’t cost you so much capital that you can’t keep investing in entrepreneurs whose efforts you care about supporting. They need you to stay in the game!",
        image: "koala.png",
        metrics: "Highest impact score",
    },
    TORTOISE: {
        id: BADGES.TORTOISE,
        name: "Transaction Tortoise",
        description: "Slow and steady may win the race in the long-term, but it limits how much opportunity you create for entrepreneurs. You executed the lowest number of transactions in the game, which means you rejected more folks than your fellow investors. The purpose is to provide equitable access to capital–try offering that a little more frequently next time.",
        image: "turtle.png",
        metrics: "Lowest number of deals done in the game",
    },
    MONKEY: {
        id: BADGES.MONKEY,
        name: "Monkey in the Middle",
        description: "First, we’d like to congratulate you on thinking holistically. You have managed to balance your performance across risk, return and impact, scoring at the midrange across all three metrics. Kudos on your integrated perspective! But what might your middle-of-the-road strategy be leaving out? Next time, consider aiming for higher impact and/or financial returns.",
        image: "monkey.png",
        metrics: " Mid two quartiles for impact, risk AND return",
    },
};

const isWinner = (game, player) => {
    // highest aggregate score
    const winnerId = getPlayerWithHighestAggregateScore(game);
    return _.get(player, "id") === winnerId;
}

const isVultureCapitalist = (game, session) => {
    // Highest financial returns (top quartile) AND lowest impact returns (bottom quartile)
    if (session) {
        // find p25 and p75 from game sessions
        const financialtStats = new Stats().push(_.map(game.sessions, "finance_score"));
        const impactStats = new Stats().push(_.map(game.sessions, "impact_score"));
        const p75Financial = financialtStats.percentile(75)
        const p25Impact = impactStats.percentile(25)
        const impact_score = Number(_.get(session, "impact_score", "0"));
        const finance_score = Number(_.get(session, "finance_score", "0"));
        return finance_score >= p75Financial && impact_score <= p25Impact;
    } else {
        return false;
    }
}

const isBankBadger = (investments) => {
    // Overweighted in term loans and other forms of debt. More than 85% of all investments started out as debt.
    const debtInvestments = _.filter(investments, {investment_type: investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT});
    return debtInvestments.length / investments.length * 100 > 70
}

const isEquityEagle = (investments) => {
    // Overweighted in equity and near equity. More than 85% of all investments started out as equity. 
    const debtInvestments = _.filter(investments, {investment_type: investmentTypes.INVESTMENT_TYPE_EQUITY});
    return debtInvestments.length / investments.length * 100 > 70
}

const isCockyCockatoo = (game, player) => {
    //  Highest risk score
    const winnerId = getPlayerWithHighestRiskScore(game);
    return _.get(player, "id") === winnerId;
}

const isCompassionateKoala = (game, player) => {
    // Highest impact score
    const winnerId = getPlayerWithHighestImpactScore(game);
    return _.get(player, "id") === winnerId;
}

const isTransactionTortoise = (game, player) => {
    // Lowest number of deals done in the game, i.e. lowest velocity score
    const winnerId = getPlayerWithLowestVelocityScore(game);
    return _.get(player, "id") === winnerId;
}

const getEarnedBadge = (game, session, player) => {
    // check badges in order, return first one meeting metrics
    const initialInvestments = getInitialInvestments(game, player);

    if (isWinner(game, player)) {
        return Badges.WINNER;
    } else if (isVultureCapitalist(game, session)) {
        return Badges.VULTURE;
    } else if (isCockyCockatoo(game, player)) {
        return Badges.COCKATOO;
    } else if (isCompassionateKoala(game, player)) {
        return Badges.KOALA;
    } else if (isTransactionTortoise(game, player)) {
        return Badges.TORTOISE;
    } else if (isBankBadger(initialInvestments)) {
        return Badges.SKUNK;
    } else if (isEquityEagle(initialInvestments)) {
        return Badges.EAGLE;
    } else {
        return Badges.MONKEY;
    }
};
  
export {
    getEarnedBadge
}