import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Button, FormControl, Select, MenuItem, TextField } from "@material-ui/core";
import NumericInput from "react-numeric-input";
import _ from "lodash";

import { investmentTypes } from "./EntityTypes";
import { getResourceString } from "./helpers/Strings";

const CalculationsFormatter = (props) => {
    const [row, setRow] = useState();
    const [options, setOptions] = useState([]);
    const [editCalculations, setEditCalculations] = useState(false);
  
    const handleClose = () => {
        setEditCalculations(false);
    }; 
        
    const handleFieldChange = (id, e) => {
        const _row = _.clone(row);
        _row.calculations[id] = e.target.value;
        setRow(_row);
    };

    const handleOpen = () => {
        setRow(props.row);
        setOptions(props.options);
        setEditCalculations(true);
    };

    const handleSaveCalculations = () => {
        row.action = "update";
        props.handleRowActions([row], "company");
        setEditCalculations(false);
    };

    const canEditInputs = () => {
        // disable changing of calculation of EQUITY investments
        return _.get(props.row, "investment_type") !== 0 ? "block": "none";
    };
    
    const showDialog = () => {
        const renderSelect = (id, name, value, options, required=false) => {
            return (
                <FormControl variant="outlined" fullWidth>
                    <span>{getResourceString(props.lang, name.toUpperCase())}</span>
                    <Select
                        style={{marginTop: 8, marginBottom: 8}}
                        value={value}
                        onChange={(e) => handleFieldChange(id, e)}
                    >
                        {
                        _.map(options, (option) => {
                            return <MenuItem label={name} key={option.id} value={option.value}>{option.text}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            );
        };
        
        const renderNumericInput = (id, name, value, required=false) => {
            return (
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <span>{getResourceString(props.lang, name.toUpperCase())}</span>
                    <NumericInput
                        className="form-control"
                        fullWidth
                        id={id}
                        value={value}
                        onChange={(v) => handleFieldChange(id, {target: {value: v}})}
                    />
                </div>
            );
        };
        
        const renderTextField = (id, name, value, required=false) => {
            return (
                <TextField
                    variant="outlined"
                    margin="normal"
                    required={required}
                    fullWidth
                    id={id}
                    label={name}
                    name={name}
                    value={value}
                    onChange={(e) => handleFieldChange(id, e)}
                />
            );
        };

        const renderCalculation = (key) => {
            const investmentType = _.find(options, {value: row.investment_type});
            let inputOptions = investmentType.options[key];
            // if investment type is royalty financing, handle revenue and growth_rate
            if (row.investment_type === investmentTypes.INVESTMENT_TYPE_ROYALTY_FINANCE) {
                if (key === "revenue" || key === "growth_rate") {
                    inputOptions = investmentType.options[key][row.stage]
                }
            }

            const value = row.calculations[key];
            const isArray = Array.isArray(inputOptions);
            if (isArray) {
                return renderSelect(key, key, value, inputOptions, true)
            } else {
                return renderNumericInput(key, key, value, true)
            }
        };
    
        return (
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="customized-dialog-title"
                open={editCalculations}
            >
            <DialogTitle id="customized-dialog-title">
            Calculation Inputs
            </DialogTitle>  
            <DialogContent dividers>
                <form noValidate>
                {
                    _.map(_.keys(_.get(row, "calculations")), key => renderCalculation(key))
                }
                </form>
            </DialogContent>
            <div style={{display: "flex", justifyContent: "right"}}>
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}} onClick={handleSaveCalculations} /*disabled={!canSubmit}*/>
                Ok
                </Button>
                <Button autoFocus style={{color: "white", backgroundColor: "#0093B2", margin: 5}}  onClick={handleClose}>
                Cancel
                </Button>
            </div>
            </Dialog>
        );
    }

    return <div>
            <span className = "btn-group  btn-group-sm">
                <button type="button" className="btn btn-primary" onClick={handleOpen} style={{display: canEditInputs()}}>Edit</button>
            </span>
            {
                showDialog()
            }
        </div>
}

export default CalculationsFormatter;