const GAME_PHASE =  {
    // must match Game class in Ruby
    OPEN: "open",
    PREVIEW: "preview",
    DRAFT: "draft",
    MARKET: "market",
    EVENT: "event",
    GLOBAL: "global",
    MANAGEMENT: "management",
    COMPLETE: "complete",
}

const PLAYER_STATUS =  {
    // must match Player class in Ruby
    INACTIVE: "inactive",
    ACTIVE: "active",
    EXECUTING: "executing",
    WAITING: "waiting",
    READY: "ready",
    LEFT: "left",
}

const PORTFOLIO_STATUS =  {
    // must match Portfolio claass in Ruby
    SELECTED: "selected",   // only for market place
    ACTIVE: "active",
    COMPLETE: "complete",
    WRITTEN_OFF: "writtenOff",
}

export const FUND_MANAGER_GAME_ID_KEY = 'fund_manager_game_id';
export const FUND_MANAGER_PLAYER_ID_KEY = 'fund_manager_player_id';
export const FUND_MANAGER_COUNTDOWN_TIMESTAMP = 'fund_manager_countdown_timestamp';


export { GAME_PHASE, PLAYER_STATUS, PORTFOLIO_STATUS }
