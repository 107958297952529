import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { install } from "resize-observer";
import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { Chip, Dialog, DialogContent, FormControl, FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";
import { GAME_PHASE, PLAYER_STATUS, FUND_MANAGER_COUNTDOWN_TIMESTAMP } from "./Constants";

import { Player } from "./Player";
import Position from "./Position";
import Market from "./Market";
import Portfolio from "./Portfolio";
import Scoreboard from "./Scoreboard";
import History from "./History";
import Administration from "./Administration";
import Footer from "./Footer";

import { getInvestmentType, getIndustry } from "./EntityTypes";
import "../../assets/stylesheets/games.scss";
import { gameSelector, playerSelector, sessionSelector, playSoundSelector, completeTurn, selectEvent, updatePlayerStatus } from "../slices/game";
import * as utils from "./helpers/Utils"
import { ConfirmDialog } from "./helpers/ConfirmDialog"

import Countdown from "react-countdown";
import toWordConverter from 'number-to-words';
import { getAggregateScore } from "./helpers/Utils"

import BIITheme from "../theme";
import { BADGES, getEarnedBadge } from "./helpers/Badges"
import { getResourceString } from "./helpers/Strings"

import useSound from 'use-sound';
import harp from '../../assets/sounds/harp.mp3';     
import cashRegister from '../../assets/sounds/cash-register.mp3';     
import winner from '../../assets/sounds/winner.mp3';     
import vulture from '../../assets/sounds/jungle.mp3';
import cockatoo from '../../assets/sounds/jungle.mp3';     
import kaola from '../../assets/sounds/jungle.mp3';     
import tortoise from '../../assets/sounds/tortoise.mp3';     
import skunk from '../../assets/sounds/jungle.mp3';     
import eagle from '../../assets/sounds/jungle.mp3';     
import monkey from '../../assets/sounds/monkey.mp3';     

const GAME_STATE = {
  NO_OP: 0,
  WAIT_FOR_PLAYERS_TO_JOIN: 1,
  WAITING_FOR_TURN: 2,
  SELECT_INVESTMENTS: 3,
  CONFIRM_INVESTMENTS: 4,
  COUNTDOWN_COMPLETE: 5,
  WAIT_FOR_PLAYERS_TO_COMPLETE: 6,
  DRAW_EVENT_CARD: 7,
  SHOW_EVENT_CARD: 8,
  MANAGE_EVENT_CARD: 9,
  REVIEW_POSITION: 10,    // in this state, the 'Review your Portfolio' dialog will be sh
  PREVIEW_COUNTDOWN: 11,
  REVIEW_PORTFOLIO: 12,
  VIEW_BADGE: 13,
  GAME_COMPLETE: 14
}

const GAME_VIEW_MODE = {
  OPEN: 0,
  PREVIEW: 1,
  MARKET: 2,
  PORTFOLIO: 3,
  SCOREBOARD: 4,
  HISTORY: 5,
  COMPLETE: 6,
  ADMINISTRATION: 7
}

export const themeMode = "dark";

export const Game = ({lang, isAdminUser, organization, classes, startGameHandler, leaveGameHandler, devMode}) => {
  
  const dispatch = useDispatch();
  const game = useSelector(gameSelector); 
  const player = useSelector(playerSelector);
  const session = useSelector(sessionSelector);
  const playSound = useSelector(playSoundSelector);

  // sound files
  const [ playHarp ] = useSound(harp, { interrupt: true });
  const [ playCashRegister ] = useSound(cashRegister, { interrupt: true });
  const [ playWinner ] = useSound(winner, { interrupt: true });
  const [ playVulture ] = useSound(vulture, { interrupt: true });
  const [ playCockatoo ] = useSound(cockatoo, { interrupt: true });
  const [ playKaola ] = useSound(kaola, { interrupt: true });
  const [ playTortoise ] = useSound(tortoise, { interrupt: true });
  const [ playSkunk ] = useSound(skunk, { interrupt: true });
  const [ playEagle ] = useSound(eagle, { interrupt: true });
  const [ playMonkey ] = useSound(monkey, { interrupt: true });

  // game state and view mode
  const [ gameState, setGameState ] = useState(GAME_STATE.NO_OP);
  const [ viewMode, setViewMode ] = useState(GAME_VIEW_MODE.MARKET);
  const [ countdownTimestamp, setCountdownTimestamp] = useState();
  
  // confirm dialog
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const [ confirmMessage, setConfirmMessage ] = useState("");
  const [ confirmMode, setConfirmMode ] = useState("start");

  const isMenuActive = (menu) => {
    return viewMode === menu;
  }

  install();
  
  const portfolios = utils.getPlayerPortfolios(game, player);
  const development = _.get(session, "development");
  const playerHasEvent = !!development;
  const playerHasGlobalEvent = playerHasEvent && development.effect_subject === 'global'

  const validGameSessionPlayer = () => {
    return isAdminUser || (!!game && !!session && !!player);
  }

  useEffect(() => {
    if (!validGameSessionPlayer()) {
      return;
    }

    if (isAdminUser) {
      setViewMode(GAME_VIEW_MODE.ADMINISTRATION);
    }

    const gamePhase = _.get(game, "phase");

    if (gamePhase === GAME_PHASE.OPEN) {
      setViewMode(GAME_VIEW_MODE.OPEN);
      setGameState(GAME_STATE.WAIT_FOR_PLAYERS_TO_JOIN);
    }

    if (gamePhase === GAME_PHASE.PREVIEW) {
      setViewMode(GAME_VIEW_MODE.PREVIEW);
      if (gameState !== GAME_STATE.PREVIEW_COUNTDOWN && gameState !== GAME_STATE.SELECT_INVESTMENTS) {
        setGameState(GAME_STATE.WAITING_FOR_TURN);
      }
    }

    if (gamePhase === GAME_PHASE.DRAFT || gamePhase === GAME_PHASE.MARKET) {
      if (session) {
        if (isPlayerActive()) {
          removeExecuteTimer(); // remove timer when active
          if (gameState === GAME_STATE.WAITING_FOR_TURN) {
          } else {
            setGameState(GAME_STATE.WAITING_FOR_TURN)
          }
        } else if (isPlayerExecuting()) {
          // active player has already made required investments, check if investments has been confirmed
          if (utils.hasMadeRequiredInvestments(game, player)) {
            setGameState(GAME_STATE.CONFIRM_INVESTMENTS)
          } else {
            // player needs to make investments
            setGameState(GAME_STATE.SELECT_INVESTMENTS)
            // set or load countdown timestamp from localstorage to prevent it being reset if it has already started
            setExecuteTimer();
          }
        } else if (isPlayerReady()) {
          removeExecuteTimer(); // remove timer when ready
          if (gameState !== GAME_STATE.REVIEW_PORTFOLIO) {
            setGameState(GAME_STATE.WAIT_FOR_PLAYERS_TO_COMPLETE)
          }
        } else {
          // if player is not reviewing portfolio
          if (gameState !== GAME_STATE.REVIEW_PORTFOLIO) {
            // player is ready or waiting
            setGameState(GAME_STATE.WAITING_FOR_TURN);
          }
        }

        // only set view mode if it is open
        if (viewMode === GAME_VIEW_MODE.OPEN) {
          setViewMode(GAME_VIEW_MODE.MARKET)
        }
      }
    }

    if (gamePhase === GAME_PHASE.EVENT || gamePhase === GAME_PHASE.GLOBAL) {
      setViewMode(GAME_VIEW_MODE.PORTFOLIO)
      if (session) {
        if (isPlayerReady()) {
          if (gameState !== GAME_STATE.REVIEW_PORTFOLIO) {
            setGameState(GAME_STATE.WAIT_FOR_PLAYERS_TO_COMPLETE)
          }
        } else {
          if (isPlayerExecuting()) {
            setGameState(GAME_STATE.MANAGE_EVENT_CARD);
          } else {
            setGameState(playerHasEvent ? GAME_STATE.SHOW_EVENT_CARD : GAME_STATE.DRAW_EVENT_CARD)
          }
        }
      }
    }

    if (gamePhase === GAME_PHASE.MANAGEMENT) {
      if (session) {
        if (isPlayerReady()) {
          if (gameState !== GAME_STATE.REVIEW_PORTFOLIO) {
            setGameState(GAME_STATE.WAIT_FOR_PLAYERS_TO_COMPLETE)
          }
          setViewMode(GAME_VIEW_MODE.MARKET)
        } else {
          if (isPlayerExecuting()) {
            setGameState(GAME_STATE.REVIEW_PORTFOLIO);
          } else {
            setGameState(GAME_STATE.REVIEW_POSITION)
          }
          setViewMode(GAME_VIEW_MODE.PORTFOLIO)
        }
      }
    }

    if (gamePhase === GAME_PHASE.COMPLETE) {
      setViewMode(GAME_VIEW_MODE.COMPLETE);
      setGameState(GAME_STATE.GAME_COMPLETE);
    }
  }, [isAdminUser, game, session, player])

  const isPlayerWaiting = () => {
    return _.get(session, "status") === PLAYER_STATUS.WAITING;
  }

  const isPlayerActive = () => {
    return _.get(session, "status") === PLAYER_STATUS.ACTIVE;
  }

  const isPlayerExecuting = () => {
    return _.get(session, "status") === PLAYER_STATUS.EXECUTING;
  }

  const isPlayerReady = () => {
    return _.get(session, "status") === PLAYER_STATUS.READY;
  }

  const isPlayerNotReady = (session) => {
    const ready = _.get(session, "status") === PLAYER_STATUS.READY;
    const left = _.get(session, "status") === PLAYER_STATUS.LEFT;
    return !ready && !left
  }

  const setExecuteTimer = () => {
    let timestamp = window.localStorage.getItem(FUND_MANAGER_COUNTDOWN_TIMESTAMP);
    if (!timestamp) {
      timestamp = Date.now() + getCountdownInMinutes() * 60000;
      window.localStorage.setItem(FUND_MANAGER_COUNTDOWN_TIMESTAMP, timestamp);
      setCountdownTimestamp(timestamp);
    } else {
      setCountdownTimestamp(Number(timestamp));
    }
  }

  const removeExecuteTimer = () => {
    window.localStorage.removeItem(FUND_MANAGER_COUNTDOWN_TIMESTAMP);
  }

  const handleStartGame = () => {
    const message = "Are you sure you want to start the game?"
    setConfirmMessage(message);
    setConfirmMode("start");
    setConfirmOpen(true);
  }

  const handleLeaveGame = () => {
    const message = isAdminUser ? "Are you sure you want to logout?" : "Are you sure you want to leave the game?"
    setConfirmMessage(message);
    setConfirmMode("leave");
    setConfirmOpen(true);
  }

  const showConfirmDialog = () => {
    return (
      <ConfirmDialog
        message={confirmMessage}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={confirmMode === "start" ? startGameHandler : leaveGameHandler}
      />
    )
  }
  
  const showSideMenu = () => {
    return (
      <nav class="main-menu">
        {
          isAdminUser ? 
            <ul>
              <li className={isMenuActive(GAME_VIEW_MODE.ADMINISTRATION) ? 'active' : ''} onClick={() => setViewMode(GAME_VIEW_MODE.ADMINISTRATION)}>
                <a>
                  <i class="fa fa-tasks fa-2x"></i>
                  <span class="nav-text">
                      Administration
                  </span>
                </a>
              </li>
            </ul>
            :
            <ul>
              <li className={isMenuActive(GAME_VIEW_MODE.MARKET) ? 'active' : ''} onClick={() => setViewMode(GAME_VIEW_MODE.MARKET)}>
                <a>
                  <i class="fa fa-th-list fa-2x"></i>
                  <span class="nav-text">
                    Marketplace
                  </span>
                </a>
              </li>
              <li className={isMenuActive(GAME_VIEW_MODE.PORTFOLIO) ? 'active' : ''} onClick={() => setViewMode(GAME_VIEW_MODE.PORTFOLIO)}>
                <a>
                  <i class="fa fa-university fa-2x"></i>
                  <span class="nav-text">
                    Portfolio
                  </span>
                </a>
              </li>
              <li className={isMenuActive(GAME_VIEW_MODE.SCOREBOARD) ? 'active' : ''} onClick={() => setViewMode(GAME_VIEW_MODE.SCOREBOARD)}>
                <a>
                  <i class="fa fa-users fa-2x"></i>
                  <span class="nav-text">
                      Scoreboard
                  </span>
                </a>
              </li>
              </ul>
        }
        {/* {
          isAdminUser &&
            <ul>
              <li className={isMenuActive(GAME_VIEW_MODE.HISTORY) ? 'active' : ''} onClick={() => setViewMode(GAME_VIEW_MODE.HISTORY)}>
                <a>
                  <i class="fa fa-clock-o fa-2x"></i>
                  <span class="nav-text">
                      Game History
                  </span>
                </a>
              </li>
            </ul>
        } */}
        <ul style={{position: "absolute", bottom: 158, zIndex: 1}}>
          <li onClick={handleLeaveGame}>
              <a>
                  <i class="fa fa-sign-out fa-2x"></i>
                  <span class="nav-text">
                    {isAdminUser ? "Logout" : "Leave Game"}
                  </span>
              </a>
          </li>  
      </ul>
    </nav>
    );
  }

  const showMainViewTitle = () => {
    if (viewMode === GAME_VIEW_MODE.PREVIEW) {
      return "Marketplace" ;
    }

    if (viewMode === GAME_VIEW_MODE.OPEN) {
      return "Marketplace";
    }
  
    if (viewMode === GAME_VIEW_MODE.MARKET) {
      return "Marketplace";
    }
  
    if (viewMode === GAME_VIEW_MODE.PORTFOLIO) {
      return "Portfolio";
    }

    if (viewMode === GAME_VIEW_MODE.SCOREBOARD) {
      return "Scoreboard";
    }

    if (viewMode === GAME_VIEW_MODE.HISTORY) {
      return "Game History";
    }

    if (viewMode === GAME_VIEW_MODE.COMPLETE) {
      return "Portfolio";
    }

    if (viewMode === GAME_VIEW_MODE.ADMINISTRATION) {
      return `Administration [${_.get(organization, "name", "")}]`;
    }
    return ""
  }

  const getCountdownInMinutes = () => {
    return game.phase === GAME_PHASE.DRAFT ? 3 : 1;
  }

  const setMarketViewMode = () => {
    setViewMode(GAME_VIEW_MODE.MARKET);
  }

  const handleVisitMarket = () => {
    setMarketViewMode()
    if (isPlayerActive()) {
      dispatch(updatePlayerStatus(PLAYER_STATUS.EXECUTING));
    } else {
      setGameState(GAME_STATE.WAITING_FOR_TURN);
    }
  }

  const handleDrawEventCard = (override=false) => {
    if (playSound) {
      playHarp();
    }
    dispatch(selectEvent(override));
  }

  const handleManagePortfolio = () => {
    dispatch(updatePlayerStatus(PLAYER_STATUS.EXECUTING));
    setViewMode(GAME_VIEW_MODE.PORTFOLIO);
    setGameState(GAME_STATE.SHOW_EVENT_CARD)
  }

  const handleManageInvestments = () => {
    removeExecuteTimer();
    handleCompleteTurn()
  }

  const handleReselectInvestments = () => {
    setViewMode(GAME_VIEW_MODE.PORTFOLIO);
    setGameState(GAME_STATE.SELECT_INVESTMENTS);
  }

  const handleReviewPortfolio = () => {
    if (game.phase === GAME_PHASE.MANAGEMENT) {
      dispatch(updatePlayerStatus(PLAYER_STATUS.EXECUTING));
    } else {
      setViewMode(GAME_VIEW_MODE.PORTFOLIO);
      setGameState(GAME_STATE.REVIEW_PORTFOLIO);
    }
  }

  const handleViewEarnedBadge = () => {
    setGameState(GAME_STATE.VIEW_BADGE);
    const badge = getEarnedBadge(game, session, player)
    switch (badge.id) {
      case BADGES.WINNER:
        return playWinner();
      case BADGES.VULTURE:
          return playVulture();
      case BADGES.COCKATOO:
        return playCockatoo();
      case BADGES.KAOLA:
        return playKaola();
      case BADGES.TORTOISE:
        return playTortoise();
      case BADGES.SKUNK:
        return playSkunk();
      case BADGES.EAGLE:
        return playEagle();
      case BADGES.MONKEY:
        return playMonkey();
    }
  }

  const handleViewScoreboard = () => {
    setViewMode(GAME_VIEW_MODE.SCOREBOARD);
    setGameState(GAME_STATE.NO_OP);
  }

  const handleCompleteTurn = () => {
    // playCashRegister();
    dispatch(completeTurn());
  }

  // show "Next Event Card" button for BII games
  const isBIIPlayer = !_.get(player, "organization_id") === 1;
  
  const showWaitingForPlayersToJoin = () => {
    // show waiting for players to join
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.WAIT_FOR_PLAYERS_TO_JOIN}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
          Waiting for {game.number_of_players - _.get(game, "players.length")} more to join game
          </Typography>
          <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
            The game requires {game.number_of_players} players.  Instead of waiting, you may start the game by clicking on the 'Start Game' button below, or leave the game.
          </Typography>
          { showStartLeaveButtons() }
        </div>
      </DialogContent>
    </Dialog>
  }

  const shouldShowMarketMessage = () => {
    if (gameState === GAME_STATE.WAITING_FOR_TURN && game.phase === GAME_PHASE.DRAFT) {
      return true;
    } else {
      return false
    }
  }

  const showMarketTitle = () => {
    if (isPlayerReady()) {
      return 'Your turn is complete. Well done!'
    }
    if (isPlayerActive()) {
      return "All Players Ready"
    }
    if (isPlayerWaiting()) {
      return "Please wait for your turn"
    }
  }

  const showMarketMessage = () => {
    const requiredInvestmentsInRound = utils.getRequiredInvestmentsInRound(game);

    const currentPlayer = _.get(game, "current_player")
    if (!currentPlayer) {
      return null
    }
    return (
      game.phase === GAME_PHASE.DRAFT ? 
        `${game.draft_round > 0 ? 'It’s the beginning of draft round ' + toWordConverter.toWords(game.draft_round + 1) + '. You' : 'To get started, you' }  will have ${toWordConverter.toWords(getCountdownInMinutes())} minutes to review and select ${toWordConverter.toWords(requiredInvestmentsInRound)} ${requiredInvestmentsInRound > 1 ? "companies" : "company"} from MarketPlace which will be added to your portfolio. You will be able to add more at each turn.`
        :
        `${game.round > 0 ? 'It’s the beginning of round ' + toWordConverter.toWords(game.round + 1) + '. You' : 'To get started, you' }  will have ${toWordConverter.toWords(getCountdownInMinutes())} minutes to review and select ${toWordConverter.toWords(requiredInvestmentsInRound)} ${requiredInvestmentsInRound > 1 ? "companies" : "company"} from MarketPlace which will be added to your portfolio. You will be able to add more at each turn.`
      )
  }

  const showMarketLink = () => {
    if (isPlayerActive()) {
      return showVisitMarket()
    } else if (isPlayerReady()) {
      return showReviewPortfolio()
    }
    return showReviewPortfolio()
  }

  const showCountDown = () => {
    return <div>
      <MemoCountdown/>
    </div>
  }

  const showMarket = () => {
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={shouldShowMarketMessage()}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{margin: "auto", textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            { showMarketTitle() }
          </Typography>
            <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
              { showMarketMessage() }
            </Typography>
            { showMarketLink() }
        </div>
      </DialogContent>
    </Dialog>
  }

  const showRoundStatus = () => {
    const businessesInPortfolios = utils.getPlayerPortfolios(game, player).length;
    const requiredInvestments = utils.hasMadeRequiredInvestments(game)
    const session = _.find(game.sessions, {player_id: _.get(player, "id")});
    const status = _.get(session, "status");

    // hide button if still waiting for players or game has ended
    switch (game.phase) {
      case GAME_PHASE.OPEN:
        // return (
        //   <Typography className={classes.turnCompleteButton} style={{backgroundColor: "lightgrey"}}>
        //     {_.toUpper(getResourceString(lang, "WAIT_FOR_PLAYERS"))}
        //   </Typography>
        // );
        break;
      case GAME_PHASE.DRAFT:
      case GAME_PHASE.MARKET:
        // if (status === Player.INACTIVE) {
        //   return (
        //     <Typography className={classes.turnCompleteButton}>
        //       {_.toUpper(getResourceString(lang, "WAIT_FOR_TURN"))}
        //     </Typography>
        //   );
        // }
        if (status === PLAYER_STATUS.READY) {
          // return (
          //   <Typography className={classes.roundStatusText}>
          //     {_.toUpper(getResourceString(lang, "WAIT_FOR_OTHERS"))}
          //   </Typography>
          // );
        } else {
          return businessesInPortfolios < requiredInvestments &&
            <Typography className={classes.roundStatusText}>
              {_.toUpper(getResourceString(lang, "NEED_MORE_INVESTMENT_1") + (requiredInvestments) + getResourceString(lang, "NEED_MORE_INVESTMENT_2"))}
            </Typography>
        }
        break;
      case GAME_PHASE.EVENT:
      case GAME_PHASE.GLOBAL:
      case GAME_PHASE.MANAGEMENT:
        if (status === PLAYER_STATUS.READY) {
          // return (
          //   <Typography className={classes.roundStatusText}>
          //     {_.toUpper(getResourceString(lang, "WAIT_FOR_OTHERS"))}
          //   </Typography>
          // );
        }
        break;
      case GAME_PHASE.COMPLETE:
        // return (
        //   <Typography className={classes.roundStatusText}>
        //     {_.toUpper(getResourceString(lang, "GAME_COMPLETED"))}
        //   </Typography>
        // );
        break;
    }
  };

  const showCountdownComplete = () => {
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.COUNTDOWN_COMPLETE}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            {
                `Your time is up and your turn is over.  Please start managing your investments.`
            }
          </Typography>
          <div style={{display: "flex", justifyContent: "space-evenly", margin: "6px 0px 8px 0px"}}>
            <div>
              <Chip
                label="CONTINUE"
                onClick={handleManageInvestments}
                variant="outlined"
                style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
              />
              <Typography style={{paddingTop: 5, fontSize: 10, width: 150, textAlign: "center"}}>
                Start managing your investments
              </Typography>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  }

  const showConfirmInvestments = () => {
    const requiredInvestmentsInRound = utils.getRequiredInvestmentsInRound(game);

    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.CONFIRM_INVESTMENTS}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            {
              `You have selected ${toWordConverter.toWords(requiredInvestmentsInRound)} ${requiredInvestmentsInRound > 1 ? "companies" : "company"}. Are your investment choices final?`
            }
          </Typography>
          <div style={{display: "flex", justifyContent: "space-evenly", width: 360}}>
            <div>
              <Chip
                label="YES"
                onClick={handleManageInvestments}
                variant="contained"
                style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
              />
              <Typography style={{paddingTop: 5, fontSize: 10, width: 150, textAlign: "center"}}>
                Start managing your investments
              </Typography>
            </div>
              <div>
                <Chip
                  label ="NO"
                  onClick={handleReselectInvestments}
                  variant="outlined"
                  style={{ width: 150, backgroundColor: BIITheme.palette.dark.secondary.button, color: "black" }}
                />
                <Typography style={{paddingTop: 5, fontSize: 10, width: 150, textAlign: "center"}}>
                  Re-select investments in your Portfolio
                </Typography>
              </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  }

  const showWaitingForPlayersToCompleteTurn = () => {
    // show waiting for players to join
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.WAIT_FOR_PLAYERS_TO_COMPLETE}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            Your turn is complete.  Well done!
          </Typography>
          <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
            {
            `Other players are still ${game.phase === GAME_PHASE.DRAFT || game.phase === GAME_PHASE.MARKET ? 'selecting their investments' : 'managing their events'}. You will receive a notification as soon as you can take your next turn.`
            }
          </Typography>
          { showReviewPortfolio() }
        </div>
      </DialogContent>
    </Dialog>
  }

  const renderWithOptions = (development) => {
    const options = _.split(development.name, "|")
    const value = options.shift();
    return (
        <div style={{marginTop: 0, marginLeft: 20, marginRight: 20, padding: 10, height: 178, border: "1px dotted lightgrey", borderRadius: 16}}>
            <p style={{textAlign: "center"}} >{value}</p>
            <FormControl style={{paddingTop: 0}}>
                <RadioGroup
                    aria-labelledby="development-options-group"
                    name="development-options"

                >
                    {
                        _.map(options, (option, index) => {
                            return <FormControlLabel value={index.toString()} control={<Radio size="small" style={{height: 8}}  disabled />} label={<Typography style={{fontSize: 12}}>{option}</Typography>} />
                        })
                    }
                </RadioGroup>
            </FormControl>
        </div>
    );
  };

  const showManageEventCard = () => {
    const noEventCanBeApplied = _.isEmpty(utils.getPortfoliosForEvent(portfolios, development));

    return <Dialog
        PaperProps={{
          borderRadius: 15,
          style: {
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.SHOW_EVENT_CARD}
    >
      {/* <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, paddingTop: 24, alignItems: "center", borderWidth: 10, borderStyle: "dashed", borderColor: "#44D62C"}}> */}
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, padding: 0, alignItems: "center", borderWidth: 4, borderStyle: "solid", borderColor: "grey"}}>
        <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "center", background: "#0093B2", color: "white", fontSize: 20, fontWeight: 600, padding: 4}}>Individual Event Card</div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{position: "absolute", left: 20, top: 40}}>
              <img src={require('../../assets/images/individual-event.png')} alt='Manage Event' class="badge-center" width="80" height="80" />
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: 100, paddingTop: 20}}>
              <Typography variant="h7" component="h6" style={{textAlign: "center", fontWeight: 600, paddingBottom: 10}}>
                Sector: <font color="blue">{getIndustry(_.get(development, "industry"), "All")}</font>
              </Typography>
              <Typography variant="h7" component="h6" style={{textAlign: "center", fontWeight: 600, paddingBottom: 20}}>
                Investment Type: <font color="blue">{getInvestmentType(_.get(development, "investment_type"), "All")}</font>
              </Typography>
            </div>
          </div>
          { playerHasEvent && renderWithOptions(development) }
          { showManagePortfolio() }
          {
            isBIIPlayer && 
              <Chip className={classes.chipButton} style={{position: "fixed"}} label="Draw another Event Card" color="primary" variant="outlined" width="200" onClick={() => handleDrawEventCard(true)} />
          }
        </div>
      </DialogContent>
    </Dialog>
  }

  const showDrawEventCard = () => {
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.DRAW_EVENT_CARD}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            All Players Ready
          </Typography>
          <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
            It's time to manage your portfolio.
          </Typography>
          <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Draw an Event Card" color="primary" variant="outlined" onClick={() => handleDrawEventCard(false)} />
        </div>
      </DialogContent>
    </Dialog>
  }

  const showGlobalEventCard = () => {
    const noEventCanBeApplied = _.isEmpty(utils.getPortfoliosForEvent(portfolios, development));
    const eventsRequired = _.get(development, "required", 0) > 0;

    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={playerHasGlobalEvent && gameState === GAME_STATE.SHOW_EVENT_CARD}
    >
      <DialogContent className="global-event-card" style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, paddingTop: 24, justifyContent: "top", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <div style={{marginBottom: 20}}>
            <img src={require('../../assets/images/global-event.png')} alt='Global Event' class="badge-center" width="80" height="80" />
          </div>
          { playerHasGlobalEvent && renderWithOptions(development) }
          {/* {
            eventsRequired && noEventCanBeApplied &&
              <div>
                <Typography component="p" style={{color: "red", width: 450, textAlign: "center", margin: "auto", fontWeight: 600, paddingBottom: 10}}>
                  This event does not apply to any of your portfolio companies. You must pass.
                </Typography>
              </div>
          } */}
          { showManagePortfolio() }
          {
            isBIIPlayer && 
              <Chip className={classnames({"flashing": false}, classes.chipButton)} style={{position: "fixed"}} label="Draw another Event Card" color="primary" variant="outlined" onClick={() => handleDrawEventCard(true)} />
          }
        </div>
      </DialogContent>
    </Dialog>
  }

  const showViewPosition = () => {
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.REVIEW_POSITION}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
            All Players Ready
          </Typography>
          <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
            {
              `It’s the end of round ${toWordConverter.toWords(game.round + 1)} and your investments may be paying off. Please review your Portfolio and updated Fund Position.`
            }
          </Typography>
          { showReviewPortfolio() }
        </div>
      </DialogContent>
    </Dialog>
  }

  const showViewBadge = () => {
    const badge = getEarnedBadge(game, session, player)
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={gameState === GAME_STATE.GAME_COMPLETE}
        // open={gameState === GAME_STATE.VIEW_BADGE}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "top", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
           <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 0}}>
             Game Over!
           </Typography>
          <div>
            <img src={require(`../../assets/images/${badge.image}`)} alt={badge.name} class="badge-center" width="80" height="80" />
          </div>
          <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingTop: 5, paddingBottom: 5}}>
            { badge.name }
          </Typography>
          <Typography style={{textAlign: "center", fontSize: "1rem", fontWeight: 300, paddingTop: 0, paddingBottom: 15}}>
            { badge.description }
          </Typography>
          { showViewScoreboard() }
        </div>
      </DialogContent>
    </Dialog>
  }

  // const showScoreboard = () => {
  //   return <Dialog
  //       PaperProps={{
  //         style: {
  //           borderRadius: 15,
  //           backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
  //         }
  //       }}
  //       fullWidth={true}
  //       maxWidth={"sm"}
  //       aria-labelledby="customized-dialog-title"
  //       open={gameState === GAME_STATE.GAME_COMPLETE}
  //   >
  //     <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, paddingTop: 50, alignItems: "center"}}>
  //       <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
  //         <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
  //           Game Over!
  //         </Typography>
  //         <Typography variant="h6" component="h3" style={{textAlign: "center", fontWeight: 300, paddingBottom: 40}}>
  //           Congratulations! You are an Integrated Capital superstar. 
  //         </Typography>
  //         { showEarnedBadge() }
  //       </div>
  //     </DialogContent>
  //   </Dialog>
  // }

  const showMainView = (classes) => {
    if (viewMode === GAME_VIEW_MODE.MARKET) {
      return <Market classes={classes} />;
    }

    if (viewMode === GAME_VIEW_MODE.PORTFOLIO) {
      return <Portfolio lang={lang} classes={classes} setMarketViewMode={setMarketViewMode}/>;
    }

    if (viewMode === GAME_VIEW_MODE.SCOREBOARD) {
      return <Scoreboard classes={classes} />;
    }

    if (viewMode === GAME_VIEW_MODE.HISTORY) {
      return <History classes={classes} />;
    }

    if (viewMode === GAME_VIEW_MODE.COMPLETE) {
      return <Portfolio lang={lang} classes={classes} />;
    }

    if (viewMode === GAME_VIEW_MODE.ADMINISTRATION) {
      return <Administration classes={classes} lang={lang} isAdminUser={isAdminUser} devMode={devMode} />;
    }
  }

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // show dialog that time is up and player loses turn, with current invested portfolio
      setGameState(GAME_STATE.COUNTDOWN_COMPLETE);
      return null;
    } else {
      // Render a countdown
      const color = minutes == 0 && seconds <= 30 ? classes.countdownRed : classes.countdownYellow;
      return (
        (utils.hasMadeRequiredInvestments(game, player) || game.phase === GAME_PHASE.MARKET) ?
          <Chip className={color} title="I do not wish to invest" label={`${minutes} minutes ${seconds < 10 ? '0': ''}${seconds} seconds`} variant="outlined" onDelete={handleManageInvestments} />
        :
          <Chip className={color} label={`${minutes} minutes ${seconds < 10 ? '0': ''}${seconds} seconds`} variant="outlined" />
      );
    }
  };

  // memorize countdown to have internal state of count down interval remains
  const CountdownWrapper = () => <Countdown date={countdownTimestamp} renderer={renderer} />;
  const MemoCountdown = React.memo(CountdownWrapper);

  const shouldShowCountdown = () => {
    return (gameState === GAME_STATE.SELECT_INVESTMENTS || gameState === GAME_STATE.CONFIRM_INVESTMENTS) && isPlayerExecuting()
  }

  const showStartLeaveButtons = () => {
    return (
      <div style={{display: "flex", justifyContent: "space-evenly", width: 360}}>
        <div>
          <Chip
            label="Start Game"
            onClick={handleStartGame}
            variant="contained"
            style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
          />
        </div>
        <div>
          <Chip
            label ="Leave Game"
            onClick={handleLeaveGame}
            variant="outlined"
            style={{ width: 150, backgroundColor: BIITheme.palette.dark.secondary.button, color: "black" }}
          />
        </div>
      </div>
    );
  }

  const showVisitMarket = () => {
    return <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Visit Marketplace" color="primary" variant="outlined" width="400px" onClick={handleVisitMarket} />
  }

  const showManagePortfolio = () => {
    return <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Manage event in Portfolio" color="primary" variant="outlined" width="50%" onClick={handleManagePortfolio} />
  }

  const showEarnedBadge = () => {
    return <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Show Earned Badge" color="primary" variant="outlined" width="50%" onClick={handleViewEarnedBadge} />
  }

  const showViewScoreboard = () => {
    return <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Go to Scoreboard" color="primary" variant="outlined" width="50%" onClick={handleViewScoreboard} />
  }

  const showReviewPortfolio = () => {
    return <Chip className={classnames({"flashing": false}, classes.chipButton)} label="Review your Portfolio" color="primary" variant="outlined" width="50%" onClick={handleReviewPortfolio} />
  }

  const showEventCard = () => {
    return playerHasEvent ? showManageEventCard(): showDrawEventCard() 
  }

  const showActionButton = () => {
    return (
      <div style={{paddingRight: 15}}>
        {/* { !isAdminUser && showRoundStatus() } */}
        { !isAdminUser && shouldShowCountdown() && showCountDown() }
      </div>
    );
  }

  // sort players by aggreate score
  const rankedSessions = _.orderBy(game.sessions, (session) => {
    return getAggregateScore(session);
    }, ['desc']
  )

  const getAvatar = (session) => {
    if (player) {
      const sessionPlayer = _.find(game.players, {id: session.player_id})
      const isMe = player.id === _.get(session, "player_id")
      const name = isMe ? "Me" : _.capitalize(sessionPlayer.first_name + " " + sessionPlayer.last_name) 
      const stats = name + " ["  + getAggregateScore(session).toFixed(2) +  "]"
      return (
        <Tooltip title={`${stats}`}>
          <Avatar className={classnames({"flashing": isPlayerNotReady(session)})} style={{left: -50, marginRight: 5, color: "white", backgroundColor: session.status === PLAYER_STATUS.LEFT ? "lightgrey" : session.avatar}}>{isMe ? undefined : _.toUpper(sessionPlayer.first_name)[0]}</Avatar>
        </Tooltip>
      )
    }
  }

  return (
    <React.Fragment>
      { showSideMenu()}
      <div className={classes.contentWrapper}>
        <div className={classes.itemCenterWrapper}>
          <div className={classes.titleBar}>
          {
            rankedSessions.map((session) => (
              getAvatar(session)
            ))}
            <Typography className={classes.titleBarText}>
              {/* {`${showMainViewTitle()} [${gameState} ${_.get(session, "status")}]`} */}
              {`${showMainViewTitle()}`}
            </Typography>
            { showActionButton() }
          </div>
          { validGameSessionPlayer() && showMainView(classes) }
          { validGameSessionPlayer() && showWaitingForPlayersToJoin() }
          { validGameSessionPlayer() && showMarket() }
          { validGameSessionPlayer() && showConfirmInvestments() }
          { validGameSessionPlayer() && showCountdownComplete() }
          { validGameSessionPlayer() && showWaitingForPlayersToCompleteTurn() }
          { validGameSessionPlayer() && showEventCard() }
          { validGameSessionPlayer() && showGlobalEventCard() }
          { validGameSessionPlayer() && showViewPosition() }
          { validGameSessionPlayer() && showViewBadge() }
          {/* { validGameSessionPlayer() && showScoreboard() } */}
          <div className={classes.itemWrapperGradient} />
        </div>
        <div className={classes.itemWrapper}>
          { !isAdminUser && <Position classes={classes} /> }
        </div>
      </div>
      { showConfirmDialog() }
      <Footer lang={lang}/>
    </React.Fragment>
  );
};

export default Game;
