import React from "react";
import PropTypes from "prop-types";
import { subscribe } from "../channels/messaging";
import { getRandomInt } from "../global/getRandomInt";
import ChatBox from "./ChatBox";
import Messages from "./Messages";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      currentPlayer: 0,
      gameId: 1,
      messages: [],
      send: () => {},
    };

    this.handleReceive = this.handleReceive.bind(this);
  }

  handleReceive(data) {
    // Update component state when we receive data from Action Cable
    this.setState((prevState) => {
      const newMessages = prevState.messages.concat({ data });

      return {
        messages: newMessages,
      };
    });
  }

  componentDidMount() {
    // Set player information
    this.setState({ currentPlayer: getRandomInt(1, 9) });

    // TODO Here we can GET the current game state from server and set the React state

    // Set up Action Cable subscription
    const channel = subscribe(this, this.handleReceive);

    // Expose Action Cable send method as "this.state.send" so we can use it elsewhere
    // this.setState({
    //   send: (data) => {
    //     channel.send(data);
    //   },
    // });
  }

  render() {
    return (
      <React.Fragment>
        <div className="messaging-demo">
          <ChatBox
            currentPlayer={this.state.currentPlayer}
            sendMessage={this.state.send}
          />
          <Messages messages={this.state.messages} />
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {};

export default App;
