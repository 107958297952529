

import React from 'react';
import {Link, Typography} from '@material-ui/core';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faFacebook,
    faTwitter,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";
  
import { makeStyles } from '@material-ui/core/styles';

const subLogo = require('../../assets/images/BIISublogo.png');
import { getResourceString } from "./helpers/Strings"

function Copyright(lang, classes) {
    return (
        <Typography style={{fontSize: 12}} color="white" align="left" display="inline">
            {getResourceString(lang, "COPYRIGHT") + ` © ${new Date().getFullYear()}`}
            <Link className={classes.link} href="https://bostonimpact.org/"  style={{paddingLeft: 5}} target="_blank">
            Boston Impact Initiative
            </Link>{' '}
        </Typography>
    );
}

function contact(lang, classes) {
    return (
        <Typography variant="body2" color="white" align="right" display="inline" style={{paddingLeft: 30}}>
        <Link className={classes.link} href="https://bostonimpact.org/contact" target="_blank">
            {getResourceString(lang, "CONTACT_LINK")}
        </Link>{' '}
        </Typography>
    );
}
  
const useStyles = makeStyles((theme) => ({
    footer: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: 40,
        margin: "auto",
        color: "#005365",
        backgroundColor: "#C4C4C4",
        zIndex: 9999
    },
    footerContent: {
        margin: 12,
        padding: 0
    },
    social: {
        padding: 4,
        marginLeft: 20,
        borderRadius: 20,
        color:"#009eba",
        backgroundColor: "white",
        transition: "transform 250ms"
    },
    sublogo: {
        height: 40,
        marginLeft: "auto",
        marginRight: 20
    },
    link: {
        color: "#005365",
        '&:hover': {
            color: "#b5b807",
        },
    }
}));

const Footer = (props) => {
    const classes = useStyles();
    const lang = props.lang;

    return (
        <div className={`flex ${classes.footer}`}>
            <div className={classes.footerContent}>
                {Copyright(lang, classes)}
                <a href="https://twitter.com/bosimpact/"target="_blank"  className={classes.social}>
                    <FontAwesomeIcon icon={faTwitter} style={{width: "1.2rem"}}/>
                </a>
                <a href="https://www.facebook.com/bostonimpactinitiative/" target="_blank" className={classes.social}>
                    <FontAwesomeIcon icon={faFacebook} style={{width: "1.2rem"}}/>
                </a>
                <a href="https://www.linkedin.com/company/boston-impact-initiative/" target="_blank" className={classes.social}>
                    <FontAwesomeIcon icon={faLinkedin} style={{width: "1.2rem"}}/>
                </a>
                <a href="https://www.instagram.com/bosimpact/" target="_blank" className={classes.social}>
                    <FontAwesomeIcon icon={faInstagram} style={{width: "1.2rem"}}/>
                </a>
                {contact(lang, classes)}
            </div>
            <img src={subLogo} className={classes.sublogo}/>
        </div>
    );
}

export default Footer;