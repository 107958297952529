import React from "react";
import PropTypes from "prop-types";
import PlayerCard from "./PlayerCard";
import {
  Button,
  Grid,
  Paper,
  Card,
  GridList,
  GridListTile,
} from "@material-ui/core";

//Current player up and players[] likely need to be state
class PlayerQueue extends React.Component {
  render() {
    return (
      <React.Fragment>
        <GridList cols={4}>
          {this.props.Players &&
            this.props.Players.map((player) => (
              <GridListTile key={player.id}>
              <PlayerCard player={player} ActivePlayer={false} />
              </GridListTile>
            ))}
        </GridList>
      </React.Fragment>
    );
  }
}

PlayerQueue.propTypes = {
  Players: PropTypes.arrayOf(PropTypes.object),
  CurrentPlayerUp: PropTypes.number,
};

export default PlayerQueue;
