import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Paper,
  Card,
  GridList,
  GridListTile,
  Tooltip,
  Divider,
} from "@material-ui/core";
import {
  CashIcon,
  HoldingsValueIcon,
  ImpactIcon,
  PlaceHolderPieChart,
  RiskIcon,
} from "./GameIcons";

import { PLAYER_STATUS } from "./Constants";

//Active Player at least needs to be state --- this could be a functional component
//Grid Tile may need to be lifted to queue to ensure portability for the player component
// NOT USED YET
class PlayerCard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Paper elevation={this.props.player.status === PLAYER_STATUS.ACTIVE ? 10 : 1}>
          <h6> {this.props.player.name}'s Fund Position </h6>
          <Divider />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={8}>
                  <p style={{ margin: "1px", padding: "1px" }}>
                    <CashIcon />
                    {this.props.player.session_data.cash}
                  </p>
                </Grid>
                <Grid item xs={4} justify="center" alignItems="center">
                  <PlaceHolderPieChart />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} style={{padding: '0px 0px 0px 4px' }}>
              <HoldingsValueIcon/>
            </Grid>
            <Grid item xs={11}style={{padding: 0 }}>
            <Divider orientation="horizontal" />

            </Grid>
            <Grid item xs={4}>
              <p style={{ margin: "1px", padding: "1px" }}>
                #:{" "}
                {(this.props.player.portfolios &&
                  this.props.player.portfolios.length) ||
                  0}
              </p>
            </Grid>
            <Grid item xs={8}>
              <p style={{ margin: "1px", padding: "1px" }}>Income: xxx,xxx</p>
            </Grid>
            <Grid item xs={12}> Portfolio Value: $XX,XXX,XXX </Grid>
            <Grid item xs={6}>
              <p style={{ margin: "1px", padding: "1px" }}>
                <ImpactIcon />X
              </p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ margin: "1px", padding: "1px" }}>
               
                <RiskIcon />X
              </p>
            </Grid>
          </Grid>
        </Paper>
      </React.Fragment>
    );
  }
}

PlayerCard.propTypes = {
  player: PropTypes.object,
  ActivePlayer: PropTypes.bool,
};

export default PlayerCard;
