import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import rootReducer from "../slices";
import GameContainer from "./GameContainer";

const store = configureStore({
  reducer: rootReducer,
});

const GameRoot = (props) => {
  return (
    <Provider store={store}>
      <GameContainer lang="EN" />
    </Provider>
  );
};

export default GameRoot;
