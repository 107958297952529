import React from "react";
import { useSelector } from "react-redux";

const Messages = () => {
  const messages = useSelector((state) => state.game.messages);

  return (
    <div id="messages">
      {messages.map((msg) => (
        <div>
          {msg.sent_by}: {msg.body}
        </div>
      ))}
    </div>
  );
};

export default Messages;
