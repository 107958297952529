import { createMuiTheme }  from '@material-ui/core/styles'
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Montserrat'
    },
    palette: {
        dark: {
            primary: {
                main: 'rgba(255,255,255,0.70)',
                button: '#0093B2',
            },
            secondary: {
                button: '#C8F5FF'
            }
        },
        light: {
            primary: { main: '#A1D3DD' },
        }
    },
})
export default theme