import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Button, Slider, TextField } from "@material-ui/core";
import _ from "lodash";

import { verifyPortfolio, convertPortfolio, exitPortfolio } from "../slices/game";
import { getResourceString } from "./helpers/Strings"
import { PORTFOLIO_STATUS } from "./Constants"
import { getInvestmentType, investmentTypes } from "./EntityTypes";

const VerifyBusinessFormatter = (props) => {
    const [round, setRound] = useState(0)
    const [business, setBusiness] = useState();
    const [session, setSession] = useState();
    const [portfolio, setPortfolio] = useState()
    const [monthsPerRound, setMonthsPerRound] = useState(1)
    const [multiplier, setMultiplier] = useState(1)
    const [openDialog, setOpenDialog] = useState(false);
    const [log, setLog] = useState("")

    const startingCash = 10000000;
    const handleClose = () => {
        setOpenDialog(false);
        setLog("")
    }; 
    
    const handleOpen = () => {
        const business = props.row;
        setPortfolio({
            business_id: business.id,
            investment_type: business.investment_type,
            month_funded: 0,
            months_held: 0,
            investment_value: business.investment_size,
            investment_values: [{date: new Date(), investment_value: -business.investment_size}],
            total_collected: 0,
            earnings: 0,
            status: PORTFOLIO_STATUS.ACTIVE
        });
        setSession({
            cash: startingCash - business.investment_size,
            portfolios_value: business.investment_size,
            finance_score: 0,
            impact_score: 0,
            velocity_score: 0
        })
        setRound(0);
        setBusiness(business);
        setOpenDialog(true);
    };

    // simulate advancing one month
    const handleAdvanceRound = async () => {
        const data = await verifyPortfolio(session, portfolio, round, monthsPerRound, startingCash);
        setRound(round + 1);
        setSession(data.session);
        setPortfolio(_.extend(data.portfolio, { business: {id: data.portfolio.business_id, calculations: data.calculations }}));
        setLog(log + data.log[0].log + "\n");
    };

    //  handle converting a convertible debt
    const handleConvert = async () => {
        const data = await convertPortfolio(session, portfolio, round, monthsPerRound, startingCash);
        setSession(data.session);
        setPortfolio(_.extend(data.portfolio, { business: {id: data.portfolio.business_id, calculations: data.calculations }}));
        setLog(log + data.log + "\n");
    }

    // handle exiting an equity
    const handleExit = async () => {
        const data = await exitPortfolio(session, portfolio, round, monthsPerRound, startingCash, multiplier);
        setSession(data.session);
        setPortfolio(_.extend(data.portfolio, { business: {id: data.portfolio.business_id, calculations: data.calculations }}));
        setLog(log + data.log + "\n");
    }

    const showDialog = () => {
        const renderValue = (name, value, important=false) => {
            return (
                important ? 
                    <span style={{marginRight: 10}}>{getResourceString(props.lang, name.toUpperCase()) || name}: <b>{value}</b></span>
                    :
                    <span style={{marginRight: 10}}>{getResourceString(props.lang, name.toUpperCase()) || name}: {value}</span>
            );
        };
        
        const renderOutput = (id, name, value, readOnly=false, required=false, rows=20) => {
            return (
                <TextField
                    InputProps={{readOnly: readOnly, disableUnderline: readOnly}}
                    variant="outlined"
                    margin="normal"
                    required={required}
                    fullWidth
                    id={id}
                    label={name}
                    name={name}
                    value={value}
                    multiline
                    rows={rows}
                    maxRows={rows}
                />
            );
        };

        const renderCalculation = (row, key) => {
            return renderValue(key, row.calculations[key])
        };
    
        const renderMonthsPerRound = () => {
            return (
                <div style={{display: "flex", width: 320, marginRight: 10}}>
                    <span style={{width: 280}}>Months Per Round: </span><span style={{fontWeight: 600, marginRight: 20}}>{monthsPerRound}</span>
                    <Slider width={200} color="secondary" defaultValue={monthsPerRound} min={1} max={12} onChange={(e, v) => setMonthsPerRound(v)} aria-label="Default" valueLabelDisplay="auto" />
                </div>
            )
        };

        const renderMultiplier = () => {
            return (
                <div style={{display: "flex", width: 280, marginRight: 10}}>
                    <span style={{width: 120}}>Multiplier: </span><span style={{fontWeight: 600, marginRight: 20}}>{multiplier}</span>
                    <Slider width={200} color="primary" defaultValue={multiplier} min={1} max={6} step={0.5} onChange={(e, v) => setMultiplier(v)} aria-label="Default" valueLabelDisplay="auto" />
                </div>
            )
        };

        const investmentType = _.get(portfolio, "investment_type");
        const isConvertibleDebt = investmentType === investmentTypes.INVESTMENT_TYPE_CONVERTIBLE_DEBT;
        const isEquity = investmentType === investmentTypes.INVESTMENT_TYPE_EQUITY;
        const isPortfolioActive = portfolio && portfolio.status !== PORTFOLIO_STATUS.COMPLETE;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
            >
            <DialogTitle id="customized-dialog-title">
            Verify Business
            </DialogTitle>  
            <DialogContent dividers>
                <form noValidate>
                    <div style={{display: "flex"}}>
                        { portfolio && renderValue("Investment Type", getInvestmentType(investmentType), true) }
                        { portfolio && renderValue("Months Held", portfolio.months_held, true) }
                        { portfolio && renderValue("Status", portfolio.status, true) }
                        { portfolio && renderMonthsPerRound() }
                    </div>
                    <div style={{display: "flex"}}>
                        { _.map(_.keys(_.get(business, "calculations")), key => renderCalculation(business, key)) }
                        { portfolio && renderValue("Investment Size", "$" + business.investment_size) }
                        { isEquity && renderMultiplier() }
                    </div>
                    <div style={{display: "flex"}}>
                        { portfolio && renderValue("Cash", "$" + session.cash) }
                        { portfolio && renderValue("Portfolio Value", "$" + session.portfolios_value) }
                        { portfolio && renderValue("Total Collected", "$" + portfolio.total_collected) }
                        { portfolio && renderValue("Investment Value", "$" + portfolio.investment_value) }
                        { portfolio && renderValue("Earnings", "$" + portfolio.earnings) }
                    </div>
                    <div style={{display: "flex"}}>
                        { session && renderValue("Finance Score", session.finance_score) }
                        { session && renderValue("Impact Score", session.impact_score) }
                        { session && renderValue("Velocity Score", session.velocity_score) }
                        { session && renderValue("Aggregate Score", session.finance_score + session.impact_score + session.velocity_score) }
                    </div>
                    { renderOutput("log", "", log, true) }
                </form>
            </DialogContent>
            <div style={{display: "flex", justifyContent: "right"}}>
                <Button style={{display: isConvertibleDebt && isPortfolioActive && portfolio.business ? "block" : "none", color: "white", backgroundColor: "#007bff", margin: 5}}  onClick={handleConvert}>
                    Convert
                </Button>
                <Button style={{display: isEquity && isPortfolioActive ? "block" : "none", color: "white", backgroundColor: "#007bff", margin: 5}}  onClick={handleExit}>
                    Exit
                </Button>
                <Button style={{display: isPortfolioActive ? "block" : "none", color: "white", backgroundColor: "#007bff", margin: 5}}  onClick={handleAdvanceRound}>
                    Advance Round
                </Button>
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}}  onClick={handleClose}>
                Close
                </Button>
            </div>
            </Dialog>
        );
    }

    return <div>
            <span className = "btn-group  btn-group-sm">
                <button type="button" className="btn btn-primary" onClick={handleOpen}>Verify</button>
            </span>
            {
                showDialog()
            }
        </div>
}

export default VerifyBusinessFormatter;