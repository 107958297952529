import { Button, Paper } from "@material-ui/core";
import React from "react";
import {
  gameSelector,
  playerSelector,
  businessesSelector,
  developmentAction,
} from "../slices/game";
import { useDispatch } from "react-redux";

import PortfolioCard from "./PortfolioCard";

//TODO ---> Refactor Portfolio business to be as dumb as this so that it only need take a portfolio and a business
// infact, the entire chain down to portfolio business needs to be redone in redux mode
const DevelopmentBusiness = (props) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Paper elevation={12}>
        <PortfolioCard
          portfolioItem={props.portfolioItem}
          business={props.business}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={() =>
            dispatch(
              developmentAction(
                props.development.factor,
                props.portfolioItem.id,
                props.development.id
              )
            )
          }
        >
          Action
        </Button>
      </Paper>
    </React.Fragment>
  );
};

export default DevelopmentBusiness;
