import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import { Chip, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Radio, RadioGroup, Tooltip, Typography } from "@material-ui/core";

import { gameSelector, playerSelector, sessionSelector, completeTurn, updatePlayerStatus } from "../slices/game";
import { applyDevelopment } from "../slices/game";
import { getInvestmentType, getIndustry } from "./EntityTypes";
import _ from "lodash";
import * as utils from "./helpers/Utils"
import { ConfirmDialog } from "./helpers/ConfirmDialog"
import { getResourceString } from "./helpers/Strings"

import BIITheme from "../theme";
import PortfolioCard from "./PortfolioCard";
import { GAME_PHASE, PLAYER_STATUS, PORTFOLIO_STATUS } from "./Constants";

const Portfolio = ({lang, classes, setMarketViewMode}) => {
  const dispatch = useDispatch();

  const game = useSelector(gameSelector);
  const player = useSelector(playerSelector);
  const session = useSelector(sessionSelector);
  const development = _.get(session, "development");

  const [ showCompletePortfolios, setShowCompletePortfolios ] = useState(false);
  const [ relevantOnly, setRelevantOnly ] = useState(false);
  const [ portfolios, setPortfolios ] = useState([]);
  const [ selectedPortfolios, setSelectedPortfolios ] = useState([]);
  const [ optionValue, setOptionValue ] = useState()

  // confirm dialog
  const [ cannotApplyEventDialogOpen, setCannotApplyEventDialogOpen ] = useState(false);
  const [ noEventCanBeAppliedDialogOpen, setNoEventCanBeAppliedDialogOpen ] = useState(false);
  
  const isGlobalDevelopment = (development) => {
    return _.get(development, "effect_subject") === "global";
  }

  const applyEventToSelectedPortfolios = (option) => {
    if (!_.isEmpty(selectedPortfolios)) {
      dispatch(applyDevelopment(_.map(selectedPortfolios, portfolio => portfolio.id), option));
      setSelectedPortfolios([]);
    } else {
      dispatch(completeTurn());
    }
  }

  const applyEvent = () => {
    const option = optionValue;
    const isGlobal = isGlobalDevelopment(development);
    if (isGlobal) {
      if (development.required > 0) {
        applyEventToSelectedPortfolios(option)
      } else {
        dispatch(applyDevelopment(_.map(portfolios, portfolio => portfolio.id)), option);
      }
    } else {
      applyEventToSelectedPortfolios(option)
    }

    // reset relevantOnly
    setRelevantOnly(false);

    // reset optionValue
    setOptionValue()
  }

  const isNoopEventOptionSelected = (development) => {
    if (optionValue) {
      const option = parseInt(optionValue)
      const factor = development.factor.split("?")[option]
      if (factor === "noop") {
        return true;
      }  
    }
    return false;
  }

  const activePortfolios = _.filter(portfolios, {status: PORTFOLIO_STATUS.ACTIVE});
  const noEventCanBeApplied = utils.isNoEventCanBeApplied(activePortfolios, development);
  const eventAppliedInRound = !!_.find(_.get(session, "developments"), {round: game.round, id: _.get(development, 'id')});
  const noopEventOptionSelected = isNoopEventOptionSelected(development);
  const hasRequiredSelected = utils.hasRequiredSelectedForEvent(development, selectedPortfolios, activePortfolios);
  const canApplyEvent = noopEventOptionSelected || (hasRequiredSelected && !eventAppliedInRound);
  const eventHasOptions = _.split(_.get(development, "name"), "|").length > 1
  const optionForEventNotSelected = eventHasOptions && (optionValue === undefined)

  useEffect(() => {
    if (player) {
      let playerPortolios = utils.getPlayerPortfolios(game, player);
      if ((game.phase === GAME_PHASE.EVENT || game.phase === GAME_PHASE.GLOBAL) && relevantOnly) {
        // determine relevant portfolios from event
        // filter investment_type, and industry
        if (development) {
          playerPortolios = utils.getPortfoliosForEvent(playerPortolios, development);
        }
      }
      let p =_.sortBy(playerPortolios, ["business.name"]);
      setPortfolios(p);
    }
  }, [game, player, showCompletePortfolios, relevantOnly]);

  const toggleShowCompletePortfolios = () => {
    setShowCompletePortfolios(!showCompletePortfolios);
  };

  const toggleRelevantOnly = () => {
    setRelevantOnly(!relevantOnly);
    if (!relevantOnly && noEventCanBeApplied) {
      setNoEventCanBeAppliedDialogOpen(true);
    }
  };

  const isSelected = (portfolioItem) => {
    return !_.isUndefined(_.find(selectedPortfolios, {id: portfolioItem.id}));
  };

  const playerHasEvent = !!_.get(session, "development");

  const showCannotApplyEventDialog = () => {
    return (
      <ConfirmDialog
        message="The selected company does not meet the event conditions"
        open={cannotApplyEventDialogOpen}
        setOpen={setCannotApplyEventDialogOpen}
        yesNo={false}
      />
    )
  }
  
  const showNoEventCanBeAppliedDialog = () => {
    return (
      <ConfirmDialog
        message="This event does not apply to any of your portfolio companies. You must pass."
        open={noEventCanBeAppliedDialogOpen}
        setOpen={setNoEventCanBeAppliedDialogOpen}
        onConfirm={applyEvent}
        label={getResourceString(lang, "TURN_COMPLETE")}
        yesNo={false}
      />
    )
  }

  const handleSelect = (portfolioItem) => {
    if (!playerHasEvent || eventAppliedInRound) {
      return false;
    }

    // check if event can be applied to portfolio
    const selected = selectedPortfolios.concat(portfolioItem)
    if (!isSelected(portfolioItem) && !utils.canApplyEventToPortfolios(development, selected, portfolios)) {
      setCannotApplyEventDialogOpen(true);
    } else {
      // determne if portfolioItem can be selected as some events can be applied  "up tp N portfolio items"
      if (!utils.canSelectPortfolioItem(development, selectedPortfolios)) {
        if (isSelected(portfolioItem)) {
          const s = _.filter(selectedPortfolios, p => p.id !== portfolioItem.id);
          setSelectedPortfolios(s);
        }
      } else {
        const s = _.clone(selectedPortfolios)
        s.push(portfolioItem)
        setSelectedPortfolios(s);    
      }
    }  
  };

  const handleOptionChange = (e) => {
    const option = parseInt(e.target.value);
    setOptionValue(option)
  };

  const renderWithOptions = (development) => {
    const options = _.split(_.get(development, "name"), "|")
    const value = options.shift();
    return (
        <div style={{fontWeight: 600, color: "white"}}>
            <span>{value}</span>
            <FormControl style={{paddingTop: 10}}>
                <RadioGroup
                    aria-labelledby="development-options-group"
                    name="development-options"
                    value={optionValue}
                    onChange={handleOptionChange}
                >
                    {
                        _.map(options, (option, index) => {
                            return <FormControlLabel value={index.toString()} control={<Radio size="small" style={{height: 8}}  />} label={<Typography style={{fontSize: 12}}>{option}</Typography>} />
                        })
                    }
                </RadioGroup>
            </FormControl>
        </div>
    );
  };

  const showCompletePortfolioCheckbox = () => {
    return (
      <div style={{ontWeight: 600, paddingTop: 4, paddingBottom: 0}}>
        <FormControl>
          <FormControlLabel
            control={<Checkbox style={{padding: 0, color: "#005365"}} checked={showCompletePortfolios} onChange={() => toggleShowCompletePortfolios()} />}
            label={<Typography style={{padding: 0, margin: 0, textAlign: "center", fontSize: 14, color: "#005365"}}>Include Banked or Written Off Companies</Typography>}
          />
        </FormControl>
      </div>
    )
  }

  const showPortfolio = () => {
    return (
      <div className={`${classes.itemContainer} custom-scrollbar`}>
      {
        portfolios &&
          portfolios.map((portfolioItem) => {
            if ((portfolioItem.status === PORTFOLIO_STATUS.COMPLETE || portfolioItem.status === PORTFOLIO_STATUS.WRITTEN_OFF) ? showCompletePortfolios : true) {
              return (
                <PortfolioCard
                  key={portfolioItem.business_id}
                  portfolioItem={portfolioItem}
                  business={portfolioItem.business}
                  classes={classes}
                  isSelected={isSelected}
                  handleSelect={handleSelect}
                />
              )
            }
          })
      }
      </div>
    )
  }

  const showApplyEventButton = () => {
    const text = getResourceString(lang, "APPLY_EVENT")
    const disabled = optionForEventNotSelected || !canApplyEvent || noEventCanBeApplied
    return <Chip className={classnames({"flashing": !disabled})} style={{ width: 200, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }} label={text} color="primary" variant="contained" disabled={disabled} onClick={applyEvent} />
  }

  const showEventDetails = () => {
    return (
      <div style={{display: "flex", backgroundColor: isGlobalDevelopment(development) ? "#ad0414" : "#005365", padding: 10}}>
        <div style={{width: "40%", overflow: "auto"}}>
          { renderWithOptions(development) }
        </div>
        <div style={{display: "flex", flexDirection: "column", flexGrow: 2, alignItems: "center"}}>
          <div style={{textAlign: "center", fontWeight: 600, paddingBottom: 0}}>
            <span style={{fontWeight: 600, color: "white"}}>Sector:</span><span style={{fontWeight: 300, paddingLeft: 10, color: "white"}}>{getIndustry(_.get(development, "industry"), "All")}</span>
            <span style={{fontWeight: 600, color: "white", paddingLeft: 10}}>Stage:</span><span style={{fontWeight: 300, paddingLeft: 10, color: "white"}}>{development.stage}</span>
          </div>
          <div style={{textAlign: "center", paddingBottom: 0}}>
            <span style={{fontWeight: 600, color: "white"}}>Investment Types:</span><span style={{fontWeight: 300, paddingLeft: 10, color: "white"}}>{getInvestmentType(_.get(development, "investment_type"), "All")}</span>
          </div>
          <div style={{ontWeight: 300, textAlign: "center", paddingBottom: 0}}>
            <FormControl>
              <FormControlLabel
                control={<Checkbox style={{padding: 0, color: "white"}} checked={relevantOnly} onChange={() => toggleRelevantOnly()} />}
                label={<Typography style={{padding: 0, margin: 0, textAlign: "center", fontSize: 14, color: "white"}}>View Relevant Companies</Typography>}
              />
            </FormControl>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", marginLeft: "auto", justifyContent: "center",  alignItems: "center"}}>
          { showApplyEventButton() }
        </div>
      </div>
    )
  }

  /** moved from Game.jsx */
  const isPlayerExecuting = () => {
    return _.get(session, "status") === PLAYER_STATUS.EXECUTING;
  }

  const handleCompleteTurn = () => {
    // playCashRegister();
    dispatch(completeTurn());
    setMarketViewMode();
  }

  const shouldShowCompleteTurn = () => {
    return game.phase === GAME_PHASE.MANAGEMENT && isPlayerExecuting()
  }

  const showCompleteTurn = () => {
    return <Chip className={classnames({"flashing": true})} style={{ width: 200, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }} label={getResourceString(lang, "VISIT_MARKETPLACE")} color="primary" variant="contained" onClick={handleCompleteTurn} />
  }
  /** end moved from Game.jsx */

  const shouldShowEventDetails = () => { return !!development }
  return (
    <React.Fragment>
      { shouldShowEventDetails() && showEventDetails() }
      <div style={{display: "flex", paddingTop: 8, paddingLeft: 16}}>
        { showCompletePortfolioCheckbox() }
        { shouldShowCompleteTurn() && showCompleteTurn()}
      </div>
      { showPortfolio() }
      { showCannotApplyEventDialog() }
      { showNoEventCanBeAppliedDialog() }
    </React.Fragment>
  );
}
  
export default Portfolio;
