import React from "react";
import { useSelector } from "react-redux";
import { gameSelector } from "../../javascript/slices/game";
import { getAggregateScore } from "./helpers/Utils"
import _ from "lodash";

import ScoreboardCard from "./ScoreboardCard";

const RANK_BY_FIELD = "score"

const Scoreboard = ({classes}) => {
  const game = useSelector(gameSelector);

  let rankedSessions;

  if (RANK_BY_FIELD == "score") {
    // sort players by aggreate score
    rankedSessions = _.orderBy(game.sessions, (session) => {
      return getAggregateScore(session);
    }, ['desc']
    )
  } else {
    // sort players by cash + portfolios_value
    rankedSessions = _.sortBy(game.sessions, (session) => (
      session.cash + session.portfolios_value).reverse()
    )
  }

  return (
    <React.Fragment>
      <div className={`${classes.itemContainer} custom-scrollbar`}>
        {
          rankedSessions.map((session, index) => (
              <ScoreboardCard
                key={session.id}
                placement={index+1}
                game={game}
                session={session}
                player={_.find(game.players, {id: session.player_id})}
                classes={classes}
              />
          ))}
      </div>
    </React.Fragment>
  );
}

export default Scoreboard;
