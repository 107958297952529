import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from "@material-ui/core/styles";
import { Avatar, CssBaseline, Typography  } from '@material-ui/core';
import { joinGame, startGame, leaveGame, setOrganization } from "../slices/game";
import { FUND_MANAGER_GAME_ID_KEY, FUND_MANAGER_PLAYER_ID_KEY, FUND_MANAGER_COUNTDOWN_TIMESTAMP } from "./Constants";

import axios from "axios";
import _ from "lodash";
import * as utils from "./helpers/Utils"

import GameAccessForm from "./GameAccessForm";
import { Game } from "./Game";
import { gameSelector, playerSelector, sessionSelector, playSoundSelector, setPlaySound } from "../slices/game";
import { getResourceString } from "./helpers/Strings"
import BIITheme from "../theme";

const devMode = false

const drawerWidth = 228;

const styles = theme => ({
  startButton: {
    color: "white",
    backgroundColor: "#63544a",
    fontWeight: 600,
    '&:hover': {
      color: '#63544a',
    }
  },
  gameContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#C4C4C4"
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    height: 68,
    backgroundColor: "rgba(255, 255, 255, 0.7)"
  },
  topBarText: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 20,
    marginRight: 20,
    padding: 4,
    color: "#005365",
    alignSelf: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  topBarStatus: {
    display: "flex",
    alignSelf: "center",
    height: 24
  },
  topBarStatusText: {
    fontSize: 14,
    fontweight: 400,
    color: "#017892",
    paddingLeft: 20,
    paddingRight: 20,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  roundStatusText: {
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: 20,
    paddingRight: 20,
    color: "#005365",
    backgroundColor: "lightgrey",
    // color: "white",
    // backgroundColor: "#0093B2",
    border: "1px solid rgba(0, 147, 178, 1)",
    borderRadius: "10px 10px 10px 10px",
    alignSelf: "center",
    height: 24,
    minWidth: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  turnCompleteButton: {
    fontSize: 14,
    fontWeight: 600,
    paddingLeft: 20,
    paddingRight: 20,
    color: "white",
    backgroundColor: "#0093B2",
    border: "1px solid rgba(0, 147, 178, 1)",
    borderRadius: "12px 12px 12px 12px",
    alignSelf: "center",
    height: 24,
    minWidth: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer"
  },
  titleBar: {
    display: "flex",
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#C4C4C4"
  },
  titleBarText: {
    margin: "auto",
    color: "#005365",
    fontSize: 26,
    fontWeight: 700
  },
  contentWrapper: {
    display: "flex",
    paddingLeft: 60,
    backgroundColor: "#C4C4C4"
  },
  itemCenterWrapper: {
    flexGrow: 1,
    backgroundColor: "white",
    zIndex: 0
  },
  itemWrapper: {
    backgroundColor: "white",
    zIndex: 0
  },
  itemWrapperGradient: {
    position: "absolute",
    height: "35%",
    bottom: 0,
    width: "100%",
    backgroundImage: "linear-gradient(to bottom, #FFFFFF, #C4C4C4)",
    zIndex: -9
  },
  itemContainer: {
    height: "calc(100vh - 108px - 50px)",
    display: "flex",
    flexWrap: "wrap",
    overflowY: "scroll",
    alignContent: "baseline",
  },
  frameContainer: {
    flexGrow: 0,
    flexShrink: 0,
    margin: "5px 5px",
    borderRadius: 12,
  },
  contentFull: {
    marginRight: 0
  },
  contentRight: {
    marginRight: drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  content: {
    textAlign: "center",
    fontSize: 22,
    paddingTop: 20,
    paddingBottom: 0,
    padding: theme.spacing(3)
  },
  iconLabel: {
    marginLeft: 4,
    color: "blue",
    fontSize: "0.6rem",
    fontWeight: 600
  },
  divider: {
    borderTop: "1px solid rgba(0, 147, 178, 0.5)",
    margin: "4px 0px 4px 0px",
  },
  dashedDivider: {
    borderTop: "1px dashed rgba(0, 147, 178, 0.5)",
    margin: "4px 8px 4px 0px",
  },
  select: {
    "&:before": {
      borderColor: "#0093B2"
    },
    "&:after": {
      borderColor: "#0093B2"
    }
  },
  icon: {
    fill: "#0093B2"
  },
  disabled: {
    pointerEvents: "none", //This makes it not clickable
    opacity: 0.6         //This grays it out to look disabled
  },
  investButton: {
    width: 120,
    color: "white",
    borderColor: "white",
    backgroundColor: "#0093B2",
  },
  onTitleChipButton: {
    width: 200,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#0093B2"
    }
  },
  chipButton: {
    width: 250,
    position: "absolute",
    bottom: 40,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#0093B2"
    }
  },
  countdownGreen: {
    color: "black",
    backgroundColor: "#44D62C",
  },
  countdownYellow: {
    color: "black",
    backgroundColor: "yellow",
    borderColor: "black",
  },
  countdownRed: {
    color: "white",
    backgroundColor: "red",
    borderColor: "white",
    fontWeight: 600
  }
});

const GameContainer = ({classes, lang}) => {
  const dispatch = useDispatch();
  const game = useSelector(gameSelector);
  const player = useSelector(playerSelector);
  const session = useSelector(sessionSelector);
  const playSound = useSelector(playSoundSelector);

  const [ organizations, setOrganizations ] = useState([]);
  const [ allowedAccess, setAllowedAccess ] = useState(false);
  const [ isAdminUser, setIsAdminUser ] = useState(false);
  const [ userOrganization, setUserOrganization ] = useState();
  const [ gameId, setGameId ] = useState();
  const [ playerId, setPlayerId ] = useState();

  const allowNoInvestments= false; //TODD

  useEffect(async () => {
    await fetchOrganizations();
  }, []);

  useEffect(() => {
    const gameId = window.localStorage.getItem(FUND_MANAGER_GAME_ID_KEY);
    if (gameId) {
      setGameId(parseInt(gameId));
    } else {
      setGameId();
    }

    const playerId = window.localStorage.getItem(FUND_MANAGER_PLAYER_ID_KEY);
    if (playerId) {
      setPlayerId(parseInt(playerId));
    } else {
      setPlayerId();
    }

    // uncomment below if debugging Adminstration functionality
    if (devMode) {
      setIsAdminUser(true);
    }
  }, [game]);
  
  useEffect(() => {  
    if (gameId && playerId) {
      // Update initial game and player
      dispatch(joinGame(gameId, playerId, isAdminUser));
    }
  }, [gameId, playerId]);

  // The following code is to restore game upon refreshing of browser during a game
  useEffect(() => {
    setAllowedAccess(!!_.get(game, "id") || devMode);
  }, [game])

  const fetchOrganizations = async () => {
    setOrganizations(_.get(await axios.get("/api/organizations/fetch/all"), "data", []));
  };

  const calculateGameDate = () => {
    if (session) {
      const sessionDate = new Date(session.created_at);
      sessionDate.setDate(sessionDate.getDate() + game.round * game.months_per_round * 30);
      return sessionDate.toLocaleDateString();
    }
  };

  const calculateGameMonth = () => {
    const month = game.current_month + 1
    const totalMonths = game.number_of_years * 12
    return `${month} of ${totalMonths}`
  }

  const showGameDetails = () => {
    return game ? (
      <div className={classes.topBarStatus}>
        <Typography className={classes.topBarStatusText} style={{border: "1px solid #017892", borderRadius: "12px 0px 0px 12px"}}>
          {`${_.toUpper(getResourceString(lang, "GAME_NAME"))}:`}<span style={{marginLeft: 8, fontWeight: 600}}>{`${game.name}`}</span>
        </Typography>
        <Typography className={classes.topBarStatusText} style={{border: "1px solid #017892", borderLeft: "none"}}>
          {`${_.toUpper(getResourceString(lang, "GAME_SESSION"))}:`}<span style={{marginLeft: 8, fontWeight: 600}}>{`${_.get(session, "id", "")}`}</span>
        </Typography>
        <Typography className={classes.topBarStatusText} style={{border: "1px solid #017892", borderLeft: "none"}}>
          {`${_.toUpper(getResourceString(lang, "GAME_ROUND"))}:`}<span style={{marginLeft: 8, fontWeight: 600}}>{`${game.round + 1} of ${game.number_of_years * 12 / game.months_per_round}`}</span>
        </Typography>
        <Typography className={classes.topBarStatusText} style={{border: "1px solid #017892", borderLeft: "none", borderRight: "none"}}>
          {`${_.toUpper(getResourceString(lang, "GAME_MONTH"))}:`}<span style={{marginLeft: 8, fontWeight: 600}}>{calculateGameMonth()}</span>
        </Typography>
        <Typography className={classes.topBarStatusText} style={{border: "1px solid #017892", borderRadius: "0px 12px 12px 0px"}}>
          {`${_.toUpper(getResourceString(lang, "GAME_PHASE"))}:`}<span style={{marginLeft: 8, fontWeight: 600}}>{`${_.capitalize(game.phase)}`}</span>
        </Typography>
      </div>
    ) : null;
  };

  const truncateString = (str, n) => {
    if (str.length > n) {
      return str.substring(0, n) + "...";
    } else {
      return str;
    }
  }

  const showGameContainer = () => {
    const playerName = truncateString(_.toUpper(_.get(player, "first_name", "") + " " + _.get(player, "last_name", "")), 18)
    return (
      <div className={classes.gameContainer}>
        <div className={classes.topBar}>
          <Typography className={classes.topBarText} style={{fontSize: 22, fontWeight: 600}}>
            {getResourceString(lang, "FUND_MANAGER_TITLE")}
          </Typography>
          { !isAdminUser && showGameDetails() }
          <div style={{display: "flex"}}>
            {
              playSound ?
                <i class="fa fa-volume-up fa-2x" style={{cursor: "pointer", color: "#005365", alignSelf: "center", paddingTop: 8}} onClick={() => dispatch(setPlaySound(false))} />
              :
              <i class="fa fa-volume-off fa-2x" style={{cursor: "pointer", color: "#005365", alignSelf: "center", paddingTop: 8}} onClick={() => dispatch(setPlaySound(true))} />
            }
            <Avatar style={{alignSelf: "center", color: "white", backgroundColor: _.get(session, "avatar")}}></Avatar>
            <Typography className={classes.topBarText} style={{marginLeft: 10}}>
              {playerName}
            </Typography>
          </div>
        </div>
        <Game classes={classes} lang={lang} isAdminUser={isAdminUser} organization={userOrganization} startGameHandler={startGameHandler} leaveGameHandler={leaveGameHandler} devMode={devMode} />
      </div>
    )
  };

  const showAccessForm = () => {
    return <GameAccessForm
      handleSetAllowAccess={handleSetAllowAccess}
      handleAdminLogin={handleAdminLogin}
      handleJoinGame={handleJoinGame}
      organizations={organizations}
    />    
  };

  const handleSetAllowAccess = (isAdmin, organization) => {
    setAllowedAccess(true);
    setIsAdminUser(isAdmin);
    setUserOrganization(organization);
  };

  const handleAdminLogin = async (firstName, lastName, email, organization) => {
    const response = await axios.get(`/api/organizations/${organization.id}`);
    dispatch(setOrganization(response.data));
    return true;
  };

  const handleJoinGame = async (firstName, lastName, email, organization, game) => {
    const organizationId = _.get(organization, "id");
    const organizationName = _.get(organization, "name");
    const gameId = _.get(game, "id");

    const response = await axios.post("/api/games/join", {
      organization_id: organizationId,
      organization_name: organizationName,
      game_id: gameId,
      first_name: firstName,
      last_name: lastName,
      email: email,
    });
    const playerId = response.data;

    // write gameId and playerId to localStorage before setting gameId and playerId for subscribeGame() in userEffect()
    window.localStorage.setItem(FUND_MANAGER_GAME_ID_KEY, gameId);
    window.localStorage.setItem(FUND_MANAGER_PLAYER_ID_KEY, playerId);

    // set gameId and playerId
    setGameId(gameId);
    setPlayerId(playerId);

    return true;
  };

  const startGameHandler = async (firstName, lastName, email, organization) => {
    const gameId = window.localStorage.getItem(FUND_MANAGER_GAME_ID_KEY);
    const playerId = window.localStorage.getItem(FUND_MANAGER_PLAYER_ID_KEY);
    const response = await axios.post("/api/games/start", {
      game_id: gameId
    });
    dispatch(startGame(gameId, playerId));
  };

  const leaveGameHandler = async () => {
    if (!isAdminUser) {
      const gameId = window.localStorage.getItem(FUND_MANAGER_GAME_ID_KEY);
      const playerId = window.localStorage.getItem(FUND_MANAGER_PLAYER_ID_KEY);
      const response = await axios.post("/api/games/leave", {
        game_id: gameId,
        player_id: playerId
      });
    }
    // write gameId and playerId to localStorage
    window.localStorage.removeItem(FUND_MANAGER_GAME_ID_KEY);
    window.localStorage.removeItem(FUND_MANAGER_PLAYER_ID_KEY);
    window.localStorage.removeItem(FUND_MANAGER_COUNTDOWN_TIMESTAMP);
    dispatch(leaveGame(playerId, isAdminUser));
    setAllowedAccess(false);
    setIsAdminUser(false);
    await fetchOrganizations();
  }

  return (
    <ThemeProvider theme={BIITheme}>
      <CssBaseline />
      <React.Fragment>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900" rel="stylesheet"/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"></link>
        {
          allowedAccess ? showGameContainer() : (gameId ? null : showAccessForm())
        }
      </React.Fragment>
    </ThemeProvider>
  );
};

export default withStyles(styles, { withTheme: true })(GameContainer);