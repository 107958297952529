import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Button, FormControl, Select, MenuItem, TextField } from '@material-ui/core';
import ReactDataGrid from "react-data-grid";
import NumericInput from "react-numeric-input";
import _ from "lodash";

import { ConfirmDialog } from "./helpers/ConfirmDialog"

// react-data-grid configs
const defaultColumnProperties = {
  resizable: true
};

const MIN_HEIGHT = "calc(100vh - 108px - 120px)";

const DataGrid = ({dataset, columns, type, defaultSortColumn, handleRowSelect, handleRowUpdate, handleRowActions, dialogHeight="auto", minHeight=MIN_HEIGHT, autoSave=false, updateOnly=false, readOnly=false, resetable=false, maxSelectRows=0}) => {
  const [dataColumns, setDataColumns] = useState(columns);
  const [rows, setRows] = useState(dataset);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState("ASC");
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [addMode, setAddMode] = useState(false);
  const [newRow, setNewRow] = useState({});
  const [changedRows, setChangedRows] = useState([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState();
  const [confirmMessage, setConfirmMessage] = useState("Are you sure you want to continue?");

  useEffect(() => {
    setRows(dataset);
  }, [dataset, sortColumn, sortDirection]);

  const renderSelect = (id, name, options, required) => {
    return (
      <FormControl variant="outlined" fullWidth>
        <span>{required ? `${name} *`: name}</span>
        <Select
          style={{marginTop: 8, marginBottom: 8}}
          required={required}
          onChange={(e) => handleFieldChange(id, e)}
        >
          {
            _.map(options, (option) => {
              return <MenuItem label={name} key={option.id} value={option.value}>{option.text}</MenuItem>
            })
          }
        </Select>
      </FormControl>
    );
  };

  const renderTextField = (id, name, required=false) => {
    return (
      <TextField
        variant="outlined"
        margin="normal"
        required={required}
        fullWidth
        id={id}
        label={name}
        name={name}
        onBlur={(e) => handleFieldChange(id, e)}
      />
    );
  };

  const renderTextArea = (id, name, required=false) => {
    return (
      <TextField
        variant="outlined"
        margin="normal"
        multiline
        required={required}
        fullWidth
        id={id}
        label={name}
        name={name}
        onBlur={(e) => handleFieldChange(id, e)}
      />
    );
  };

  const renderNumericInput = (id, name, required=false) => {
    return (
        <div style={{marginTop: 20, marginBottom: 20}}>
            <span>{required ? `${name.toUpperCase()} *`: name}</span>
            <NumericInput
                className="form-control"
                required={required}
                fullWidth
                id={id}
                value={newRow[id]}
                onChange={(v) => handleFieldChange(id, {target: {value: v}})}
            />
        </div>
    );
  };

  // convert string in updated to integer based on the row
  const convertUpdatedValues = (fromRow, toRow, updated) => {
    const converted = {};
    const keys = _.keys(updated);
    _.map(keys, key => {
      if ((typeof rows[fromRow][key]) === "number") {
        converted[key] = parseInt(updated[key]);
      } else if ((typeof rows[fromRow][key]) === "boolean") {
        converted[key] = (updated[key] === "true");
      } else {
        converted[key] = updated[key];
      }
    });

    return converted;
  };

  const onGridSort = (sortColumn, sortDirection) => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
      } else if (sortDirection === 'DESC') {
        return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
      }
    };

    const sortedRows = sortDirection === 'NONE' ? rows.slice(0) : rows.sort(comparer);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
    setRows(sortedRows);
  };

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const converted = convertUpdatedValues(fromRow, toRow, updated);
    const _rows = _.clone(rows);

    const updatedRows = [];
    for (let i = fromRow; i <= toRow; i++) {
      _rows[i] = { ..._rows[i], ...converted };
      // call handleRowChanged if one is passed, primarily to handle investment type changes
      if (handleRowUpdate) {
        handleRowUpdate(_rows[i], updated);
      }
      _rows[i].action = "update";
      updatedRows.push(_rows[i]);
    }
    setRows(_rows)

    if (autoSave) {
      handleRowActions(updatedRows, type);
    } else {
      addToChangedRows(updatedRows);
    }
  };

  const onSelectedIndexes = (indexes) => {
    setSelectedIndexes(indexes);
    handleRowSelect && handleRowSelect(_.map(indexes, index => rows[index]));
  };

  const selectRow = (selectedRows) => {
    if (maxSelectRows > 0 && selectedIndexes.length === maxSelectRows) {
      return;
    }
    const indexes = _.clone(selectedIndexes);
    _.map(selectedRows, row => {
      if(!_.includes(indexes, row.rowIdx)) {
        indexes.push(row.rowIdx);
      }
    });
    onSelectedIndexes(indexes);
  };

  const addToChangedRows = (rows) => {
    const _changedRows = _.clone(changedRows);
    _.map(rows, row => {
      const index = _.findIndex(changedRows, {id: row.id});
      if (index > -1) {
        _changedRows[index] = row;
      } else {
        _changedRows.push(row)
      }
    });
    setChangedRows(_changedRows);
    return _changedRows;
  };

  const deselectRow = (rows) => {
    const indexes = _.clone(selectedIndexes);
    _.map(rows, row => {
      _.remove(indexes, r => r === row.rowIdx);
    })
    onSelectedIndexes(indexes);
  };

  const handleExecuteAction = () => {
    if (confirmAction === "delete") {
      handleDeleteRows()
    }
    if (confirmAction === "reset") {
      handleResetRows()
    }
  }

  const handleDeleteRows = () => {
    const _rows = _.clone(rows);
    const deletedRows = [];

    // collect rows for backend updates
    _.map(selectedIndexes, index => {
      deletedRows.push({id: _rows[index].id, action: "delete"});
    });

    // remove rows from data grid
    _.remove(_rows, row => _.find(deletedRows, selectedRow => { return row.id === selectedRow.id }));
    setRows(_rows);

    addToChangedRows(deletedRows);
    onSelectedIndexes([]);
    if (autoSave) {
      handleRowActions(deletedRows, type);
      setChangedRows([]);
    }
  };

  const confirmExecuteAction = (type, action) => {
    const isPlural = selectedIndexes.length > 1
    setConfirmMessage(`Are you sure you want to ${action} ${isPlural ? selectedIndexes.length : "the"} ${type === "development" ? "event" : type}${isPlural ? "s" : ""}?`);
    setConfirmAction(action);
    setConfirmOpen(true);
  }

  const showConfirmDialog = () => {
    return (
      <ConfirmDialog
        message={confirmMessage}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleExecuteAction}
      />
    );
  };

  const handleSave = async () => {
    const records = await handleRowActions(changedRows, type);
    _.map(changedRows, (row, i) => row.id = _.get(records, [i, "data", "id"]));
    setChangedRows([]);
  };

  const addRow = (row) => {
    // collect rows for backend updates
    row.action = "create";
    const _rows = _.clone(rows);
    _rows.unshift(row);
    setRows(_rows);
    addToChangedRows([row]);
  };

  const handleAdd = async () => {
    addRow(newRow);
    setNewRow({});
    handleClose();

    if (autoSave) {
      const records = await handleRowActions([newRow], type);
      const recordId = _.get(records, ["0", "data", "id"]);
      newRow.id = recordId;
      setChangedRows([]);
    }
  };

  const handleOpen = () => {
    const row = {};
    _.map(dataColumns, column => {
      row[column.key] = column.default
    });
    setNewRow(row);
    setAddMode(true);
  };

  const handleClose = () => {
    setNewRow({});
    setCanSubmit(false);
    setAddMode(false);
  };

  const handleFieldChange = (id, e) => {
    newRow[id] = e.target.value;
    setNewRow(newRow);

    // handle filteredDropDown columns
    const _dataColumns = _.cloneDeep(dataColumns);
    _.map(_dataColumns, column => {
      if (column.type === 'filteredDropDown') {
        if (column.editor.props.filteredBy === id) {
          const filteredBy = column.editor.props.filteredBy;
          const options = _.get(column.editor.props.options, newRow[filteredBy]);
          column.filteredOptions = options;
        }
      }
    })
    setDataColumns(_dataColumns);
    
    // see of Ok button should be enabled
    enableSubmit();
  };

  const enableSubmit = () => {
    let result = true;
    _.each(dataColumns, column => {
      const hasValue = (column.type === "dropDown" || column.type === "filteredDropDown") ? !_.isUndefined(newRow[column.key]) : (!!newRow[column.key] || !_.isEmpty(newRow[column.key]));
      if (column.editable && column.required && !hasValue) {
        result = false;
      }
    });
    setCanSubmit(result);
  };

  const handleResetRows = () => {
    const _rows = _.clone(rows);
    const selectedRowIds = [];

    // collect rows for backend updates
    _.map(selectedIndexes, index => {
      selectedRowIds.push({id: _rows[index].id, action: "reset"});
    });

    onSelectedIndexes([]);
    handleRowActions(selectedRowIds, type);
  };
  
  return (
    <React.Fragment>
      <div style={{position: "relative"}}>
        {
          !readOnly && (
            <div style={{display: "flex", position: "absolute", top: -64, right: 8}}>
              {
                !updateOnly &&
                <div>
                  <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}} onClick={handleOpen}>
                    Add
                  </Button>
                  <Button style={{color: "white", backgroundColor: "red", margin: 5}} disabled={_.isEmpty(selectedIndexes)} onClick={() => confirmExecuteAction(type, "delete")}>
                    Delete
                  </Button>
                </div>
              }
              {
                resetable &&
                <div>
                  <Button style={{color: "white", backgroundColor: "black", margin: 5}} onClick={() => confirmExecuteAction(type, "reset")}>
                    Reset
                  </Button>
                </div>
              }
              {
                !autoSave &&
                <Button style={{color: "white", backgroundColor: "#0093B2", margin: 5}}  onClick={handleSave}>
                  Save
                </Button>
              }
            </div>
          )
        }
      </div>
      <ReactDataGrid
        minHeight={minHeight}
        rowKey='id'
        columns={dataColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
        rowGetter={i => rows[i]}
        rowsCount={rows.length}
        onGridSort={onGridSort}
        onGridRowsUpdated={onGridRowsUpdated}
        enableCellSelect={true}
        rowSelection={{
          showCheckbox: true,
          enableShiftSelect: true,
          onRowsSelected: selectRow,
          onRowsDeselected: deselectRow,
          selectBy: {
              indexes: selectedIndexes
          }
        }}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={addMode}
      >
        <DialogTitle id="customized-dialog-title">
        {`Add New ${_.capitalize(type)}`}
        </DialogTitle>
        <DialogContent style={{height: dialogHeight}} dividers>
          <form noValidate>
            {
              dataColumns && _.map(_.filter(dataColumns, "editable"), column => {
                const id = column.key, name = column.name, required = column.required;
                if (!column.hide) {
                  if (column.type === 'textarea') {
                    return renderTextArea(id, name, required);
                  }
                  if (column.type === 'dropDown') {
                    return renderSelect(id, name, column.editor.props.options, required);
                  }
                  if (column.type === 'filteredDropDown') {
                    return renderSelect(id, name, column.filteredOptions, required);
                  }
                  if (column.type === 'numeric') {
                    return renderNumericInput(id, name, required)
                  }
                  return renderTextField(id, name, required);
                }
              })
            }
          </form>
        </DialogContent>
        <div style={{display: "flex", justifyContent: "right"}}>
          <Button style={{border: "1px solid #0093B2", margin: 5}} onClick={handleAdd} disabled={!canSubmit}>
            Ok
          </Button>
          <Button autoFocus style={{color: "white", backgroundColor: "#0093B2", margin: 5}}  onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Dialog>      
      { showConfirmDialog() }
    </React.Fragment>
  );
};

export default DataGrid;