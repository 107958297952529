const ResourceStrings = {
  EN: {
    FUND_MANAGER_TITLE: "BII Impact Investing: The Game",
    COPYRIGHT: "Copyright",
    GAME_NAME: "Game",
    GAME_SESSION: "Session",
    GAME_ROUND: "Round",
    GAME_MONTH: "Month",
    GAME_PHASE: "Phase",
    VISIT_MARKETPLACE: "Visit MarketPlace",
    TURN_COMPLETE: "Turn Complete",
    WAIT_FOR_PLAYERS: "Waiting for more players",
    START_GAME: "Start Game",
    NEED_MORE_INVESTMENT_1: "Need ",
    NEED_MORE_INVESTMENT_2: " more investments",
    WAIT_FOR_OTHERS: "Waiting for others",
    WAIT_FOR_TURN: "Waiting for turn",
    GAME_COMPLETED: "Game Completed",
    INVEST: "Invest",
    APPLY_EVENT: "Apply Event",
    // investment types
    TERM: "Term Length",
    INTEREST_RATE: "Interest Rate",
    DISCOUNT_RATE: "Discount Rate",
    ROYALTY_RATE: "Royalty Rate",
    REPAYMENT_CAP: "Repayment Cap",
    COLLATERAL: "Collateral",
    REVENUE: "Revenue",
    GROWTH_RATE: "Growth Rate",

    // misc
    RISKRATING: "Risk Rating"
  },
  ES: {},
};

const getResourceString = (lang, label) => {
  return ResourceStrings[lang][label];
};

export {getResourceString}
