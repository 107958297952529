import React from "react";
import { SvgIcon, Tooltip } from "@material-ui/core";

// https://materialdesignicons.com/ for icon paths
export const RiskIcon = () => {
  return (
    <React.Fragment>
        <Tooltip title={"Risk"}>
        <SvgIcon style={{fontSize: "0.8rem"}}>
          <path  fill="blue" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
        </SvgIcon>
        </Tooltip>
    </React.Fragment>
  );
};

export const ImpactIcon = () =>{
  return (
      <React.Fragment>
        <Tooltip title={"Impact"}>
        <SvgIcon style={{fontSize: "0.8rem"}}>
          <path fill="blue" d="M16 3.23C16.71 2.41 17.61 2 18.7 2C19.61 2 20.37 2.33 21 3C21.63 3.67 21.96 4.43 22 5.3C22 6 21.67 6.81 21 7.76S19.68 9.5 19.03 10.15C18.38 10.79 17.37 11.74 16 13C14.61 11.74 13.59 10.79 12.94 10.15S11.63 8.71 10.97 7.76C10.31 6.81 10 6 10 5.3C10 4.39 10.32 3.63 10.97 3C11.62 2.37 12.4 2.04 13.31 2C14.38 2 15.27 2.41 16 3.23M22 19V20L14 22.5L7 20.56V22H1V11H8.97L15.13 13.3C16.25 13.72 17 14.8 17 16H19C20.66 16 22 17.34 22 19M5 20V13H3V20H5M19.9 18.57C19.74 18.24 19.39 18 19 18H13.65C13.11 18 12.58 17.92 12.07 17.75L9.69 16.96L10.32 15.06L12.7 15.85C13 15.95 15 16 15 16C15 15.63 14.77 15.3 14.43 15.17L8.61 13H7V18.5L13.97 20.41L19.9 18.57Z" />
        </SvgIcon>
        </Tooltip>
      </React.Fragment>
    );
};

export const HoldingsValueIcon = () => {
  return(
      <React.Fragment>
      <Tooltip title={"Holdings"}>
      <SvgIcon>
          <path d="M6.5,10H4.5V17H6.5V10M12.5,10H10.5V17H12.5V10M21,19H2V21H21V19M18.5,10H16.5V17H18.5V10M11.5,3.26L16.71,6H6.29L11.5,3.26M11.5,1L2,6V8H21V6L11.5,1Z" />
        </SvgIcon>
        </Tooltip>
      </React.Fragment>
    );
};

export const CashIcon = () =>{
  return (
    <React.Fragment>
      <Tooltip title={"Cash"}>
      <SvgIcon>
        <path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
      </SvgIcon>
      </Tooltip>
    </React.Fragment>
  );
};

export const PlaceHolderPieChart = () => {
  return (
    <React.Fragment>
      <Tooltip title={"Holdings Makeup"}>
      <SvgIcon>
        <path d="M11,2V22C5.9,21.5 2,17.2 2,12C2,6.8 5.9,2.5 11,2M13,2V11H22C21.5,6.2 17.8,2.5 13,2M13,13V22C17.7,21.5 21.5,17.8 22,13H13Z" />
      </SvgIcon>
      </Tooltip>
    </React.Fragment>
  );
};