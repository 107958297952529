import { GAME_PHASE, PORTFOLIO_STATUS } from "../Constants";
import Portfolio from "../Portfolio";

const calculateRisk = (portfolios) => {
  let risk = 0.0;
  _.map(portfolios, (portfolio, i) => {
    const r = parseFloat(portfolio.risk);
    risk += r;
  });
  const average = _.isEmpty(portfolios) ? 0 : risk / portfolios.length;
  return average;
};

const calculateImpact = (portfolios) => {
  let impact = 0.0;
  _.map(portfolios, (portfolio, i) => {
    const r = parseFloat(portfolio.social_impact);
    impact += r;
  });
  const average = _.isEmpty(portfolios) ? 0 : impact / portfolios.length;
  return average;
};

const calculateEarnings = (portfolios) => {
  let earnings = 0;
  _.map(portfolios, (portfolio) => {
    earnings += portfolio.earnings;
  });
  return earnings;
};

const getInitialInvestments = (game, player) => {
  // return the investments from the first 2 rounds
  const logs = _.get(game, "history_logs")
  const initialInvestments = [];
  if (logs) {
      const investments = _.filter(logs, log => {
          return log.playerId === _.get(player, "id") && log.round < 2 && log.type === "investment";
      });
      const portfolios = _.get(game, "portfolios");
      _.map(portfolios, portfolio => {
          _.map(investments, investment => {
              if (investment.portfolioId === portfolio.id) {
                  initialInvestments.push(portfolio);
              }
          })
      });
  }
  return initialInvestments;
}

// same as get_total_collected() in games_helper.rb
const getTotalCollected = (game, player) => {
  let total_collected = 0
  const portfolios = getPlayerPortfolios(game, player)

  portfolios.forEach(portfolio =>
    total_collected += portfolio.total_collected
  )
  return total_collected  
}

// same as calculate_scores() in games_helper.rb
const getImpactScoreGroups = (game, session, player) => {
    const impactScoreGroups = [0, 0, 0, 0, 0, 0]
    const playerId = _.get(player, "id")
    const portfolios = _.get(game, "portfolios", [])
    const portfolioValues = _.get(session, "portfolios_value", 0)

    portfolios.forEach(portfolio => {
      if (portfolio.player_id === playerId && portfolio.status === PORTFOLIO_STATUS.ACTIVE) {
        const socialImpact = portfolio.social_impact
        if (socialImpact >= 0 && socialImpact < 1) {
          impactScoreGroups[0] += portfolio.investment_value
        } else if (socialImpact >= 1 && socialImpact < 2) {
          impactScoreGroups[1] += portfolio.investment_value
        } else if (socialImpact >= 2 && socialImpact < 3) {
          impactScoreGroups[2] += portfolio.investment_value
        } else if (socialImpact >= 3 && socialImpact < 4) {
          impactScoreGroups[3] += portfolio.investment_value
        } else if (socialImpact >= 4 && socialImpact < 5) {
          impactScoreGroups[4] += portfolio.investment_value
        } else if (socialImpact >= 5) {
          impactScoreGroups[5] += portfolio.investment_value
        }
      }
    })

    const impactScores = [0, 0, 0, 0, 0, 0]
    impactScoreGroups.forEach((group, index) => {
      if (portfolioValues > 0) {
        impactScores[index] = group / portfolioValues * index
      }
    })
    return impactScores
}

const getAggregateScore = (session) => {
  const aggregateScore = (Number(_.get(session, "finance_score", "0")) + Number(_.get(session, "impact_score", "0")) + Number(_.get(session, "velocity_score", "0")));
  return (aggregateScore / 2.1);
};

const getPlayerWithHighestAggregateScore = (game) => {
  let winner;
  _.map(game.sessions, session => {
    const score = getAggregateScore(session)
    if (winner) {
      if (score > winner.score) {
        winner = {player_id: session.player_id, score: score}
      }
    } else {
      winner = {player_id: session.player_id, score: score}
    }
  })
  return _.get(winner, "player_id");
}

const getPlayerWithHighestRiskScore = (game) => {
  let winner;
  _.map(game.players, player => {
    const portfolios = getPlayerPortfolios(game, player);
    const score = calculateRisk(portfolios);
    if (winner) {
      if (score > winner.score) {
        winner = {player_id: player.id, score: score}
      }
    } else {
      winner = {player_id: player.id, score: score}
    }
  })
  return _.get(winner, "player_id");
}

const getPlayerWithHighestImpactScore = (game) => {
  let winner;
  _.map(game.players, player => {
    const portfolios = getPlayerPortfolios(game, player);
    const score = calculateImpact(portfolios)
    if (winner) {
      if (score > winner.score) {
        winner = {player_id: player.id, score: score}
      }
    } else {
      winner = {player_id: player.id, score: score}
    }
  })
  return _.get(winner, "player_id");
}

const getPlayerWithLowestVelocityScore = (game) => {
  let winner;
  _.map(game.sessions, s => {
    const score = s.velocity_score;
    if (winner) {
      if (score < winner.score) {
        winner = {player_id: s.player_id, score: score}
      }
    } else {
      winner = {player_id: s.player_id, score: score}
    }
  })
  return _.get(winner, "player_id");
}

const canApplyEventToPortfolio = (development, portfolio) => {
  if (!development || !portfolio) {
    return false;
  }

  // exclude portfolios that are not active
  if (portfolio.status !== PORTFOLIO_STATUS.ACTIVE) {
    return false;
  }
  
  // check investment_type and industry
  return (development.investment_type === -1 || portfolio.investment_type === development.investment_type) &&
  (development.industry === -1 || portfolio.industry === development.industry)
}

const getPortfoliosForEvent = (portfolios, development) => {
  return _.filter(portfolios, portfolio => canApplyEventToPortfolio(development, portfolio));
}

const checkSelectCondition = (condition, required, selectedPortfolios, portfolios) => {
  const conditionParts = condition.split("_");
  const ordering = conditionParts[0];  // highest, lowest
  const orderField = conditionParts[1];  // risk, social_impact in business
  // find the highest scores, then get all businesses matching those scores
  const orderedBusinesses =  _.orderBy(_.map(portfolios, "business"), [orderField], [ordering === "highest" ? "desc" : "asc"]).slice(0, required);
  const orderedScores = _.map(orderedBusinesses, business => business[orderField])
  const filteredPortfolios = _.filter(_.map(portfolios, "business"), business => _.includes(orderedScores, business[orderField]))
  return selectedPortfolios.every(portfolio => _.includes(_.map(filteredPortfolios, "id"), portfolio.business.id));
}

const canApplyEventToPortfolios = (development, selectedPortfolios, portfolios) => {
  // check if development has 'select' condition in factor (e.g. select:highest_risk)
  const factors = development.factor.split(",");
  for (const factor of factors) {
    const parts = factor.split(':');
    if (parts[0] === "select") {
      const required = development.required ?? 0;
      if(!checkSelectCondition(parts[1], required, selectedPortfolios, portfolios)) {
        return false;
      }
    }
  }
  
  for(const portfolio of selectedPortfolios) {
    if (!canApplyEventToPortfolio(development, portfolio)) {
      return false;
    }
  }
  return true;
}

const getRequiredInvestmentsInRound = (game) => {
  return game.phase === GAME_PHASE.DRAFT ? game.number_of_investments : 1;
}

const hasMadeRequiredInvestments = (game, player) => {
  if (!game || !player) {
    return false
  }

  const businessesInPortfolios = _.filter(getPlayerPortfolios(game, player), {status: PORTFOLIO_STATUS.SELECTED}).length;
  const requiredInvestments = getRequiredInvestmentsInRound(game);
  return businessesInPortfolios === requiredInvestments  
}

const getPlayerPortfolios = (game, player) => {
  const player_id = _.get(player, "id");
  return _.filter(game.portfolios, (portfolio) => {
    // TODO: return portfolio.player_id == player_id && portfolio.status != PORTFOLIO_STATUS.WRITTEN_OFF
    return portfolio.player_id == player_id
  })
}

const hasRequiredSelectedForEvent = (development, selectedPortfolios, portfolios) => {
  const required = _.get(development, "required", 0);
  const selectedCount = _.size(selectedPortfolios) ;
  const totalCount = _.size(portfolios);

  // handle up to N which are negative number
  if (required < 0) {
    return selectedCount > 0 && selectedCount <= Math.abs(required)
  } else {
    // there may not be enough remaining portfolio items to meet devepment's required number
    return selectedCount >= required || selectedCount === totalCount;
  }
};

const isNoEventCanBeApplied = (portfolios, development) => {
  let noEventCanBeApplied = false
  const required = _.get(development, "required")
  // if required is undefined/null then event does not have any required specified
  // if required is a positive number (at least N), then there must be at least N number in portfolio meeting event conditions
  // if required is a negative number (up to N), then there must be at least one in portfolio meeting event conditions
  if (required) {
    const portfoliosForEvent = getPortfoliosForEvent(portfolios, development);
    if (required > 0) {
      // there may not be enough remaining portfolio items to meet devepment's required number
      noEventCanBeApplied = portfoliosForEvent.length < required;
    } else if (required < 0) {
      noEventCanBeApplied = portfoliosForEvent.length < 1
    }
  }
  return noEventCanBeApplied
}

const canSelectPortfolioItem = (development, selection) => {
  // handle up to N which are negative number
  const required = Math.abs(_.get(development, "required", 0));
  const selectedCount = _.size(selection);
  return selectedCount < required;
}

const openConfirmDialog = () => {
  setOpenConfirmDialog(true)
}

const showConfirmDialog = () => {
  return <Dialog
      PaperProps={{
        style: {
          borderRadius: 15,
          backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
        }
      }}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="customized-dialog-title"
      open={openConfirmDialog}
  >
    <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 400, justifyContent: "center", alignItems: "center"}}>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Typography variant="h5" component="h3" style={{textAlign: "center", fontWeight: 600, paddingBottom: 40}}>
          {
            `You have selected ${toWordConverter.toWords(requiredInvestmentsInRound)} ${requiredInvestmentsInRound > 1 ? "companies" : "company"}. Are your investment choices final?`
          }
        </Typography>
        <div style={{display: "flex", justifyContent: "space-evenly", width: 360}}>
          <div>
            <Chip
              label="YES"
              onClick={handleManageInvestments}
              variant="contained"
              style={{ width: 150, backgroundColor: BIITheme.palette.dark.primary.button, color: "white" }}
            />
            <Typography style={{paddingTop: 5, fontSize: 10, width: 150, textAlign: "center"}}>
              Start managing your investments
            </Typography>
          </div>
            <div>
              <Chip
                label ="NO"
                onClick={handleReselectInvestments}
                variant="outlined"
                style={{ width: 150, backgroundColor: BIITheme.palette.dark.secondary.button, color: "black" }}
              />
              <Typography style={{paddingTop: 5, fontSize: 10, width: 150, textAlign: "center"}}>
                Re-select investments in your Portfolio
              </Typography>
            </div>
        </div>
      </div>
    </DialogContent>
  </Dialog>
}

export {
  calculateRisk,
  calculateImpact,
  calculateEarnings,
  canApplyEventToPortfolio,
  canApplyEventToPortfolios,
  canSelectPortfolioItem,
  getAggregateScore,
  getInitialInvestments,
  getPlayerPortfolios,
  getPlayerWithHighestAggregateScore,
  getPlayerWithHighestRiskScore,
  getPlayerWithHighestImpactScore,
  getPlayerWithLowestVelocityScore,
  getPortfoliosForEvent,
  getRequiredInvestmentsInRound,
  getImpactScoreGroups,
  getTotalCollected,
  hasMadeRequiredInvestments,
  hasRequiredSelectedForEvent,
  isNoEventCanBeApplied
}
