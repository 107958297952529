import consumer from "./consumer";

let channel;

export function subscribe(gameId, playerId, onConnect, onReceive) {
  channel = consumer.subscriptions.create(
    { channel: "GameChannel", game: gameId },
    {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log( "ActionCable: GameChannel subscription is ready for use on the server. You're connected to game with id: " + gameId);
        onConnect(playerId);
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log("ActionCable: GameChannel subscription has been terminated by the server");
      },

      received(data) {
        console.log("ActionCable: GameChannel received: ", JSON.stringify(data));
        onReceive(data);
      },
    }
  );
  return channel;
}

export function unsubscribe(playerId, onDisconnect) {
  channel.unsubscribe(playerId);
  onDisconnect(playerId)
}

export function sendMessage(payload) {
    channel.send(payload);
}
