import { subscribe, unsubscribe, sendMessage } from "../channels/messaging";
import { appendMessage, updateGame } from "../slices/game";
import _ from "lodash";

const actionFailure = (message) => console.log(message);

function handleConnect(playerId, dispatch) {
  dispatch(send(playerId, 'joined the game', true));
}

function handleDisconnect(playerId, dispatch) {
  dispatch(send(playerId, 'left the game', true));
}

function handleReceive(data, dispatch) {
  dispatch(appendMessage(data));

  // To avoid having clients update their game state (e.g. if we're just sending a chat message),
  //   call send with false second parameter, like this: dispatch(send(data, false))
  if (data.updateGame) {
    // Fetch new game state from server
    dispatch(updateGame());
  }
}

export function subscribeGame(gameId, playerId) {
  return async (dispatch) => {
    try {
      subscribe(gameId, playerId, 
        (playerId) => handleConnect(playerId, dispatch),
        (data) => handleReceive(data, dispatch));
    } catch (error) {
      dispatch(actionFailure(`Error connecting to game: ${error.toString()}`));
    }
  };
}

export function unsubscribeGame(playerId) {
  return async (dispatch) => {
    try {
      unsubscribe(playerId, (playerId) => handleDisconnect(playerId, dispatch));
    } catch (error) {
      dispatch(actionFailure(`Error disconnecting from game: ${error.toString()}`));
    }
  };
}

export function send(playerId, message, updateGame = true) {
  return async (dispatch) => {
    try {
      const payload = {
        sent_by: playerId,
        body: message,
        updateGame,
      };
      sendMessage(payload);
    } catch (error) {
      dispatch(actionFailure(`Error broadcasting to game: ${error.toString()}`));
    }
  };
}
