import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { gameSelector, sessionSelector, playerSelector, animatedValuesSelector, setAnimatedValue } from "../slices/game";
import {
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from "@material-ui/core";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { getInvestmentTypeColor, investmentTypeOptions, industryTypeOptions } from "./EntityTypes";
import * as utils from "./helpers/Utils"

import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnimateOnChange from 'react-animate-on-change';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontSize: 12,
    fontWeight: 600,
    color: "white",
    textAlign: "center",
    padding: "10px 10px"
  },
  detailText: {
    fontSize: 10,
    fontWeight: 400,
    color: "white",
    textAlign: "center",
  },
  divider: {
    borderTop: "1px solid rgba(255, 255, 255, 0.5)",
    margin: "4px 14px 4px 14px"
  }
}));

const formatMoney = (number) => {
  return '$'+ number.toLocaleString('en-US');
};

const Position = ({classes}) => {
  const dispatch = useDispatch();
  const [ showAssetLegend, setShowAssetLegend ] = useState(false)
  const [ showSectorLegend, setShowSectorLegend ] = useState(false)
  const [ showScoreDetails, setShowScoreDetails ] = useState(false)

  const _classes = useStyles();

  const game = useSelector(gameSelector);
  const player = useSelector(playerSelector);
  const session = useSelector(sessionSelector);
  const animatedValues = useSelector(animatedValuesSelector);
  const portfolios = utils.getPlayerPortfolios(game, player);

  const currentValues = {
    aggregate_score: (Number(_.get(session, "finance_score", "0")) + Number(_.get(session, "impact_score", "0")) + Number(_.get(session, "velocity_score", "0"))),
    impact_score: _.get(session, "impact_score", 0),
    cash: _.get(session, "cash", 0),
    portfolios_value: _.get(session, "portfolios_value", 0),
    average_risk: utils.calculateRisk(portfolios),
    average_impact: utils.calculateImpact(portfolios),
    earnings: utils.calculateEarnings(portfolios),
  };

  const setAnimatedValueChanged = (animatedValueKey, value) => {
    dispatch(setAnimatedValue({key: animatedValueKey, value: { currentValue: currentValues[animatedValueKey], animated: value }}));
  };

  const handleAnimatedValues = (keys) => {
    _.map(keys, key => {
      const animatedValueKey = key;
      // set animated value changed flag
      const previousValue = _.get(animatedValues, [animatedValueKey, "currentValue"]);
      if (!_.has(animatedValues, animatedValueKey)) {
        // initial setting values into store
        setAnimatedValueChanged(animatedValueKey, false)
      } else {
        if (currentValues[animatedValueKey] !== previousValue) {
          setAnimatedValueChanged(animatedValueKey, true)
        }
      }
    });
  };

  useEffect(() => {
    if (player && session && portfolios) {
      handleAnimatedValues(["aggregate_score", "impact_score", "cash", "portfolios_value", "average_risk", "average_impact", "earnings"]);
    }
  }, [player, portfolios]);

  if (!portfolios) {
    return <div>Loading player...</div>;
  }

  const getInvestmentTypeDataset = (portfolios) => {
    const dataset = {};
    _.map(portfolios, portfolio => {
      const investment_type = portfolio.investment_type;
      if (!dataset[investment_type]) {
        dataset[investment_type] = 0;
      }
      dataset[investment_type] += portfolio.investment_value;
    });
    return dataset;
  };
  
  const investmentTypeChartDataset = getInvestmentTypeDataset(portfolios);
  const investmentTypeChartOptions = {
    responsive: true,
    plugins: {
      legend: { display: showAssetLegend, align: "start", labels: { boxWidth: 20, font: 6, color: "white" } },
      tooltip: { enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return tooltipItem.label;
          }
        }
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
              sum += data;
          });
          let percentage = (sum > 0 ? (value*100 / sum).toFixed(0) : 0)+"%";
          return percentage;
        },
        color: '#fff',
      }
    }
  };

  const backgroundColor = _.map(_.keys(investmentTypeChartDataset), key => getInvestmentTypeColor(parseInt(key)));
  const borderColor = _.map(_.keys(investmentTypeChartDataset), () => 'rgba(255, 255, 255, 1)');
  const investmentTypeData = {
    labels: _.map(_.keys(investmentTypeChartDataset), key => _.get(_.find(investmentTypeOptions, {value: parseInt(key)}), "label", "")),
    datasets: [
      {
        data: _.values(investmentTypeChartDataset),
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        borderWidth: 1,
      },
    ]
  };
  
  const getIndustryDataset = (portfolios) => {
    const dataset = {};
    _.map(portfolios, portfolio => {
      const industry = portfolio.industry;
      if (!dataset[industry]) {
        dataset[industry] = 0;
      }
      dataset[industry] += portfolio.investment_value;
    });
    return dataset;
  };
  
  const industryChartDataset = getIndustryDataset(portfolios);
  const industryOptions = {
    responsive: true,
    plugins: {
      legend: { display: showSectorLegend, align: "start", labels: { boxWidth: 20, font: 6, color: "white" } },
      tooltip: { enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return tooltipItem.label;
          }
        }
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
              sum += data;
          });
          let percentage = (sum > 0 ? (value*100 / sum).toFixed(0) : 0)+"%";
          return percentage;
        },
        color: '#fff',
      }
    }
  };

  const industryData = {
    labels: _.map(_.keys(industryChartDataset), key => _.find(industryTypeOptions, {value: parseInt(key)}).label),
    datasets: [
      {
        data: _.values(industryChartDataset),
        backgroundColor: [
          'rgba(255, 102, 53, 1)',
          'rgba(78, 205, 196, 1)',
          'rgba(45, 76, 157, 1)',
          'rgba(196, 77, 88, 1)',
          'rgba(15, 116, 100, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderColor: [
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
          'rgba(255, 255, 255, 1)',
        ],
        borderWidth: 1,
      },
    ]
  };

  const showScoreDialog = () => {
    const calculationText = "Porfolio Scoring and Calculations"
    const scoreDescription = "A player’s portfolio score combines Financial Score (Financial Return plus Risk Rating) and Impact Rating, as well as a factor for number of deals done, called Velocity Score, to generate a blended score that is used to rank players."
    const financeScoreDescription = "Financial Score: The financial score combines the Fund’s ending cash balance (which includes realized investment returns) with a valuation of open investment positions at the end of the game. Open investments are valued by looking at potential returns and discounting using the risk rating. The scalar converts this collective dollar amount to a rating from 1 to 5."
    const impactRatingDescription = "Impact Score: The Impact Score is determined by taking the dollar-weighted average Impact Rating of open and completed investments. Only Investments that don’t default contribute to each portfolio’s Impact Score; defaulted investments are excluded as the intended impact likely wasn’t realized."
    const velocityRatingDescription = "Velocity Score: To incentivize moving capital, players get a small boost for activating more of their assets and a small penalty for sitting out investment opportunities. Unlike the Financial and Impact Scores, the Velocity Score only ranges from -0.5 to 0.5. The game rewards or penalizes players slightly for how much capital they’re moving, but recognizes financial return and impact are more important."
  
    const financeScore = _.get(session, "finance_score", 0);
    const impactScore = _.get(session, "impact_score", 0);
    const velocityScore = _.get(session, "velocity_score", 0);
    const totalCollected = utils.getTotalCollected(game, player);
    const impactScoreGroups = utils.getImpactScoreGroups(game, session, player);
    const velocityRate = Math.round(portfolios.length * 100 / (game.round + 1)) / 100;

    const financeScoreDetails = `Your Financial Score is ${financeScore}, since you have $${_.get(session, "cash", 0)} in cash, collected $${totalCollected} so far, and have $${_.get(session, "portfolios_value", 0)} in your portfolio.`
    const impactScoreDetails = `Your Impact Score is ${impactScore} with [${(impactScoreGroups[1]).toFixed(2)}] from impact of 1 to 2, [${(impactScoreGroups[2]).toFixed(2)}] from 2 to 3, [${(impactScoreGroups[3]).toFixed(2)}] from 3 to 4, and [${((impactScoreGroups[4] + impactScoreGroups[5])).toFixed(2)}] from 4 to 5.`
    const velocityScoreDetails = `Your Velocity Score is ${velocityScore} with velocity at ${velocityRate}. Velocity score is -0.5 if the velocity is less than ${game.months_per_round <= 12 ? 2 : 1}, 0 if it is between ${game.months_per_round <= 12 ? 2 : 1} and ${game.months_per_round <= 12 ? 3 : 1.3}, and 0.5 if  it is more than ${game.months_per_round <= 12 ? 3 : 1.3}.`
  
    return <Dialog
        PaperProps={{
          style: {
            borderRadius: 15,
            backgroundImage: "linear-gradient(rgb(255, 255, 255) 60%, rgb(0, 147, 178, 0.2))",
          }
        }}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="customized-dialog-title"
        open={showScoreDetails}
    >
      <DialogContent style={{flexGrow: 1, display: "flex", flexDirection: "column", height: 540, paddingTop: 60, paddingBottom: 20, alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <Typography variant="h6" component="h6" style={{textAlign: "center", fontWeight: 600, paddingBottom: 20}}>
            {calculationText}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 300, paddingBottom: 10}}>
            {scoreDescription}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 300, paddingBottom: 0}}>
            {financeScoreDescription}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 600, paddingBottom: 10, color: "blue"}}>
            {financeScoreDetails}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 300, paddingBottom: 0}}>
            {impactRatingDescription}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 600, paddingBottom: 10, color: "green"}}>
            {impactScoreDetails}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 300, paddingBottom: 0}}>
            {velocityRatingDescription}
          </Typography>
          <Typography variant="h9" component="h9" style={{textAlign: "left", fontWeight: 600, paddingBottom: 10, color: "salmon"}}>
            {velocityScoreDetails}
          </Typography>
          <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Chip className={classes.chipButton} label="Close" color="primary" variant="outlined" onClick={() => setShowScoreDetails(false)} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  }

  const showAggregateScore = () => {
    const text = `Score: ${(currentValues.aggregate_score / 2.1).toFixed(2)}`
    return <Chip style={{ width: "90%", color: "black", backgroundColor: "yellow", marginLeft: 5, marginRight: 5 }} label={text} color="primary" variant="contained" /* onClick={() => setShowScoreDetails(true)} */ />
  }

  return (
    <React.Fragment>
      <div className={classes.titleBar}>
        <Typography className={classes.titleBarText}>
          My Position
        </Typography>
      </div>
      <div className={`${classes.itemContainer} custom-scrollbar`} style={{minWidth: 168, flexDirection: "column", flexWrap: "nowrap", backgroundColor: "#0093B2"}}>
        <div className={_classes.titleText} style={{padding: 0, paddingTop: 8, paddingBottom: 2}}>
          {/* <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["aggregate_score", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("aggregate_score", false)}
          > */}
            {showAggregateScore()}
          {/* </AnimateOnChange> */}
        </div>
        <Divider className={classes.divider} />
        <div className={_classes.titleText}>
          <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["cash", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("cash", false)}
          >
            {`Cash Position: ${formatMoney(currentValues.cash)}`}
          </AnimateOnChange>
        </div>
        <div className={_classes.titleText} style={{paddingTop: 0}}>
          <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["portfolios_value", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("portfolios_value", false)}
          >
            Portfolio Value: {formatMoney(currentValues.portfolios_value)}
          </AnimateOnChange>
        </div>
        <div className={_classes.detailText}>
          <AnimateOnChange
              animationClassName="value-changed"
              animate={_.get(animatedValues, ["earnings", "animated"], false)}
              onAnimationEnd={() => setAnimatedValueChanged("earnings", false)}
            >
              Earnings to Date: {formatMoney(currentValues.earnings)}
          </AnimateOnChange>
        </div>
        <div className={_classes.detailText} style={{padding: "4px 0 4px 0"}}>
          <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["impact_score", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("impact_score", false)}
          >
            Impact to Date: {currentValues.impact_score}
          </AnimateOnChange>
        </div>
        <Divider className={classes.divider} />
        <div className={_classes.titleText} style={{padding: "4px 0 4px 0"}}>
          <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["average_risk", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("average_risk", false)}
          >
            Risk Rating: {currentValues.average_risk.toFixed(2)}
          </AnimateOnChange>
        </div>
        <div className={_classes.titleText} style={{padding: "0 0 4px 0"}}>
          <AnimateOnChange
            animationClassName="value-changed"
            animate={_.get(animatedValues, ["average_impact", "animated"], false)}
            onAnimationEnd={() => setAnimatedValueChanged("average_impact", false)}
          >
            Impact Rating: {currentValues.average_impact.toFixed(2)}
          </AnimateOnChange>
        </div>
        <Divider className={classes.divider} />
        <div className={_classes.titleText}>
          <span>Asset Allocation</span>
          {/* <span style={{cursor: "pointer", marginLeft: 5, float: "right", fontColor: "white", fontSize: 8}} onClick={() => setShowAssetLegend(!showAssetLegend)}>Legend</span> */}
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: 10}}>
          <Pie data={investmentTypeData} options={investmentTypeChartOptions} width={120} />
        </div>
        <Divider className={classes.divider} />
        <div className={_classes.titleText}>
          <span>Sector Allocation</span>
          {/* <span style={{cursor: "pointer", marginLeft: 5, float: "right", fontColor: "white", fontSize: 8}} onClick={() => setShowSectorLegend(!showSectorLegend)}>Legend</span> */}
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", margin: 10}}>
          <Pie data={industryData} options={industryOptions} width={120} />
        </div>
      </div>
      { showScoreDialog() }
    </React.Fragment>
  );
};

export default Position;
